import React, { FC, ReactElement, RefObject, useRef } from 'react';
import classNames from 'classnames';

import { TabBarItem } from '../../../types';
import styles from '../styles.module.scss';
import { useTab } from './hooks';

type Props = {
  id: TabBarItem['id'];
  children: ReactElement | string;
  containerRef: RefObject<HTMLDivElement>;
};

const Tab: FC<Props> = ({ children, id, containerRef }) => {
  const tabRef = useRef<HTMLDivElement>(null);

  const { isActive, handleClick } = useTab({ id, containerRef, tabRef });

  return (
    <div
      ref={tabRef}
      onClick={handleClick}
      className={classNames(styles.item, { [styles.active]: isActive })}
    >
      {children}
    </div>
  );
};

export default Tab;
