import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';

import { GetStatisticsResponse } from '../types';

const getStatistics = (): Promise<AxiosResponse<GetStatisticsResponse>> => {
  return authInstance({
    method: 'get',
    url: '/v1/quiz/stats',
  });
};

export default getStatistics;
