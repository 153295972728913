import { createSelector } from '@reduxjs/toolkit';

import { Tournaments } from 'packages/rest';

import { TournamentTypes } from '../../types';
import getState from './getState';

export const getData = createSelector(
  getState,
  (state: TournamentTypes.State): Tournaments.GetTournamentByIdResponse | undefined => state.data,
);
