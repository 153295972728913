import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { cleanupMakePredictionState } from '../actions';
import { makePrediction } from '../thunks';
import { MakePredictionTypes } from '../types';

const defaultState: MakePredictionTypes.State = {
  status: {
    isLoading: false,
    requestStatus: undefined,
  },
};

const makePredictionReducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(makePrediction.pending, (state) => {
      state.status.isLoading = true;
      state.status.requestStatus = undefined;
    })
    .addCase(cleanupMakePredictionState, () => {
      return defaultState;
    })
    .addMatcher(isAnyOf(makePrediction.fulfilled, makePrediction.rejected), (state, { meta }) => {
      state.status.isLoading = false;
      state.status.requestStatus = meta.requestStatus;
    });
});

export default makePredictionReducer;
