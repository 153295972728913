import React, { FC, memo, useMemo } from 'react';
import classNames from 'classnames';

import { Avatar, Icon } from 'packages/shared/components';
import { UserGroup } from 'packages/rest/types';

import styles from './styles.module.scss';

type Props = {
  firstName: string;
  lastName: string;
  useTextEllipsis?: boolean;
  photo?: string;
  teamName?: string;
  id?: number;
  groups?: UserGroup[];
  onClick?: (id: number, event: React.MouseEvent<HTMLDivElement>) => void;
};

const Base: FC<Props> = ({
  onClick,
  groups = [],
  photo,
  firstName,
  lastName,
  teamName,
  id,
  useTextEllipsis = false,
}) => {
  const isCelebrity = useMemo(
    () => groups.some((group) => group === UserGroup.CELEBRITY),
    [groups],
  );

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (id && onClick) {
      onClick(id, event);
    }
  };

  return (
    <div
      onClick={handleClick}
      className={classNames(styles.wrapper, { [styles.textEllipsis]: useTextEllipsis })}
    >
      <Avatar src={photo || ''} useEmptyUserIcon size="lg" />

      <div className={styles.info}>
        <div className={styles.name}>
          <span>
            {firstName} {lastName}
          </span>{' '}
          {isCelebrity && (
            <Icon className={styles.celebrityIcon} id="checkedInCircle" module="other" />
          )}
        </div>

        {teamName && <div className={styles.team}>{teamName}</div>}
      </div>
    </div>
  );
};

export default memo(Base);
