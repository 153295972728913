import { createAsyncThunk } from '@reduxjs/toolkit';

import { Rating } from 'packages/rest';
import { RootState } from 'app/store';

import { FilterTypes } from '../types';
import { ratingSelectors, filterSelectors } from '../selectors';

type ReturnType = {
  rating: Rating.TeamRating[] | Rating.PlayerRating[];
  offset: number;
  hasNext: boolean;
};

const getMoreRating = createAsyncThunk<ReturnType, void, { state: RootState }>(
  'leaderboard/getMoreRating',
  async (params, { getState }) => {
    const { offset, limit } = ratingSelectors.getStatus(getState());
    const { search, active } = filterSelectors.getData(getState());
    const { unit, sport, measurement, dateOffset, onlyFriends, dateType } = active;

    const { data } = await Rating.getRating({
      offset,
      limit,
      type: unit === Rating.UnitType.Players && onlyFriends ? Rating.UnitType.Friends : unit,
      order: measurement,
      dateOffset: dateOffset.value,
      dateType,
      sportId: sport.value === FilterTypes.SportId.All ? undefined : sport.value,
      search,
    });

    return {
      rating: data.teams || data.all || [],
      offset: offset + limit,
      hasNext: limit <= (data.teams || data.all || []).length,
    };
  },
);

export default getMoreRating;
