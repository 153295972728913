import React, { FC, memo, useCallback, useContext, useMemo, useRef } from 'react';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import { useTranslation } from 'react-i18next';

import {
  FilterCounter,
  InfiniteScrollLoader,
  LoaderContainer,
  NavBar,
  Search,
} from 'packages/shared/components';
import { Modal } from 'app/context';
import { useAppDispatch, useAppSelector } from 'app/store';
import {
  detailsActions,
  DetailsTypes,
  filterSelectors,
  ratingSelectors,
  getInitialRatingItemDetails,
  filterActions,
  isPlayerRating,
  getMoreRating,
} from 'packages/leaderboard';

import styles from './styles.module.scss';
import TeamRatingTable from './TeamRatingTable';
import PlayerRatingTable from './PlayerRatingTable';
import { useFilterVisibility } from './hooks';
import { ContentType } from '../../types';
import { getChangedFilterItems } from '../../../../services';
import { Context } from '../../context';

const LeaderboardTable: FC = () => {
  const { t } = useTranslation();
  const { updateActiveModals } = useContext(Modal.Context);
  const [, setContentElement] = useContext(Context);
  const dispatch = useAppDispatch();
  const navBarRef = useRef<HTMLDivElement>(null);
  const filterVisible = useFilterVisibility(navBarRef);
  const rating = useAppSelector(ratingSelectors.getRating);
  const { isInitialLoading, hasNext, isMoreLoading } = useAppSelector(ratingSelectors.getStatus);
  const { active: activeFilter, search } = useAppSelector(filterSelectors.getData);
  const changedFilterItems = useMemo(
    () => getChangedFilterItems({ selected: activeFilter }),
    [activeFilter],
  );

  const handleFilterClick = useCallback(() => {
    updateActiveModals({ leaderboardFilter: true });
  }, [updateActiveModals]);

  const handleRowClick = useCallback(
    ({ photo = '', points = 0, id = '', name = '' }: Omit<DetailsTypes.Data, 'details'>) => {
      dispatch(detailsActions.selectItemDetails({ photo, points, id, name }));
      dispatch(getInitialRatingItemDetails({ id }));
      setContentElement(ContentType.Details);
    },
    [setContentElement, dispatch],
  );

  const handleSearch = useCallback(
    (search: string) => dispatch(filterActions.search({ search })),
    [dispatch],
  );

  const handleLoadMore = useCallback(() => {
    if (!isMoreLoading) {
      dispatch(getMoreRating());
    }
  }, [dispatch, isMoreLoading]);

  return (
    <>
      <NavBar
        navBarRef={navBarRef}
        className={styles.header}
        titleContent="Leaderboard"
        leftContent={<></>}
        rightContent={
          <FilterCounter
            className={classNames(styles.rightIcon, { [styles.visible]: filterVisible })}
            onClick={filterVisible ? handleFilterClick : undefined}
            activeItems={changedFilterItems}
          />
        }
      />
      <div className={styles.settings}>
        <Search
          initialValue={search}
          useDebounce
          placeholder={t('common_search')}
          onChange={handleSearch}
          filterActiveItemsCount={changedFilterItems}
          onFilterIconClick={handleFilterClick}
        />
      </div>
      <LoaderContainer fullSize isLoading={isInitialLoading}>
        <InfiniteScroll
          hasMore={hasNext}
          loadMore={handleLoadMore}
          useWindow={false}
          initialLoad={false}
          loader={<InfiniteScrollLoader key="loader" />}
          threshold={10}
        >
          {isPlayerRating(rating) ? (
            <PlayerRatingTable onRowClick={handleRowClick} data={rating} />
          ) : (
            <TeamRatingTable onRowClick={handleRowClick} data={rating} />
          )}
        </InfiniteScroll>
      </LoaderContainer>
    </>
  );
};

export default memo(LeaderboardTable);
