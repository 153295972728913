import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'packages/shared/components';

import MenuLink from '../MenuLink';
import styles from './styles.module.scss';

type Props = {
  supportLinkDisplayed?: boolean;
};

const SupportLinks: FC<Props> = ({ supportLinkDisplayed }) => {
  const { t } = useTranslation();

  return (
    <ul className={styles.wrapper}>
      <li>
        <MenuLink
          iconElement={<Icon id="documentation" module="base" />}
          label={t('common_help')}
        />
      </li>
      <li>
        <MenuLink iconElement={<Icon id="rate" module="base" />} label={t('common_rate_app')} />
      </li>
      {supportLinkDisplayed && (
        <li>
          <MenuLink
            iconElement={<Icon id="supportChat" module="base" />}
            label={t('profile_button_support')}
          />
        </li>
      )}
    </ul>
  );
};
export default memo(SupportLinks);
