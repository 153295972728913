import { createReducer, current } from '@reduxjs/toolkit';

import { Sport } from 'packages/types';

import { availablePredictionsActions, settingsActions } from '../../actions';
import { availablePredictionsThunks } from '../../thunks';
import { AvailablePredictionsTypes } from '../../types';
import { availablePredictionsConstants } from '../../constants';
import { resolveConflicts, reorderSelectedMarketCodes } from '../../services';

const defaultState: AvailablePredictionsTypes.Data = {
  predictionsBySport: availablePredictionsConstants.DEFAULT_PREDICTIONS_BY_SPORT_STATE,
  selectedMarketCodes: availablePredictionsConstants.DEFAULT_SELECTED_PREDICTIONS_BY_SPORT,
  sports: [],
};

const reducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(availablePredictionsActions.resolveConflict, (state, { payload }) => {
      const { sport, eventIds } = payload;
      const initialSelectedMarketCodes = current(state.selectedMarketCodes[sport]);
      const { marketCodesWithConflict, cleanMarketCodes } = current(
        state.predictionsBySport[sport],
      );

      const { selectedMarketCodes, ...rest } = resolveConflicts({
        selectedMarketCodes: initialSelectedMarketCodes,
        marketCodesWithConflict,
        cleanMarketCodes,
        eventIds,
      });

      state.predictionsBySport[sport] = rest;
      state.selectedMarketCodes[sport] = selectedMarketCodes;
    })
    .addCase(availablePredictionsActions.reorderSelectedMarketCodes, (state) => {
      const predictionsBySportCopy = current(state.predictionsBySport);
      const selectedPredictionsBySportCopy = current(state.selectedMarketCodes);

      state.selectedMarketCodes = reorderSelectedMarketCodes({
        selectedMarketCodes: selectedPredictionsBySportCopy,
        predictionsBySport: predictionsBySportCopy,
      });
    })
    .addCase(availablePredictionsActions.updateCleanMarketCodes, (state, { payload }) => {
      const { cleanMarketCodes, sport } = payload;

      state.predictionsBySport[sport].cleanMarketCodes = cleanMarketCodes;
    })
    .addCase(availablePredictionsActions.updateSelectedMarketCodes, (state, { payload }) => {
      const { selectedMarketCodes, sport } = payload;

      state.selectedMarketCodes[sport] = selectedMarketCodes;
    })
    .addCase(availablePredictionsActions.cleanupPredictions, ({ selectedMarketCodes }) => {
      return { ...defaultState, selectedMarketCodes };
    })
    .addCase(availablePredictionsThunks.loadPredictions.fulfilled, (state, { payload }) => {
      const { sports, predictionsBySport } = payload;

      state.sports = sports;
      state.predictionsBySport = { ...state.predictionsBySport, ...predictionsBySport };
    })
    .addCase(settingsActions.applySettings, (state, { payload }) => {
      const { activeSports } = payload;

      for (const selectedMarketCodesSport of Object.keys(state.selectedMarketCodes)) {
        const validSelectedMarketCodesSport = <Sport>parseInt(selectedMarketCodesSport, 10);

        if (!activeSports.includes(validSelectedMarketCodesSport)) {
          state.selectedMarketCodes[validSelectedMarketCodesSport] = [];
        }
      }
    });
});

export default reducer;
