import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';

import { GetTournamentExpressRequest, GetTournamentExpressResponse } from '../types';

const getTournamentExpress = ({
  tournamentId,
  limit,
  offset,
}: GetTournamentExpressRequest): Promise<AxiosResponse<GetTournamentExpressResponse>> => {
  return authInstance({
    method: 'get',
    url: `/v2/tournaments/${tournamentId}/express`,
    params: {
      limit,
      offset,
    },
  });
};

export default getTournamentExpress;
