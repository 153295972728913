import React, { cloneElement, ReactElement, RefObject, useMemo } from 'react';

import Summary from '../../Summary';
import Content from '../../Content';

interface Params {
  onClick: () => void;
  ref: RefObject<HTMLDivElement>;
  children: (
    | ReactElement<Parameters<typeof Summary>[0]>
    | ReactElement<Parameters<typeof Content>[0]>
  )[];
  isOpen?: boolean;
}

const useSummaryElement = ({ onClick, isOpen, ref, children }: Params) => {
  return useMemo(() => {
    const summaryElement = children.find((child) => child.type === Summary) as
      | ReactElement<Parameters<typeof Summary>[0]>
      | undefined;

    const handleClick = () => {
      onClick();
      summaryElement?.props?.onClick?.();
    };

    return summaryElement ? (
      cloneElement(summaryElement, { onClick: handleClick, isOpen, forwardedRef: ref })
    ) : (
      <></>
    );
  }, [children, isOpen, onClick, ref]);
};

export default useSummaryElement;
