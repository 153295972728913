import { IconTypes } from 'packages/shared/components';
import { Sport } from 'packages/types';

const getSportIconId = (sportId: Sport): keyof typeof IconTypes.IconId => {
  switch (sportId) {
    case Sport.Soccer:
      return 'soccerBall';
    case Sport.Basketball:
      return 'basketballBall';
    case Sport.Tennis:
      return 'tennisBall';
    case Sport.Hockey:
      return 'hockeyPuck';
    default:
      return 'soccerBall';
  }
};

export default getSportIconId;
