import { createAsyncThunk } from '@reduxjs/toolkit';

import { TournamentsV2 } from 'packages/rest';

const getActual = createAsyncThunk<TournamentsV2.Tournament[], void>(
  'tournaments/getActual',
  async () => {
    const { data } = await TournamentsV2.getActualTournaments();

    return data;
  },
);

export default getActual;
