import React, { cloneElement, FC, memo, ReactElement, useMemo } from 'react';
import classNames from 'classnames';

import { Size, Theme } from './types';
import Counter from '../Counter';
import styles from './styles.module.scss';
import { getCounterTheme } from './services';

type Props = {
  theme?: keyof typeof Theme;
  size?: keyof typeof Size;
  iconElement?: ReactElement;
  count?: number;
  label?: string;
  className?: string;
  isIconReversed?: boolean;
  textEllipsis?: boolean;
  preventTextWrapping?: boolean;
};

const Tag: FC<Props> = ({
  iconElement,
  size = 'md',
  theme = 'primary',
  label,
  count,
  isIconReversed,
  className,
  textEllipsis,
  preventTextWrapping = false,
}) => {
  const icon = useMemo(() => {
    if (iconElement) {
      return cloneElement(iconElement, {
        className: classNames(styles.icon, iconElement.props.className),
      });
    }

    return undefined;
  }, [iconElement]);

  const classes = classNames(
    styles.wrapper,
    styles[Size[size]],
    styles[Theme[theme]],
    {
      [styles.withText]: !icon,
      [styles.withTextAndIcon]: !!icon && !isIconReversed && label,
      [styles.withTextAndIconReversed]: !!icon && isIconReversed && label,
      [styles.withIcon]: !!icon && !label,
      [styles.textEllipsis]: textEllipsis,
      [styles.noWrap]: preventTextWrapping,
    },
    className,
  );
  const counterTheme = getCounterTheme(Theme[theme]);

  return (
    <div className={classes}>
      {!!icon && icon}

      {label && <span className={styles.label}>{label}</span>}

      {count && (
        <Counter theme={counterTheme} size={size} count={count} className={styles.counter} />
      )}
    </div>
  );
};

export default memo(Tag);
