import React, { FC, memo } from 'react';

import { Content } from './components';
import { Provider } from './context';

const Leaderboard: FC = () => {
  return (
    <Provider>
      <Content />
    </Provider>
  );
};

export default memo(Leaderboard);
