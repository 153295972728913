import dayjs from 'dayjs';
import { t } from 'i18next';

import { DateUtils } from 'app/utils';

import { FilterTypes } from '../types';

const getMonthOptions = (): FilterTypes.DateOffsetOption[] => {
  const currentMonth = dayjs().get('month');

  return DateUtils.getPeriods(12, 'month').map(({ start, offset }, index) => {
    if (start.get('month') === currentMonth) {
      return { value: offset, id: `month-${index}`, label: t('common_date_this_month') };
    }

    return { value: offset, id: `month-${index}`, label: start.format('MMM YY') };
  });
};

export default getMonthOptions;
