import { createReducer } from '@reduxjs/toolkit';

import { Tournaments } from 'packages/rest';

import { settingsConstants } from '../../constants';
import { settingsActions } from '../../actions';
import { SettingsTypes } from '../../types';

const defaultState: SettingsTypes.Data = {
  title: '',
  description: '',
  activeSports: [],
  type: Tournaments.TournamentType.User,
  buyIn: 0,
  royalty: 0,
  isPrivate: false,
  prizePool: settingsConstants.DEFAULT_PRIZE_POOL_BY_TYPE[SettingsTypes.PoolType.TopOne],
};

const reducer = createReducer(defaultState, (builder) => {
  builder.addCase(settingsActions.applySettings, (state, { payload }) => {
    return payload;
  });
});

export default reducer;
