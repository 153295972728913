import React, { Children, cloneElement, memo, ReactElement, useState } from 'react';
import classNames from 'classnames';

import Option from '../Option';
import styles from './styles.module.scss';

interface Props<T extends number | string> {
  children:
    | ReactElement<Parameters<typeof Option<T>>[0]>
    | ReactElement<Parameters<typeof Option<T>>[0]>[];
  onChange?: (id: T) => void;
  activeId?: T;
  darkAppearance?: boolean;
}

const SegmentControlBar = <T extends number | string>({
  onChange,
  darkAppearance,
  activeId,
  children,
}: Props<T>) => {
  const childrenArray = Children.toArray(children) as ReactElement<
    Parameters<typeof Option<T>>[0]
  >[];
  const [localActiveOptionId, setLocalActiveOptionId] = useState(childrenArray[0].props.id);
  const isControlled = activeId !== undefined;

  const handleActiveOptionChange = (id: T) => {
    if (!isControlled) {
      setLocalActiveOptionId(id);
    }

    onChange?.(id);
  };

  return (
    <div
      className={classNames(styles.segmentControlBar, { [styles.darkAppearance]: darkAppearance })}
    >
      {Children.map(childrenArray, (child) => {
        const { id, onClick } = child.props;

        const handleClick = () => {
          handleActiveOptionChange(id);
          onClick?.();
        };

        return cloneElement(child, {
          onClick: handleClick,
          plain: !darkAppearance,
          active: isControlled ? activeId === id : localActiveOptionId === id,
        });
      })}
    </div>
  );
};

export default memo(SegmentControlBar);
