import { createReducer } from '@reduxjs/toolkit';

import { leaderboardThunks } from '../../thunks';
import { LeaderboardTypes } from '../../types';
import { leaderboardActions } from '../../actions';

const defaultState: LeaderboardTypes.Status = {
  isInitialLoading: false,
  isMoreLoading: false,
  offset: 0,
  limit: 30,
  hasNext: true,
};

const leaderboardReducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(leaderboardThunks.getInitialRating.pending, (state) => {
      state.isInitialLoading = true;
    })
    .addCase(leaderboardThunks.getInitialRating.fulfilled, (state, { payload }) => {
      const { offset, hasNext } = payload;

      return { ...state, isInitialLoading: false, offset, hasNext };
    })
    .addCase(leaderboardThunks.getInitialRating.rejected, (state) => {
      state.isInitialLoading = false;
    })
    .addCase(leaderboardThunks.getMoreRating.pending, (state) => {
      state.isMoreLoading = true;
    })
    .addCase(leaderboardThunks.getMoreRating.fulfilled, (state, { payload }) => {
      const { offset, hasNext } = payload;

      return { ...state, isMoreLoading: false, offset, hasNext };
    })
    .addCase(leaderboardThunks.getMoreRating.rejected, (state) => {
      state.isMoreLoading = false;
    })
    .addCase(leaderboardActions.cleanupState, () => {
      return defaultState;
    });
});

export default leaderboardReducer;
