export enum Theme {
  gray = 'gray',
  greyInverse = 'greyInverse',
  primary = 'primary',
  primaryActionInverse = 'primaryActionInverse',
  primaryAction = 'primaryAction',
  secondary = 'secondary',
  tertiary = 'tertiary',
  green = 'green',
  red = 'red',
  redContrast = 'redContrast',
  yellow = 'yellow',
  mint = 'mint',
  violet = 'violet',
  purple = 'purple',
  pink = 'pink',
}

export enum Size {
  md = 'medium',
  sm = 'small',
}
