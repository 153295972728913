import { Rating } from 'packages/rest';

const isPlayerRating = (
  arr?: Rating.PlayerRating[] | Rating.TeamRating[],
): arr is Rating.PlayerRating[] => {
  if (!arr || !arr[0]) {
    return true;
  }

  return (arr[0] as Rating.PlayerRating).firstName !== undefined;
};

export default isPlayerRating;
