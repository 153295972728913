import React, { memo, ReactElement, useReducer } from 'react';

import Context from './Context';
import { NavigationAction, NavigationActionType, NavigationState, TabBarItem } from '../types';

const reducer = (state: NavigationState, action: NavigationAction) => {
  switch (action.type) {
    case NavigationActionType.SET_TAB_NAVIGATION_CONFIG: {
      const { height, topOffset } = action;

      return { ...state, tabBarHeight: height, tabBarTopOffset: topOffset };
    }

    case NavigationActionType.SET_ANIMATION_CONTAINER_STATE: {
      const { active } = action;

      return { ...state, animationContainerActive: active };
    }

    case NavigationActionType.SET_ANIMATION_CONTAINER_TOP_OFFSET: {
      const { topOffset } = action;

      return { ...state, animationContainerTopOffset: topOffset };
    }

    case NavigationActionType.CHANGE_ACTIVE_TAB: {
      const { tabId, active } = action;
      const { tabs } = state;

      if (active) {
        return { ...state, activeTabId: tabId };
      }

      const tabIndex = tabs.findIndex(({ id }) => id === tabId);

      return { ...state, activeTabId: tabIndex > 0 ? tabs[tabIndex - 1].id : tabs[0].id };
    }

    default:
      throw new Error('wrong navigation action type');
  }
};

interface Props {
  tabs: TabBarItem[];
  children: ReactElement | ReactElement[];
}

const Provider = ({ children, tabs }: Props) => {
  const state = useReducer(reducer, {
    tabBarHeight: 0,
    tabBarTopOffset: 0,
    tabs,
    activeTabId: tabs[0].id,
    animationContainerActive: false,
    animationContainerTopOffset: 0,
  });

  return <Context.Provider value={state}>{children}</Context.Provider>;
};

export default memo(Provider);
