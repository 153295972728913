import { AxiosResponse } from 'axios';

import { instance } from 'app/rest';

import { SignInBody, SignInResponse } from '../types';

const signIn = ({
  provider,
  identifier,
  deviceModel,
  geo,
  ipAddress,
  osVersion,
  accessToken,
  deviceType = '3',
}: SignInBody): Promise<AxiosResponse<SignInResponse>> => {
  return instance({
    method: 'post',
    url: '/profile/api/v1/social-auth',
    data: {
      provider,
      identifier,
      access_token: accessToken,
      device_model: deviceModel,
      device_type: deviceType,
      ip_address: ipAddress,
      os_version: osVersion,
      geo,
    },
  });
};

export default signIn;
