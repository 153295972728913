import { AvailablePredictionsTypes } from '../types';
import { Sport } from '../../types';

export const DEFAULT_PREDICTIONS_STATE: AvailablePredictionsTypes.Predictions = {
  cleanMarketCodes: [],
  marketCodesWithConflict: [],
};

export const DEFAULT_PREDICTIONS_BY_SPORT_STATE: AvailablePredictionsTypes.Data['predictionsBySport'] =
  {
    [Sport.Soccer]: DEFAULT_PREDICTIONS_STATE,
    [Sport.Hockey]: DEFAULT_PREDICTIONS_STATE,
    [Sport.Tennis]: DEFAULT_PREDICTIONS_STATE,
    [Sport.Basketball]: DEFAULT_PREDICTIONS_STATE,
  };

export const DEFAULT_SELECTED_PREDICTIONS_BY_SPORT: AvailablePredictionsTypes.Data['selectedMarketCodes'] =
  {
    [Sport.Soccer]: [],
    [Sport.Hockey]: [],
    [Sport.Tennis]: [],
    [Sport.Basketball]: [],
  };
