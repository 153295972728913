import { useCallback, useReducer } from 'react';

interface State {
  activePageId: null | string;
  isActivePageShown: boolean;
}

const INITIAL_STATE: State = {
  activePageId: null,
  isActivePageShown: false,
};

enum ActionType {
  NAVIGATE_TO_CHILD = 'NAVIGATE_TO_CHILD',
  NAVIGATE_TO_PARENT = 'NAVIGATE_TO_PARENT',
}

type Action =
  | { type: ActionType.NAVIGATE_TO_CHILD; pageId: string }
  | { type: ActionType.NAVIGATE_TO_PARENT };

const reducer = (state: State, action: Action): State => {
  const { type } = action;

  switch (type) {
    case ActionType.NAVIGATE_TO_CHILD: {
      const { pageId } = action;

      return {
        isActivePageShown: true,
        activePageId: pageId,
      };
    }
    case ActionType.NAVIGATE_TO_PARENT: {
      return {
        isActivePageShown: false,
        activePageId: null,
      };
    }
    default: {
      throw new Error('Unexpected action');
    }
  }
};

const useNavigation = () => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { activePageId, isActivePageShown } = state;

  const navigateTo = useCallback((pageId: string) => {
    dispatch({ type: ActionType.NAVIGATE_TO_CHILD, pageId });
  }, []);

  const goBack = useCallback(() => {
    dispatch({ type: ActionType.NAVIGATE_TO_PARENT });
  }, []);

  return {
    isRootHidden: !!activePageId,
    isActivePageShown,
    activePageId,
    navigateTo,
    goBack,
  };
};

export default useNavigation;
