import React, { FC, memo } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

type Props = {
  className?: string;
  src?: string;
  alt?: string;
};

const Figure: FC<Props> = ({ className, src, alt = '' }) => {
  return src ? (
    <img className={className} src={src} alt={alt} />
  ) : (
    <div className={classNames(className, styles.defaultImage)} />
  );
};

export default memo(Figure);
