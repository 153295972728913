import React, { FC, memo, ReactElement } from 'react';

import { useContentElement, useSummaryElement, useResize, useGroup } from './hooks';
import Content from '../Content';
import Summary from '../Summary';
import styles from './styles.module.scss';

interface Props {
  isOpen?: boolean;
  changeIsOpen?: (isOpen: boolean) => void;
  children: (
    | ReactElement<Parameters<typeof Summary>[0]>
    | ReactElement<Parameters<typeof Content>[0]>
  )[];
}

const Group: FC<Props> = ({ children, isOpen, changeIsOpen }) => {
  const { height, summaryRef, contentRef, handlers } = useGroup({ isOpen, changeIsOpen });
  const { handleContentResize, handleSummaryClick } = handlers;

  const contentElement = useContentElement({ isOpen, ref: contentRef, changeIsOpen, children });
  const summaryElement = useSummaryElement({
    onClick: handleSummaryClick,
    ref: summaryRef,
    children,
    isOpen,
  });

  useResize({
    ref: contentRef,
    onResize: handleContentResize,
  });

  return (
    <div style={{ height: height || 'auto' }} className={styles.group}>
      {summaryElement}
      {contentElement}
    </div>
  );
};

export default memo(Group);
