import { createAsyncThunk } from '@reduxjs/toolkit';

import { LeagueEventShortInfoSchema } from 'packages/rest/Events';
import { Events } from 'packages/rest';
import { RootState } from 'app/store';
import { LeagueSchema } from 'packages/rest/types';
import { Sport } from 'packages/types';

import { groupEventsByLeagueIds } from '../../services';
import { matchesSelectors, metaSelectors } from '../../selectors';

interface ReturnType {
  leagueEvents: Record<number, LeagueEventShortInfoSchema[]>;
  offset: number;
  hasNext: boolean;
}

interface Params {
  leagues: LeagueSchema[];
}

const loadMoreMatches = createAsyncThunk<ReturnType, Params, { state: RootState }>(
  'tournamentManagement/loadMoreMatches',
  async ({ leagues }, { getState }) => {
    const state = getState();

    const { offset, limit } = matchesSelectors.getStatus(state);
    const { activeSport } = metaSelectors.getState(state);

    const leagueIds = leagues?.slice(offset, offset + limit).map(({ id }) => id);
    const { data: eventsData } = await Events.getEvents({
      sportId: activeSport as Sport,
      leagueIds,
    });
    const { events } = eventsData;

    return {
      leagueEvents: groupEventsByLeagueIds(events),
      offset: offset + limit,
      hasNext: offset + limit <= leagues?.length,
    };
  },
);

export default loadMoreMatches;
