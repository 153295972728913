import { useMemo } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { UniqueIdentifier } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

interface Params {
  id: UniqueIdentifier;
  useDragHandle?: boolean;
}

const useSortableItem = ({ id, useDragHandle }: Params) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    setActivatorNodeRef,
  } = useSortable({
    id,
  });

  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef],
  );

  const sortableProps = useMemo(
    () => (useDragHandle ? {} : { ...attributes, ...listeners }),
    [attributes, listeners, useDragHandle],
  );

  const props = useMemo(
    () => ({
      ref: setNodeRef,
      style: {
        touchAction: 'none',
        transform: CSS.Translate.toString(transform),
        transition,
      },
    }),
    [setNodeRef, transform, transition],
  );

  return {
    context,
    props,
    sortableProps,
    isDragging,
  };
};

export default useSortableItem;
