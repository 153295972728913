import React, { FC, memo, useMemo } from 'react';

import { Avatar, Icon } from 'packages/shared/components';
import { UserGroup } from 'packages/rest/types';
import { Tournaments } from 'packages/rest';

import styles from './playerCell.module.scss';

type Props = {
  value: Pick<Tournaments.User, 'firstName' | 'lastName' | 'photo' | 'groups'>;
};

const PlayerCell: FC<Props> = ({ value }) => {
  const { photo, lastName, firstName, groups } = value;
  const isCelebrity = useMemo(
    () => groups.some((group) => group === UserGroup.CELEBRITY),
    [groups],
  );

  return (
    <div className={styles.wrapper}>
      <Avatar src={photo} size="md" useEmptyUserIcon />
      <div className={styles.name}>
        {firstName} {lastName}
        {isCelebrity && (
          <Icon className={styles.celebrityIcon} id="checkedInCircle" module="other" />
        )}
      </div>
    </div>
  );
};

export default memo(PlayerCell);
