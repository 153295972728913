import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';

import { DonateRequest, DonateResponse } from '../types';

const donate = ({ userId, coins }: DonateRequest): Promise<AxiosResponse<DonateResponse>> => {
  return authInstance({ method: 'post', url: `/v1/users/${userId}/coins/donate`, data: { coins } });
};

export default donate;
