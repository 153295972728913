import React, { memo } from 'react';

import { useAppSelector } from 'app/store';
import { lineUpSelectors } from 'packages/leaderboard';
import { Figure } from 'packages/shared/components';

import styles from './eventResult.module.scss';

const EventResult = () => {
  const { event } = useAppSelector(lineUpSelectors.getDetails) || {};
  const { isInitialLoading } = useAppSelector(lineUpSelectors.getStatus);

  const { img11, img22, score } = event || {};

  return (
    <div className={styles.wrapper}>
      {isInitialLoading ? (
        '... : ...'
      ) : (
        <>
          <Figure className={styles.teamLogo} src={img11} />
          <div className={styles.result}>{score}</div>
          <Figure className={styles.teamLogo} src={img22} />
        </>
      )}
    </div>
  );
};

export default memo(EventResult);
