import { AxiosRequestConfig, AxiosInstance } from 'axios';

import configureAuthInstance from './configureAuthInstance';
import configureInstance from './configureInstance';

let instance: AxiosInstance;
let authInstance: AxiosInstance;

const configure = (requestConfig: AxiosRequestConfig): void => {
  instance = configureInstance(requestConfig);
  authInstance = configureAuthInstance(requestConfig);
};

export { configure, instance, authInstance };
