import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';

interface Params {
  debounceTime: number;
  useDebounce: boolean;
  resetValueOn: any[];
  onChange?: (value: string) => void;
  initialValue?: string;
}

const useSearch = ({
  onChange,
  debounceTime,
  useDebounce,
  resetValueOn,
  initialValue = '',
}: Params) => {
  const [focused, setFocused] = useState<boolean>();
  const [searchValue, setSearchValue] = useState<string>(initialValue);

  const changeWithDebounce = useMemo(
    () =>
      debounce((searchValue: string) => {
        onChange?.(searchValue);
      }, debounceTime),
    [onChange, debounceTime],
  );

  useEffect(() => {
    setSearchValue(initialValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeWithDebounce, initialValue, ...resetValueOn]);

  const handleDebouncedChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value: searchValue } = event.target;

      if (!searchValue) {
        changeWithDebounce.cancel();
        onChange?.('');
      } else {
        changeWithDebounce(searchValue);
      }

      setSearchValue(searchValue);
    },
    [changeWithDebounce, onChange],
  );

  const handlePlainChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value);
      onChange?.(event.target.value);
    },
    [onChange],
  );

  const handleChange = useDebounce ? handleDebouncedChange : handlePlainChange;
  const handleFocus = useCallback(() => setFocused(true), []);
  const handleBlur = useCallback(() => setFocused(false), []);
  const handleClear = useCallback(
    (event: MouseEvent<SVGSVGElement>) => {
      event.preventDefault();

      if (!searchValue) {
        return;
      }

      setSearchValue('');
      onChange?.('');
    },
    [onChange, searchValue],
  );

  return {
    state: { focused, searchValue },
    handlers: {
      handleChange,
      handleClear,
      handleFocus,
      handleBlur,
    },
  };
};

export default useSearch;
