import { createContext } from 'react';

import { SortableContext } from '../types';

const SortableItemContext = createContext<SortableContext>({
  attributes: {},
  listeners: undefined,
  ref: () => null,
});

export default SortableItemContext;
