import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';

import { GetTournamentExpressEventsRequest, GetTournamentExpressEventsResponse } from '../types';

const getTournamentExpressEvents = ({
  tournamentId,
}: GetTournamentExpressEventsRequest): Promise<
  AxiosResponse<GetTournamentExpressEventsResponse>
> => {
  return authInstance({
    method: 'get',
    url: `/v1/tournaments/${tournamentId}/express/events`,
  });
};

export default getTournamentExpressEvents;
