import { createSelector } from '@reduxjs/toolkit';

import { Tournaments } from 'packages/rest';

import { ExpressEventsTypes } from '../../types';
import getState from './getState';

export const getData = createSelector(
  getState,
  (state: ExpressEventsTypes.State): Tournaments.GetTournamentExpressEventsResponse => state.data,
);
