import { createAsyncThunk } from '@reduxjs/toolkit';

import { localStorage, StorageKey } from 'app/storage';
import { Social } from 'packages/rest';
import { LogoutBody } from 'packages/rest/Social';

const logout = createAsyncThunk<void, LogoutBody>('auth/logout', async (params) => {
  await Social.logout(params);

  localStorage.remove(StorageKey.AccessToken);
  localStorage.remove(StorageKey.RefreshToken);
});

export default logout;
