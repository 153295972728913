import React, { memo } from 'react';

import { Avatar } from 'packages/shared/components';
import { useAppSelector } from 'app/store';
import { detailsSelectors } from 'packages/leaderboard';

import styles from './detailsHeader.module.scss';

const DetailsHeader = () => {
  const { name, photo } = useAppSelector(detailsSelectors.getData);

  return (
    <div className={styles.wrapper}>
      <Avatar size="sm" src={photo} />
      <span className={styles.name}>{name}</span>
    </div>
  );
};

export default memo(DetailsHeader);
