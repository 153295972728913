import React, { memo, useState } from 'react';

import { ModalContent } from 'packages/shared/components';

import { FiltersKit, DateRange } from './components';
import { ContentType } from './types';
import styles from './styles.module.scss';
import { Provider } from './context';

const CONTENT_COMPONENT = {
  [ContentType.FiltersKit]: FiltersKit,
  [ContentType.DateRange]: DateRange,
};

const Filter = () => {
  const [content, setContent] = useState<ContentType>(ContentType.FiltersKit);
  const Content = CONTENT_COMPONENT[content];

  return (
    <ModalContent containerClassName={styles.container}>
      <Provider>
        <Content onContentChange={setContent} />
      </Provider>
    </ModalContent>
  );
};

export default memo(Filter);
