import React, { cloneElement, FC, memo, ReactElement, useMemo } from 'react';
import classNames from 'classnames';

import { Size, Theme } from './types';
import styles from './styles.module.scss';

type Props = {
  id?: string | number;
  size?: keyof typeof Size;
  theme?: keyof typeof Theme;
  leftContent?: string | ReactElement;
  rightContent?: string | ReactElement;
  label?: string;
  children?: string;
  className?: string;
  textResponsive?: boolean;
  disabled?: boolean;
  iconElement?: ReactElement;
  onClick?: () => void;
};

const Button: FC<Props> = ({
  id,
  iconElement,
  size = 'md',
  theme = 'primary',
  textResponsive = false,
  disabled = false,
  className,
  rightContent,
  leftContent,
  children,
  label,
  onClick,
}) => {
  const icon = useMemo(
    () =>
      iconElement ? (
        cloneElement(iconElement, {
          className: classNames(styles.icon, iconElement.props?.className),
        })
      ) : (
        <></>
      ),
    [iconElement],
  );
  const isSideContentExist = leftContent || rightContent;
  const buttonStyles = classNames(
    styles.button,
    styles[Theme[theme]],
    styles[Size[size]],
    { [styles.textResponsive]: textResponsive, [styles.withSideContent]: isSideContentExist },
    className,
  );

  return (
    <button
      id={`${id}`}
      onClick={onClick}
      disabled={disabled}
      className={buttonStyles}
      type="button"
    >
      {isSideContentExist && <span className={styles.leftContent}>{leftContent}</span>}

      <span className={styles.middleContent}>
        {icon}
        {!!label && <span className={styles.label}>{label}</span>}
        {!!children && <span className={styles.description}>{children}</span>}
      </span>

      {isSideContentExist && <span className={styles.rightContent}>{rightContent}</span>}
    </button>
  );
};

export default memo(Button);
