import { Friends } from 'packages/rest';

const filterUsers = (users: Friends.SearchResponse[], searchValue: string) => {
  const filterValue = searchValue.toLowerCase();

  if (!searchValue) {
    return users;
  }

  return users.filter(
    ({ firstName, lastName, teamName }) =>
      (firstName || '').toLowerCase().includes(filterValue) ||
      (lastName || '').toLowerCase().includes(filterValue) ||
      (teamName || '').toLowerCase().includes(filterValue),
  );
};

export default filterUsers;
