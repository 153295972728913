import { createAsyncThunk } from '@reduxjs/toolkit';

import { Tournaments } from 'packages/rest';

interface Params {
  tournamentId: string | number;
}

const getTournament = createAsyncThunk<Tournaments.GetTournamentByIdResponse, Params>(
  'superExpress/getTournament',
  async ({ tournamentId }) => {
    const { data } = await Tournaments.getTournamentById({ tournamentId });

    return data;
  },
);

export default getTournament;
