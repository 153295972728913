import React, { FC, memo } from 'react';
import classNames from 'classnames';

import { CounterTheme, Size } from './types';
import styles from './styles.module.scss';

type Props = {
  count: number;
  className?: string;
  size?: keyof typeof Size;
  theme?: keyof typeof CounterTheme;
};

const Counter: FC<Props> = ({ count, theme = 'active', size = 'md', className }) => {
  return (
    <div
      className={classNames(
        styles.counter,
        styles[CounterTheme[theme]],
        styles[Size[size]],
        className,
      )}
    >
      {count}
    </div>
  );
};

export default memo(Counter);
