import React, { FC, memo, ReactElement } from 'react';
import classNames from 'classnames';

import { ContentYAlignment, Size } from './types';
import styles from './styles.module.scss';
import { UserIcon } from './components';

type Props = {
  src?: string;
  className?: string;
  useBorderRadius?: boolean;
  useEmptyUserIcon?: boolean;
  children?: ReactElement;
  contentYPosition?: keyof typeof ContentYAlignment;
  size?: keyof typeof Size;
  onClick?: () => void;
};

const Avatar: FC<Props> = ({
  src,
  children,
  useEmptyUserIcon,
  size = 'sm',
  className,
  useBorderRadius = true,
  contentYPosition = 'center',
  onClick,
}) => {
  const baseStyles = classNames(
    styles.avatar,
    styles[Size[size]],
    {
      [styles.withRadius]: useBorderRadius,
    },
    className,
  );

  if (src) {
    return <img onClick={onClick} className={baseStyles} src={src} alt="avatar" />;
  }

  if (children) {
    return (
      <div
        onClick={onClick}
        className={classNames(baseStyles, styles.content, styles[contentYPosition])}
      >
        {children}
      </div>
    );
  }

  return (
    <div onClick={onClick} className={classNames(baseStyles, styles.empty)}>
      {useEmptyUserIcon && <UserIcon className={styles.userIcon} />}
    </div>
  );
};

export default memo(Avatar);
