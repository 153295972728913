import React, { memo } from 'react';
import { NoticeProps } from 'react-select';
import classNames from 'classnames';

import { Option } from '../types';
import styles from './option.module.scss';

const NoOptionsMessage = <V extends string | number, T extends Option<V>>({
  innerProps,
  children,
  className,
}: NoticeProps<T>) => {
  return (
    <div {...innerProps} className={classNames(styles.option, className)}>
      <div className={styles.label}>{children}</div>
    </div>
  );
};

export default memo(NoOptionsMessage);
