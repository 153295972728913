import React, { FC, memo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckList, CheckListTypes, DefaultNavBar } from 'packages/shared/components';
import { useAppSelector } from 'app/store';
import { filterSelectors } from 'packages/leaderboard';

import { ContentType } from '../../types';
import styles from './styles.module.scss';
import { Context } from '../../context';

type Props = {
  onContentChange: React.Dispatch<React.SetStateAction<ContentType>>;
};

const DateRange: FC<Props> = ({ onContentChange }) => {
  const { t } = useTranslation();
  const { filterPreset, updatePreset } = useContext(Context);
  const { dateOffset: selectedOption, dateType } = filterPreset;
  const selectOptions = useAppSelector(filterSelectors.getOptions);
  const options = selectOptions[dateType];

  const handleBackClick = useCallback(() => {
    onContentChange(ContentType.FiltersKit);
  }, [onContentChange]);

  const handleCheck = useCallback(
    (option: CheckListTypes.Option<number>) => {
      updatePreset({ dateOffset: option });
      onContentChange(ContentType.FiltersKit);
    },
    [updatePreset, onContentChange],
  );

  return (
    <>
      <DefaultNavBar
        className={styles.header}
        title={t('common_date_range')}
        onLeftClick={handleBackClick}
      />
      <CheckList.Single
        selectedOption={selectedOption}
        onCheck={handleCheck}
        className={styles.contentContainer}
      >
        {options.map((option) => (
          <CheckList.Option key={option.id} {...option} />
        ))}
      </CheckList.Single>
    </>
  );
};

export default memo(DateRange);
