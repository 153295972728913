import React, { FC, memo, ReactElement } from 'react';

import { Footer } from './components';
import styles from './styles.module.scss';

type Props = {
  children: ReactElement | ReactElement[];
};

const NavigationLayout: FC<Props> = ({ children }) => {
  return (
    <div className={styles.layout}>
      {children}
      <Footer />
    </div>
  );
};

export default memo(NavigationLayout);
