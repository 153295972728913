import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';

import { FollowRequest, FollowResponse } from '../types';

const follow = ({ userId }: FollowRequest): Promise<AxiosResponse<FollowResponse>> => {
  return authInstance({ method: 'post', url: `/v1/friends/${userId}/follow` });
};

export default follow;
