import React, { FC, memo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { NavBar } from 'packages/shared/components';
import { Modal } from 'app/context';

import AppearanceFilter from './AppearanceFilter';
import MeasurementFilter from './MeasurementFilter';
import UnitFilter from './UnitFilter';
import SportFilter from './SportFilter';
import NavBarTitle from './NavBarTitle';
import ApplyButton from './ApplyButton';
import { ContentType } from '../../types';
import styles from './styles.module.scss';

type Props = {
  onContentChange: React.Dispatch<React.SetStateAction<ContentType>>;
};

const FiltersKit: FC<Props> = ({ onContentChange }) => {
  const { updateActiveModals } = useContext(Modal.Context);
  const { t } = useTranslation();

  const handleCancelClick = useCallback(() => {
    updateActiveModals({ leaderboardFilter: false });
  }, [updateActiveModals]);

  const handleDateRangeClick = useCallback(() => {
    onContentChange(ContentType.DateRange);
  }, [onContentChange]);

  return (
    <>
      <NavBar
        className={styles.header}
        sideContentClassName={styles.sideContent}
        leftContent={<span onClick={handleCancelClick}>{t('common_button_cancel')}</span>}
        titleContent={<NavBarTitle />}
        rightContent={<ApplyButton />}
      />
      <div className={styles.content}>
        <AppearanceFilter onDateRangeClick={handleDateRangeClick} />
        <SportFilter />
        <UnitFilter />
        <MeasurementFilter />
      </div>
    </>
  );
};

export default memo(FiltersKit);
