import { RefObject } from 'react';

type Params = {
  containerRef: RefObject<HTMLDivElement>;
  navigationRef: RefObject<HTMLDivElement>;
};

const setContainerTopOffset = ({ containerRef, navigationRef }: Params) => {
  if (navigationRef?.current && containerRef.current) {
    const navigationElementHeight = navigationRef?.current?.getBoundingClientRect().height || 0;

    containerRef.current.style.paddingTop = `${navigationElementHeight}px`;
  }
};

export default setContainerTopOffset;
