import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import MenuLink from '../MenuLink';
import styles from './styles.module.scss';

const TrackingLinks: FC = () => {
  const { t } = useTranslation();

  return (
    <ul className={styles.wrapper}>
      <li>
        <MenuLink label={t('common_teams')} />
      </li>
      <li>
        <MenuLink label={t('common_fanclubs')} />
      </li>
      <li>
        <MenuLink label={t('common_national_teams')} />
      </li>
      <li>
        <MenuLink label={t('common_sport_line')} />{' '}
      </li>
    </ul>
  );
};

export default memo(TrackingLinks);
