import React, { FC, memo, ReactElement } from 'react';
import classNames from 'classnames';

import { Icon } from 'packages/shared/components';

import { Theme } from './types';
import styles from './styles.module.scss';

type Props = {
  iconElement: ReactElement;
  theme: keyof typeof Theme;
  header: string;
  children: string | ReactElement;
  className?: string;
};

const Note: FC<Props> = ({ iconElement, header, children, theme, className }) => {
  return (
    <div className={classNames(styles.wrapper, styles[theme], className)}>
      <div className={styles.header}>
        {iconElement}
        <div className={styles.text}>{header}</div>
        <Icon id="chevronRight" module="system" />
      </div>

      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default memo(Note);
