import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';

import { CheckQuizRequest, CheckQuizResponse } from '../types';

const checkQuiz = ({ quizId }: CheckQuizRequest): Promise<AxiosResponse<CheckQuizResponse>> => {
  return authInstance({
    method: 'get',
    url: `/v1/quiz/${quizId}/reset/check`,
  });
};

export default checkQuiz;
