import { createAction } from '@reduxjs/toolkit';

import { AvailablePredictionsTypes } from '../types';
import { Sport } from '../../types';

export const resolveConflict = createAction<{ sport: Sport; eventIds: number[] }>(
  'tournamentManagement/resolveConflict',
);

export const updateSelectedMarketCodes = createAction<{
  selectedMarketCodes: AvailablePredictionsTypes.CleanMarketCode[];
  sport: Sport;
}>('tournamentManagement/updateSelectedMarketCodes');

export const reorderSelectedMarketCodes = createAction(
  'tournamentManagement/reorderSelectedMarketCodes',
);

export const updateCleanMarketCodes = createAction<{
  cleanMarketCodes: AvailablePredictionsTypes.CleanMarketCode[];
  sport: Sport;
}>('tournamentManagement/updateCleanMarketCodes');

export const cleanupPredictions = createAction('tournamentManagement/cleanupPredictions');
