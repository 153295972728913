import { createAsyncThunk } from '@reduxjs/toolkit';

import { Rating } from 'packages/rest';
import { RootState } from 'app/store';

import { FilterTypes } from '../types';
import { ratingSelectors } from '../selectors';

type ReturnType = {
  rating: Rating.TeamRating[] | Rating.PlayerRating[];
  userTeam?: Rating.CurrentUserTeam;
  user?: Rating.CurrentUser;
  offset: number;
  hasNext: boolean;
};

interface Params extends FilterTypes.Filter {
  search: string;
}

const getInitialRating = createAsyncThunk<ReturnType, Params, { state: RootState }>(
  'leaderboard/getInitialRating',
  async (params, { getState }) => {
    const { unit, sport, measurement, dateOffset, onlyFriends, dateType, search } = params;
    const { limit } = ratingSelectors.getStatus(getState());

    const { data } = await Rating.getRating({
      offset: 0,
      limit,
      type: unit === Rating.UnitType.Players && onlyFriends ? Rating.UnitType.Friends : unit,
      order: measurement,
      dateOffset: dateOffset.value,
      dateType,
      sportId: sport.value === FilterTypes.SportId.All ? undefined : sport.value,
      search,
    });

    return {
      userTeam: data.my,
      user: data.me,
      rating: data.teams || data.all || [],
      offset: limit,
      hasNext: limit <= (data.teams || data.all || []).length,
    };
  },
);

export default getInitialRating;
