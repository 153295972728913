import { useEffect } from 'react';

const useAutosize = (textAreaElement: HTMLTextAreaElement | null, value?: string | number) => {
  useEffect(() => {
    if (textAreaElement) {
      textAreaElement.style.height = '0px';

      const { scrollHeight } = textAreaElement;

      textAreaElement.style.height = `${scrollHeight}px`;
    }
  }, [textAreaElement, value]);
};

export default useAutosize;
