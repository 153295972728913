import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';

import { GetQuestionsRequest, GetQuestionsResponse } from '../types';

const getQuestions = ({
  quizId,
}: GetQuestionsRequest): Promise<AxiosResponse<GetQuestionsResponse>> => {
  return authInstance({ method: 'get', url: `v1/quiz/${quizId}/questions` });
};

export default getQuestions;
