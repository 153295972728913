import React, { FC, memo, ReactElement, RefObject } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

type Props = {
  children?: ReactElement;
  className?: string;
  sideContentClassName?: string;
  rightContent?: string | ReactElement;
  leftContent?: string | ReactElement;
  descriptionContent?: string | ReactElement;
  titleContent?: string | ReactElement;
  navBarRef?: RefObject<HTMLDivElement>;
};

const NavBar: FC<Props> = ({
  rightContent,
  leftContent,
  descriptionContent,
  titleContent,
  className,
  children,
  sideContentClassName,
  navBarRef,
}) => {
  const classes = classNames(
    styles.navigation,
    {
      [styles.contentCentered]: !!leftContent,
    },
    className,
  );

  return (
    <div ref={navBarRef} className={classes}>
      <div className={classNames(styles.leftContent, sideContentClassName)}>{leftContent}</div>

      {children ? (
        <>{children}</>
      ) : (
        <div className={styles.mainContent}>
          {!!descriptionContent && <div className={styles.description}>{descriptionContent}</div>}
          {!!titleContent && <div className={styles.title}>{titleContent}</div>}
        </div>
      )}

      <div className={classNames(styles.rightContent, sideContentClassName)}>{rightContent}</div>
    </div>
  );
};

export default memo(NavBar);
