import React, { FC, memo, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import { getAuthorized } from 'packages/auth/selectors';

import { AvailableFor } from './types';
import { ROOT } from '../../constants';
import { Page } from '../../types';

type Props = {
  children: ReactElement;
  availableFor?: keyof typeof AvailableFor;
};

const ProtectedRoute: FC<Props> = ({ children, availableFor = 'user' }) => {
  const isUserAuthorized = useAppSelector(getAuthorized);

  if (!isUserAuthorized && availableFor === AvailableFor.user) {
    return <Navigate to={ROOT[Page.SignIn]} replace />;
  }

  if (isUserAuthorized && availableFor === AvailableFor.guest) {
    return <Navigate to={ROOT[Page.Home]} replace />;
  }

  return children;
};

export default memo(ProtectedRoute);
