import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { tournamentsThunks } from '../../../thunks';
import { TournamentsTypes } from '../../../types';

const defaultState: TournamentsTypes.Status = {
  isLoading: false,
  isAllItemsShown: false,
  isCollapsed: false,
};

const reducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(tournamentsThunks.getCompleted.pending, (state) => {
      return { ...state, isLoading: true };
    })
    .addMatcher(
      isAnyOf(tournamentsThunks.getCompleted.fulfilled, tournamentsThunks.getCompleted.rejected),
      (state) => {
        return { ...state, isLoading: false };
      },
    );
});

export default reducer;
