import { createReducer } from '@reduxjs/toolkit';

import { setLocaleCode, setAppIdentifier } from '../actions';
import { State } from '../types';

const defaultState: State = {};

const reducer = createReducer(defaultState, (builder) => {
  builder.addCase(setLocaleCode, (state, { payload }) => {
    state.localeCode = payload;
  });
  builder.addCase(setAppIdentifier, (state, { payload }) => {
    state.appIdentifier = payload;
  });
});

export default reducer;
