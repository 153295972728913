import React, { FC, memo, ReactElement } from 'react';
import classNames from 'classnames';

import styles from './container.module.scss';

type Props = {
  header?: string;
  children: ReactElement | ReactElement[];
  className?: string;
};

const Container: FC<Props> = ({ children, header, className }) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      {header && <div className={styles.header}>{header}</div>}

      <ul>{children}</ul>
    </div>
  );
};

export default memo(Container);
