import React, { FC, memo } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { Icon } from 'packages/shared/components';

import { useCurrentDate, useFlags } from './hooks';
import styles from './styles.module.scss';

interface Props {
  activeDate?: dayjs.Dayjs;
  onChange?: (date: dayjs.Dayjs) => void;
  disableBefore?: dayjs.Dayjs;
  disableAfter?: dayjs.Dayjs;
}

const Calendar: FC<Props> = ({ activeDate, onChange, disableBefore, disableAfter }) => {
  const { currentDate, prevMonthDayNumbers, currentMonthDays, handlers } = useCurrentDate();
  const { isActiveDay, isCurrentDay, isDayDisabled, isNextMonthDisabled, isPreviousMonthDisabled } =
    useFlags({ currentDate, activeDate, disableBefore, disableAfter });

  const getDayClickHandler = (day: dayjs.Dayjs) => () => {
    onChange?.(day);
  };

  return (
    <div className={styles.calendar}>
      <div className={styles.header}>
        <Icon
          onClick={isPreviousMonthDisabled ? undefined : handlers.handleLeftClick}
          module="system"
          id="chevronLeft"
          className={classNames(styles.control, { [styles.disabled]: isPreviousMonthDisabled })}
        />
        <div className={styles.title}>{currentDate.format('MMMM YYYY')}</div>
        <Icon
          onClick={isNextMonthDisabled ? undefined : handlers.handleRightClick}
          module="system"
          id="chevronRight"
          className={classNames(styles.control, { [styles.disabled]: isNextMonthDisabled })}
        />
      </div>

      <div className={styles.weekdays}>
        {dayjs.weekdaysShort().map((weekday) => (
          <div className={styles.weekday} key={weekday}>
            {weekday}
          </div>
        ))}
      </div>

      <div className={styles.days}>
        {prevMonthDayNumbers.map((day) => (
          <div className={styles.day} key={day} />
        ))}

        {currentMonthDays.map((day) => {
          const isDisabled = isDayDisabled(day);
          const dayNumber = day.date();

          return (
            <div
              onClick={isDisabled ? undefined : getDayClickHandler(day)}
              className={classNames(styles.day, {
                [styles.current]: isCurrentDay(day),
                [styles.active]: isActiveDay(day),
                [styles.disabled]: isDisabled,
              })}
              key={dayNumber}
            >
              {dayNumber}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(Calendar);
