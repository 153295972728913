import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';

import { GetRatingRequest, GetRatingResponse } from '../types';

const getRating = ({
  type,
  sportId,
  dateType,
  dateOffset,
  teamId,
  order,
  limit,
  offset,
  search,
}: GetRatingRequest): Promise<AxiosResponse<GetRatingResponse>> => {
  return authInstance({
    method: 'get',
    url: `/v1/rating/${type}`,
    params: {
      offset,
      limit,
      sportId,
      dateType,
      dateOffset,
      order,
      search,
    },
  });
};

export default getRating;
