import { createAsyncThunk } from '@reduxjs/toolkit';

import { LeagueEventShortInfoSchema } from 'packages/rest/Events';
import { Events } from 'packages/rest';
import { RootState } from 'app/store';
import { LeagueSchema } from 'packages/rest/types';
import { Sport } from 'packages/types';

import { groupEventsByLeagueIds } from '../../services';
import { metaSelectors } from '../../selectors';
import { matchesConstants } from '../../constants';

interface ReturnType {
  leagueEvents: Record<number, LeagueEventShortInfoSchema[]>;
  offset: number;
}

interface Params {
  leagues: LeagueSchema[];
}

const loadMatches = createAsyncThunk<ReturnType, Params, { state: RootState }>(
  'tournamentManagement/loadInitialMatches',
  async ({ leagues }, { getState }) => {
    const state = getState();
    const { activeSport } = metaSelectors.getState(state);
    const offset = matchesConstants.INITIAL_OFFSET;
    const limit = matchesConstants.INITIAL_LIMIT;

    const { data: eventsData } = await Events.getEvents({
      leagueIds: leagues.slice(offset, limit).map(({ id }) => id),
      sportId: activeSport as Sport,
    });
    const { events } = eventsData;

    return {
      leagueEvents: groupEventsByLeagueIds(events),
      offset: offset + limit,
      hasNext: offset < leagues.length,
    };
  },
);

export default loadMatches;
