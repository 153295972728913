import { RefObject, useEffect, useMemo } from 'react';

interface Params {
  onResize?: (height: number) => void;
  ref?: RefObject<HTMLDivElement>;
}

const useResize = ({ onResize, ref }: Params) => {
  const resizeObserver = useMemo(
    () =>
      new ResizeObserver(([entry]) => {
        onResize?.(entry.contentRect.height);
      }),
    [onResize],
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const observable = ref?.current;

    if (observable) {
      resizeObserver.observe(observable);

      return () => {
        resizeObserver.unobserve(observable);
      };
    }
  }, [ref, resizeObserver]);
};

export default useResize;
