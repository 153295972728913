import { combineReducers } from '@reduxjs/toolkit';

import settings from './settings';
import leagues from './leagues';
import matches from './matches';
import predictions from './predictions';
import matchesFilter from './matchesFilter';
import availablePredictions from './availablePredictions';
import invitations from './invitations';
import meta from './meta';

const reducer = combineReducers({
  settings,
  leagues,
  matches,
  predictions,
  matchesFilter,
  availablePredictions,
  invitations,
  meta,
});

export default reducer;
