import { combineReducers } from '@reduxjs/toolkit';

import questions from './questions';
import details from './details';
import statistics from './statistics';
import leaderboard from './leaderboard';

const reducer = combineReducers({ questions, details, statistics, leaderboard });

export default reducer;
