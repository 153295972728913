import React, { FC, memo, useContext, useEffect } from 'react';
import classNames from 'classnames';

import { ModalContent } from 'packages/shared/components';
import { commonActions, filterSelectors, getInitialRating } from 'packages/leaderboard';
import { useAppDispatch, useAppSelector } from 'app/store';

import { Context } from '../../context';
import { ContentType } from '../../types';
import styles from './styles.module.scss';
import Details from '../Details';
import LineUp from '../LineUp';
import LeaderboardTable from '../LeaderboardTable';

const CONTENT_COMPONENT = {
  [ContentType.LeaderboardTable]: LeaderboardTable,
  [ContentType.Details]: Details,
  [ContentType.LineUp]: LineUp,
};

const Leaderboard: FC = () => {
  const dispatch = useAppDispatch();
  const [activeContentType] = useContext(Context);
  const ContentElement = CONTENT_COMPONENT[activeContentType];
  const activeFilter = useAppSelector(filterSelectors.getActiveFilter);
  const searchValue = useAppSelector(filterSelectors.getSearch);

  useEffect(() => {
    dispatch(getInitialRating({ ...activeFilter, search: searchValue }));

    return () => {
      dispatch(commonActions.cleanupState());
    };
  }, [dispatch, activeFilter, searchValue]);

  return (
    <ModalContent
      containerClassName={styles.container}
      contentClassName={classNames(styles.content, {
        [styles.withoutPadding]: activeContentType !== ContentType.Details,
      })}
    >
      <ContentElement />
    </ModalContent>
  );
};

export default memo(Leaderboard);
