import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { getInitialLineUpDetails, getMoreLineUpDetails } from '../../thunks';
import { LineUpTypes } from '../../types';
import { commonActions, lineUpActions } from '../../actions';

const defaultState: LineUpTypes.Data = {};

const reducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(lineUpActions.selectLineUp, (state, { payload }) => {
      const { teamName, tournamentId, eventId, teamId } = payload;

      return { ...state, teamName, tournamentId, eventId, teamId };
    })
    .addCase(getInitialLineUpDetails.fulfilled, (state, { payload }) => {
      const { details } = payload;

      state.details = details;
    })
    .addCase(getMoreLineUpDetails.fulfilled, (state, { payload }) => {
      const { details } = payload;

      if (state.details?.all) {
        state.details.all = [...state.details.all, ...details.all];
      }
    })
    .addMatcher(isAnyOf(lineUpActions.cleanupState, commonActions.cleanupState), () => {
      return defaultState;
    });
});

export default reducer;
