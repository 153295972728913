import React, { FC, memo, ReactElement } from 'react';

import { Provider } from '../../context';
import { TabBarItem } from '../../types';

type Props = {
  tabs: TabBarItem[];
  children: ReactElement | ReactElement[];
};

const Container: FC<Props> = ({ children, tabs }) => {
  return <Provider tabs={tabs}>{children}</Provider>;
};

export default memo(Container);
