import React, { FC, memo } from 'react';
import classNames from 'classnames';

import { ArrayUtils } from 'app/utils';

import styles from './styles.module.scss';

interface Props {
  steps: number;
  activeStep?: number;
}

const Steps: FC<Props> = ({ steps, activeStep = 1 }) => {
  const stepElements = ArrayUtils.getArrayFromNumber(steps);

  return (
    <div className={styles.steps}>
      {stepElements.map((element) => (
        <div
          key={element}
          className={classNames(styles.step, { [styles.active]: element <= activeStep })}
        >
          {element !== 0 && <div className={styles.connector} />}
          <div className={styles.point} />
        </div>
      ))}
    </div>
  );
};

export default memo(Steps);
