import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { predictionsThunks } from '../../thunks';
import { PredictionsTypes } from '../../types';

const defaultState: PredictionsTypes.Status = {
  isLoading: true,
};

const reducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(predictionsThunks.loadPredictions.pending, () => {
      return defaultState;
    })
    .addMatcher(
      isAnyOf(
        predictionsThunks.loadPredictions.fulfilled,
        predictionsThunks.loadPredictions.rejected,
      ),
      () => {
        return { isLoading: false };
      },
    );
});

export default reducer;
