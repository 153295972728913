import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { Tournaments } from 'packages/rest';

import {
  settingsSelectors,
  availablePredictionsSelectors,
  matchesSelectors,
} from '../../selectors';
import { getPlacesFromPoolType, getLValueFromPoolType } from '../../services';

type ReturnType = Tournaments.CreateTournamentResponse;

type Params =
  | {
      playersIds?: number[];
    }
  | undefined;

const createTournament = createAsyncThunk<ReturnType, Params, { state: RootState }>(
  'tournamentManagement/createTournament',
  async ({ playersIds } = {}, { getState }) => {
    const state = getState();
    const selectedMarketCodes = availablePredictionsSelectors.getSelectedMarketCodesMap(state);
    const selectedEventsIds = matchesSelectors.getSelectedEventsIds(state);
    const { title, description, buyIn, royalty, type, isPrivate, prizePool } =
      settingsSelectors.getData(state);

    const { data } = await Tournaments.createTournament({
      name: title,
      // TODO: replace with image selected by user
      img: 'https:\\/\\/bitnet-images-prod.ams3.digitaloceanspaces.com\\/olimpbattle\\/media\\/avatars\\/football\\/football_3_black.png',
      royalty,
      type,
      cost: buyIn || undefined,
      l: getLValueFromPoolType(prizePool),
      eventsCodes: selectedMarketCodes,
      events: selectedEventsIds,
      players: playersIds,
      mode: buyIn ? Tournaments.TournamentMode.BuyIn : Tournaments.TournamentMode.Free,
      rulesType: prizePool.poolType as unknown as Tournaments.TournamentRule,
      rules: {
        places: getPlacesFromPoolType(prizePool),
        text: description,
      },
      visibility: isPrivate
        ? Tournaments.TournamentVisibility.Private
        : Tournaments.TournamentVisibility.Public,
    });

    return data;
  },
);

export default createTournament;
