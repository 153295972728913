import { v4 as uuid } from 'uuid';

import { AppThunk } from 'app/store';
import { localStorage, StorageKey } from 'app/storage';

import { setAppIdentifier } from '../actions';

const configureAppIdentifier = (appIdentifier: ReturnType<typeof uuid>): AppThunk => {
  return (dispatch) => {
    localStorage.set<ReturnType<typeof uuid>>(StorageKey.AppIdentifier, appIdentifier);

    dispatch(setAppIdentifier(appIdentifier));
  };
};

export default configureAppIdentifier;
