import { createAsyncThunk } from '@reduxjs/toolkit';

import { Rating } from 'packages/rest';
import { RootState } from 'app/store';

import { LeaderboardTypes } from '../../types';
import { leaderboardSelectors } from '../../selectors';

interface ReturnParams extends LeaderboardTypes.Data {
  offset: number;
  hasNext: boolean;
}

const getInitialRating = createAsyncThunk<ReturnParams, void, { state: RootState }>(
  'quiz/getInitialRating',
  async (params, { getState }) => {
    const { limit } = leaderboardSelectors.getStatus(getState());

    const { data } = await Rating.getQuizRating({ limit, offset: 0 });

    return {
      ...data,
      offset: limit,
      hasNext: limit <= (data.all || []).length,
    };
  },
);

export default getInitialRating;
