import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { getInitialLineUpDetails, getMoreLineUpDetails } from '../../thunks';
import { LineUpTypes } from '../../types';
import { commonActions, lineUpActions } from '../../actions';

const defaultState: LineUpTypes.Status = {
  isInitialLoading: false,
  isMoreLoading: false,
  offset: 0,
  limit: 30,
  hasNext: true,
};

const reducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(getInitialLineUpDetails.pending, (state) => {
      state.isInitialLoading = true;
    })
    .addCase(getInitialLineUpDetails.fulfilled, (state, { payload }) => {
      const { offset, hasNext } = payload;

      return { ...state, isInitialLoading: false, offset, hasNext };
    })
    .addCase(getInitialLineUpDetails.rejected, (state) => {
      state.isInitialLoading = false;
    })
    .addCase(getMoreLineUpDetails.pending, (state) => {
      state.isMoreLoading = true;
    })
    .addCase(getMoreLineUpDetails.fulfilled, (state, { payload }) => {
      const { offset, hasNext } = payload;

      return { ...state, isMoreLoading: false, offset, hasNext };
    })
    .addCase(getMoreLineUpDetails.rejected, (state) => {
      state.isMoreLoading = false;
    })
    .addMatcher(isAnyOf(lineUpActions.cleanupState, commonActions.cleanupState), () => {
      return defaultState;
    });
});

export default reducer;
