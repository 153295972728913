import React, { FC, memo } from 'react';
import classNames from 'classnames';

import { Figure } from 'packages/shared/components';

import styles from './styles.module.scss';

type Props = {
  name: string;
  logo?: string;
  className?: string;
  reversed?: boolean;
  centerAlignment?: boolean;
};

const TeamLabel: FC<Props> = ({
  name,
  logo,
  className,
  reversed = false,
  centerAlignment = false,
}) => {
  return (
    <div
      className={classNames(
        styles.wrapper,
        { [styles.reversed]: reversed, [styles.centerAlignment]: centerAlignment },
        className,
      )}
    >
      <Figure src={logo} className={styles.logo} />
      <span className={styles.name}>{name}</span>
    </div>
  );
};

export default memo(TeamLabel);
