import React, { cloneElement, FC, memo, ReactElement, RefObject } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface Props {
  children: ReactElement;
  isOpen?: boolean;
  forwardedRef?: RefObject<HTMLDivElement>;
  toggleIsOpen?: (isOpen: boolean) => void;
}

const Content: FC<Props> = ({ children, isOpen, forwardedRef, toggleIsOpen }) => {
  return (
    <div ref={forwardedRef} className={classNames(styles.content, { [styles.visible]: isOpen })}>
      {cloneElement(children, { closeAccordionGroup: toggleIsOpen })}
    </div>
  );
};

export default memo(Content);
