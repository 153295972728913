import classNames from 'classnames';

import commonStyles from '../styles.module.scss';
import slideInRightStyles from '../slideInRight.module.scss';
import slideInBottomStyles from '../slideInBottom.module.scss';
import { AnimationType } from '../../../types';
import fadeInStyles from '../fadeIn.module.scss';

export const MODAL_ANIMATION_CONFIG = {
  [AnimationType.slideInRight]: {
    className: classNames(commonStyles.content, slideInRightStyles.content),
    overlayClassName: commonStyles.overlay,
    closeTimeoutMS: 300,
  },
  [AnimationType.slideInBottom]: {
    className: classNames(commonStyles.content, slideInBottomStyles.content),
    overlayClassName: commonStyles.overlay,
    closeTimeoutMS: 300,
  },
  [AnimationType.fadeIn]: {
    className: classNames(commonStyles.content, fadeInStyles.content),
    overlayClassName: commonStyles.overlay,
    closeTimeoutMS: 300,
  },
};
