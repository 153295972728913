import React, { FC, memo, SVGProps } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Spinner: FC<SVGProps<SVGSVGElement>> = ({ className, ...props }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={classNames(styles.loaderIcon, className)}
      {...props}
    >
      <g clipPath="url(#clip0_1_4847)">
        <rect width="32" height="32" rx="9" fill="url(#pattern0)" />
        <g opacity="0.16">
          <rect
            width="6"
            height="2"
            transform="matrix(0.130526 0.991445 0.991445 -0.130526 16.3191 26.0438)"
            fill="white"
          />
        </g>
        <g opacity="0.04">
          <rect
            width="6"
            height="2"
            transform="matrix(-0.866025 0.5 0.5 0.866025 6.84009 20.1346)"
            fill="white"
          />
        </g>
        <g opacity="0.08">
          <rect
            width="6"
            height="2"
            transform="matrix(-0.608761 0.793353 0.793353 0.608761 9.12329 23.3221)"
            fill="white"
          />
        </g>
        <g opacity="0.12">
          <rect
            width="6"
            height="2"
            transform="matrix(-0.258819 0.965926 0.965926 0.258819 12.4526 25.3973)"
            fill="white"
          />
        </g>
        <g opacity="0.24">
          <rect
            width="6"
            height="2"
            transform="matrix(0.5 0.866025 0.866025 -0.5 20.1345 25.1609)"
            fill="white"
          />
        </g>
        <g opacity="0.96">
          <rect
            width="6"
            height="2"
            transform="matrix(0.793353 -0.608761 -0.608761 -0.793353 24.5388 10.7032)"
            fill="white"
          />
        </g>
        <g opacity="0.4">
          <rect
            width="6"
            height="2"
            transform="matrix(0.866025 0.5 0.5 -0.866025 24.1606 21.8667)"
            fill="white"
          />
        </g>
        <g opacity="0.64">
          <rect
            width="6"
            height="2"
            transform="matrix(0.991445 0.130526 0.130526 -0.991445 25.7797 18.2957)"
            fill="white"
          />
        </g>
        <g opacity="0.8">
          <rect
            width="6"
            height="2"
            transform="matrix(0.965926 -0.258819 -0.258819 -0.965926 25.9122 14.3748)"
            fill="white"
          />
        </g>
        <rect
          width="6"
          height="2"
          transform="matrix(0.5 -0.866025 -0.866025 -0.5 21.8665 7.84052)"
          fill="white"
        />
      </g>
      <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_1_4847" />
        </pattern>
        <clipPath id="clip0_1_4847">
          <rect width="32" height="32" rx="9" fill="white" />
        </clipPath>
        <image
          id="image0_1_4847"
          width="1"
          height="1"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAQSURBVHgBAQUA+v8AAAAAAAAFAAFkeJU4AAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  );
};

export default memo(Spinner);
