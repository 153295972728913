export enum IconId {
  giftbox = 'giftbox',
  quiz = 'quiz',
  winnerJackpot = 'winner-jackpot',
  score = 'score',
  medal1 = 'medal-1',
  medal2 = 'medal-2',
  medal3 = 'medal-3',
  medalStar = 'medal-star',
  chat = 'chat',
  rules = 'rules',
  leaderboard = 'leaderboard',
  calendar = 'calendar',
  players = 'players',
  topup = 'topup',
  tournamentPreview = 'tournament-preview',
  logout = 'logout',
  auction = 'auction',
  ticket = 'ticket',
  important = 'important',
  series = 'series',
  notificationSettings = 'notification-settings',
  share = 'share',
  reward = 'reward',
  matches = 'matches',
  seriesScheduled = 'series-scheduled',
  delete = 'delete',
  supportChat = 'support-chat',
  documentation = 'documentation',
  profit = 'profit',
  faq = 'faq',
  table = 'table',
  userSettings = 'user-settings',
  user = 'user',
  settings = 'settings',
  message = 'message',
  warningDuration = 'warning-duration',
  duration = 'duration',
  calculation = 'calculation',
  rate = 'rate',
  stadium = 'stadium',
  fanclubTransfer = 'fanclub-transfer',
  stocksGrow = 'stocks-grow',
  notPlaying = 'not-playing',
  pushNotifications = 'push-notifications',
  stocks = 'stocks',
  roulette = 'roulette',
  fire = 'fire',
  history = 'history',
  flag = 'flag',
  timeEdit = 'time-edit',
  badgeCaptain = 'badge-captain',
  badgeVicecaptain = 'badge-vicecaptain',
  help = 'help',
  privacyPolicy = 'privacy-policy',
  team = 'team',
  teamPlayers = 'team-players',
  career = 'career',
  topPlayers = 'top-players',
  userAdd = 'user-add',
  userRemove = 'user-remove',
  blockUser = 'block-user',
  goalkeeper = 'goalkeeper',
  kicker = 'kicker',
  userCaptain = 'user-captain',
  userViceCaptain = 'user-vice-captain',
  settingsUser = 'settings-user',
  standings = 'standings',
  portfolioChart = 'portfolio-chart',
  filter = 'filter',
  stocksTips = 'stocks-tips',
  liveTranslation = 'live-translation',
  calendarPostponed = 'calendar-postponed',
  calendarCancelled = 'calendar-cancelled',
  roundMarker = 'round-marker',
  wildcardRandom = 'wildcard-random',
  terms = 'terms',
  highlightAdd = 'highlight-add',
  wildcardPaid = 'wildcard-paid',
  stocksGrowActive = 'stocks-grow-active',
  stocksFallActive = 'stocks-fall-active',
  copy = 'copy',
  archive = 'archive',
  alert = 'alert',
  camera = 'camera',
  photoLibrary = 'photo-library',
  highlightRemove = 'highlight-remove',
  refresh = 'refresh',
  reorder = 'reorder',
  coins = 'coins',

  arrowUp = 'arrow-up',
  favouriteActive = 'favourite-active',
  favourite = 'favourite',
  close = 'close',
  clearCircle = 'clear-circle',
  remove = 'remove',
  add = 'add',
  check = 'check',
  info = 'info',
  undo = 'undo',
  edit = 'edit',
  chevronRight = 'chevron-right',
  chevronLeft = 'chevron-left',
  removeCircle = 'remove-circle',
  addCircle = 'add-circle',
  expandMore = 'expand-more',
  expandLess = 'expand-less',
  arrowLeft = 'arrow-left',
  more = 'more',
  swipeLeft = 'swipe-left',
  swipeRight = 'swipe-right',

  searchActive = 'search-active',
  search = 'search',
  menu = 'menu',
  homeActive = 'home-active',
  home = 'home',
  tournamentActive = 'tournament-active',
  tournament = 'tournament',
  notificationsActive = 'notifications-active',
  notifications = 'notifications',

  hockeyPuck = 'hockey-puck',
  tennisServe = 'tennis-serve',
  whistle = 'whistle',
  basketballBall = 'basketball-ball',
  tennisBall = 'tennis-ball',
  hockeyAssist = 'hockey-assist',
  soccerBall = 'soccer-ball',

  telegram = 'telegram',
  instagram = 'instagram',
  twitter = 'twitter',
  vk = 'vk',
  facebook = 'facebook',
  apple = 'apple',
  google = 'google',
  bofp = 'bofp',

  checkedInCircle = 'checked-in-circle',
}

export enum Module {
  base = 'base',
  system = 'system',
  tabbar = 'tabbar',
  sports = 'sports',
  social = 'social',
  other = 'other',
}
