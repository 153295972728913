import { AvailablePredictionsTypes } from '../../types';

interface Params {
  marketCodesWithConflict: AvailablePredictionsTypes.MarketCodeWithConflicts[];
  eventIds: number[];
}

const flushMarketCodes = ({ marketCodesWithConflict, eventIds }: Params) => {
  const flushedMarketCodesWithConflict: AvailablePredictionsTypes.MarketCodeWithConflicts[] = [];
  const flushedCleanMarketCodes: AvailablePredictionsTypes.CleanMarketCode[] = [];

  for (const marketCodeWithConflict of marketCodesWithConflict) {
    const { conflictedEventIds, ...cleanMarketCodeData } = marketCodeWithConflict;

    const filtratedConflictedEvents = filterConflictedEvents(conflictedEventIds, eventIds);

    if (filtratedConflictedEvents.length) {
      flushedMarketCodesWithConflict.push({
        conflictedEventIds: filtratedConflictedEvents,
        ...cleanMarketCodeData,
      });
    } else {
      flushedCleanMarketCodes.push(cleanMarketCodeData);
    }
  }

  return {
    flushedMarketCodesWithConflict,
    flushedCleanMarketCodes,
  };
};

const filterConflictedEvents = (conflictedEventIds: number[], eventIds: number[]) => {
  return conflictedEventIds.filter((conflictedEventId) => !eventIds.includes(conflictedEventId));
};

export default flushMarketCodes;
