import React, { cloneElement, FC, memo, ReactElement, useMemo } from 'react';
import dayjs from 'dayjs';

import styles from './historyItemWrapper.module.scss';

type Props = {
  date: string;
  points: number;
  icon: ReactElement;
  header: string;
  children: ReactElement | ReactElement[];
  onClick?: () => void;
};

const HistoryItemWrapper: FC<Props> = ({ children, icon, header, onClick, date, points }) => {
  const iconElement = useMemo(
    () => (icon ? cloneElement(icon, { className: styles.icon }) : <></>),
    [icon],
  );

  return (
    <div onClick={onClick} className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.title}>
          {iconElement}
          {header}
        </div>
        <div className={styles.date}>{dayjs(date).format('MMM D, h:mm')}</div>
      </div>

      <div className={styles.content}>
        <div className={styles.left}>{children}</div>
        <div className={styles.right}>
          <div className={styles.value}>{points}</div>
          <div className={styles.label}>points</div>
        </div>
      </div>
    </div>
  );
};

export default memo(HistoryItemWrapper);
