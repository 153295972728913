import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Note } from 'packages/shared/components';
import { TeamInfoSchema } from 'packages/rest/types';

import { Nullable } from 'packages/types';

import Card from '../Card';
import CardBottomContent from './CardBottomContent';
import styles from './styles.module.scss';
import CardTopContent from './CardTopContent';

type Props = {
  team: Nullable<TeamInfoSchema>;
};

const TeamCard: FC<Props> = ({ team }) => {
  const { t } = useTranslation();

  return (
    <>
      {team ? (
        <Card
          className={styles.offset}
          topContent={<CardTopContent team={team} />}
          bottomContent={<CardBottomContent balance={team.balance} />}
        />
      ) : (
        <Note
          iconElement={<Icon id="team" module="base" />}
          header={t('team_title_join_or_create')}
          theme="green"
          className={styles.offset}
        >
          {t('team_info_join_or_create')}
        </Note>
      )}
    </>
  );
};

export default memo(TeamCard);
