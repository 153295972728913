import React, { FC, memo, useState } from 'react';
import classNames from 'classnames';

import { Icon } from 'packages/shared/components';

import styles from './styles.module.scss';

interface Props {
  checked?: boolean;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
  className?: string;
}

const Checkbox: FC<Props> = ({ checked, disabled, onChange, className }) => {
  const [checkedLocally, setCheckedLocally] = useState(false);
  const isControlled = checked !== undefined;
  const displayingChecked = isControlled ? checked : checkedLocally;

  const handleChange = () => {
    if (isControlled) {
      onChange?.(!displayingChecked);
    } else {
      setCheckedLocally((checkedLocally) => !checkedLocally);
    }
  };

  return (
    <div className={classNames(styles.checkbox, { [styles.disabled]: disabled }, className)}>
      <input type="checkbox" onChange={handleChange} disabled={disabled} />
      {displayingChecked && <Icon module="system" id="check" className={styles.check} />}
    </div>
  );
};

export default memo(Checkbox);
