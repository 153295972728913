import { createAsyncThunk } from '@reduxjs/toolkit';

import { Friends } from 'packages/rest';
import { RootState } from 'app/store';

import { getData } from '../selectors';
import { changeFollowersCount } from '../services';

interface ReturnType {
  isFriend: boolean;
  followersCount: number;
}

interface Params {
  userId: string | number;
}

const follow = createAsyncThunk<ReturnType, Params, { state: RootState }>(
  'user-profile/follow',
  async ({ userId }, { getState }) => {
    const { data } = await Friends.follow({ userId });

    const profile = getData(getState());
    const followersCount = changeFollowersCount({
      initialCount: profile?.followersCount,
      action: 'add',
    });

    return { isFriend: data.success, followersCount };
  },
);

export default follow;
