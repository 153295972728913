import React, { memo, FC, ReactElement } from 'react';

import { Icon, TeamLabel } from 'packages/shared/components';

import styles from './styles.module.scss';

type Props = {
  name: string;
  children?: ReactElement | string;
  logo?: string;
};

const Team: FC<Props> = ({ children, name, logo }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <TeamLabel logo={logo} name={name} />
        <Icon id="chevronRight" module="system" />
      </div>

      {!!children && <div className={styles.bottom}>{children}</div>}
    </div>
  );
};

export default memo(Team);
