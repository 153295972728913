export enum AnimationType {
  slideInRight = 'slideInRight',
  slideInBottom = 'slideInBottom',
  fadeIn = 'fadeIn',
}

export enum ContentYAlignment {
  top = 'top',
  bottom = 'bottom',
  center = 'center',
}

export enum ContentXAlignment {
  left = 'left',
  right = 'right',
  center = 'center',
}
