import { isObject } from 'app/utils/Object.utils';

import { KeyValueStorage, StorageKey } from './types';

class LocalStorage implements KeyValueStorage {
  storage: Storage;

  constructor() {
    this.storage = window.localStorage;
  }

  get<DataType>(key: string | StorageKey): DataType {
    const item = this.storage.getItem(key);

    return isObject(item) ? JSON.parse(item) : item;
  }

  remove(key: string | StorageKey): void {
    return this.storage.removeItem(key);
  }

  set<DataType>(key: string | StorageKey, value: DataType): void {
    if (isObject(value)) {
      return this.storage.setItem(key, JSON.stringify(value));
    }

    return this.storage.setItem(key, `${value}`);
  }
}

const sessionStorage = new LocalStorage();

export default sessionStorage;
