import { UserGroup } from 'packages/rest/types';
import { Nullable, Sport } from 'packages/types';
import exp from 'constants';

export enum UnitType {
  Players = 'players',
  Friends = 'friends',
  Teams = 'teams',
}

export enum DateType {
  Year = 'year',
  Week = 'week',
  Month = 'month',
}

export enum Order {
  Coins = 'coins',
  Points = 'points',
}

export interface PlayerRating {
  id: number;
  firstName: string;
  lastName: string;
  photo: string;
  teamId: number;
  teamName?: Nullable<string>;
  position: number;
  points: number;
  groups: UserGroup[];
  coins: number;
}

export interface TeamRating {
  id: number;
  name: string;
  photo: string;
  position: number;
  points: number;
  coins: number;
}

export interface CurrentUser {
  id: number;
  firstName: string;
  lastName: string;
  photo: string;
  teamId: Nullable<number>;
  teamName: Nullable<string>;
  points: number;
  position: number;
  groups: UserGroup;
}

export interface CurrentUserTeam {
  id: number;
  name: string;
  photo: string;
  position: number;
  points: number;
}

export interface PlayerRatingResponse {
  all: PlayerRating[];
  me: CurrentUser;
}

export interface TeamRatingResponse {
  teams: TeamRating[];
  my: CurrentUserTeam;
}

export type GetRatingResponse = Partial<PlayerRatingResponse & TeamRatingResponse>;

export interface RatingDetails {
  createdAt: string;
  points: number;
}

export interface Tournament {
  id: number;
  name: string;
}

export interface Event {
  id: number;
  date: string;
  club1Name: string;
  club2Name: string;
  img1: string;
  img2: string;
  sportId: Sport;
}

export interface EventRatingDetails extends RatingDetails {
  tournament: Tournament;
  event: Event;
}

export interface Quiz {
  id: string;
  img: string;
  name: string;
  rightQuestions: number;
  questionCount: number;
}

export interface QuizRatingDetails extends RatingDetails {
  quiz: Quiz;
}

export type GetRatingDetailsResponse = Array<QuizRatingDetails | EventRatingDetails>;

export interface GetRatingRequest {
  type: UnitType;
  sportId?: Sport;
  dateType?: DateType;
  dateOffset?: number;
  teamId?: number;
  order?: Order;
  limit?: number;
  offset?: number;
  search?: string;
}

export interface GetRatingDetailsRequest {
  type: UnitType;
  id?: string | number;
  sportId?: Sport;
  dateType?: DateType;
  dateOffset?: number;
  teamId?: number;
  order?: Order;
  limit?: number;
  offset?: number;
}

export interface GetQuizRatingRequest {
  search?: string;
  limit?: number;
  offset?: number;
}

export interface QuizRatingUser {
  id: number;
  firstName: string;
  lastName: string;
  photo: string;
}

export interface QuizRatingTeam {
  id: number;
  name: string;
  photo: string;
}

export interface QuizRating {
  points: number;
  position: number;
  answeredQuestionsCount: number;
  rightAnswersPercentage: number;
  user: QuizRatingUser;
  team: Nullable<QuizRatingTeam>;
}

export interface GetQuizRatingResponse {
  all: QuizRating[];
  me: QuizRating;
}
