import { Tournaments, TournamentsV2 } from 'packages/rest';
import { PredictionChoice } from 'packages/rest/types';

type Params = {
  predictions: Tournaments.ExpressPrediction[];
  set: number;
  variants: number;
};

const mapPredictionToTicket = ({
  predictions,
  set,
  variants,
}: Params): TournamentsV2.ExpressTicket => ({
  events: mapPredictionsToEvents(predictions),
  reward: null,
  set,
  ticketStatus: TournamentsV2.TicketStatus.Play,
  variants,
  winCount: 0,
});

const mapPredictionsToEvents = (
  predictions: Tournaments.ExpressPrediction[],
): TournamentsV2.ExpressTicketEvent[] =>
  predictions.map(({ eventId, choice }) => ({
    id: eventId,
    choices: mapChoices(choice),
  }));

const mapChoices = (choices: PredictionChoice[]): TournamentsV2.TicketEventChoice[] =>
  choices.map((choice) => ({ choice, status: 0 }));

export default mapPredictionToTicket;
