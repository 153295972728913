import React, { FC, memo, useCallback, useContext } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import { DefaultNavBar, InfiniteScrollLoader, LoaderContainer } from 'packages/shared/components';
import { useAppDispatch, useAppSelector } from 'app/store';
import { detailsActions, detailsSelectors, getMoreRatingItemDetails } from 'packages/leaderboard';

import DetailsHeader from './DetailsHeader';
import Overall from './Overall';
import History from './History';
import { ContentType } from '../../types';
import { Context } from '../../context';
import styles from './styles.module.scss';

const Details: FC = () => {
  const details = useAppSelector(detailsSelectors.getDetails) || [];
  const { isInitialLoading, isMoreLoading, hasNext } = useAppSelector(detailsSelectors.getStatus);
  const dispatch = useAppDispatch();
  const [, setContentElement] = useContext(Context);

  const handleBackClick = useCallback(() => {
    setContentElement(ContentType.LeaderboardTable);
    dispatch(detailsActions.cleanupState());
  }, [setContentElement, dispatch]);

  const handleLoadMore = useCallback(() => {
    if (!isMoreLoading) {
      dispatch(getMoreRatingItemDetails());
    }
  }, [dispatch, isMoreLoading]);

  return (
    <>
      <DefaultNavBar
        className={styles.header}
        title={<DetailsHeader />}
        onLeftClick={handleBackClick}
      />
      <Overall className={styles.overall} />

      <LoaderContainer fullSize isLoading={isInitialLoading}>
        <InfiniteScroll
          hasMore={hasNext}
          loadMore={handleLoadMore}
          useWindow={false}
          initialLoad={false}
          loader={<InfiniteScrollLoader key="loader" />}
          threshold={10}
        >
          {details.map(({ date, dailyPoints, events }) => (
            <History dailyPoints={dailyPoints} date={date} events={events} key={date} />
          ))}
        </InfiniteScroll>
      </LoaderContainer>
    </>
  );
};

export default memo(Details);
