import { useMemo } from 'react';

import { ActiveModal } from '../../../context/modal/types';

const useModalListener = (updateActiveModals: (modals: Partial<ActiveModal>) => void) => {
  return useMemo(
    () => ({
      handleMainMenuClose: () => updateActiveModals({ mainMenu: false }),
      handleLeaderboardClose: () => updateActiveModals({ leaderboard: false }),
      handleLeaderboardFilterClose: () => updateActiveModals({ leaderboardFilter: false }),
    }),
    [updateActiveModals],
  );
};

export default useModalListener;
