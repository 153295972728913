import React, { memo, ReactElement, useContext, useMemo } from 'react';
import classNames from 'classnames';

import { Icon } from 'packages/shared/components';

import Context from '../../context';
import { Option as OptionType } from '../../types';
import styles from './styles.module.scss';

type Props<T> = {
  id: number | string;
  label: string;
  value: T;
  actions?: ReactElement | ReactElement[];
  onClick?: (option: OptionType<T>) => void;
  active?: boolean;
  checkAlwaysDisplayed?: boolean;
};

const Option = <T,>({
  id,
  label,
  value,
  actions,
  onClick,
  active,
  checkAlwaysDisplayed = false,
}: Props<T>) => {
  const [selectedOptions, handleOptionClick] = useContext(Context);

  const activeByContext = useMemo(
    () => !!selectedOptions.find((selectedOption) => selectedOption.id === id),
    [id, selectedOptions],
  );

  const handleClick = () => {
    handleOptionClick({ id, label, value });
    onClick?.({ id, label, value });
  };

  active = active || activeByContext;
  const iconDisplayed = active || checkAlwaysDisplayed;

  return (
    <li
      className={classNames(styles.item, {
        [styles.active]: active,
        [styles.withIcon]: iconDisplayed,
      })}
      onClick={handleClick}
    >
      <div className={styles.iconWrapper}>
        {iconDisplayed && <Icon className={styles.icon} module="system" id="check" />}
      </div>
      <span className={styles.label}>{label}</span>
      {actions}
    </li>
  );
};

export default memo(Option);
