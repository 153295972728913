import dayjs from 'dayjs';

const getCurrentMonthDays = (date: dayjs.Dayjs): dayjs.Dayjs[] => {
  const daysInMonth = date.daysInMonth();
  const days: dayjs.Dayjs[] = [];

  for (let dayNumber = 1; dayNumber <= daysInMonth; dayNumber++) {
    days.push(date.set('date', dayNumber).startOf('day'));
  }

  return days;
};

export default getCurrentMonthDays;
