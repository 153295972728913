import { createAction } from '@reduxjs/toolkit';

import { Sport } from 'packages/types';

import { MatchesFilterTypes } from '../types';

export const applyMatchFilter = createAction<{
  sport: Sport;
  filter: MatchesFilterTypes.PersistentFilter;
}>('tournamentManagement/applyMatchesFilter');

export const setSearchKey = createAction<{
  sport: Sport;
  searchKey: string;
}>('tournamentManagement/setSearchKey');
