import React, { memo, ReactElement, useEffect } from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { AnimationType, ContentYAlignment, ContentXAlignment } from '../../types';
import { MODAL_ANIMATION_CONFIG } from './constants';

import styles from './styles.module.scss';

type Props = {
  isOpen: boolean;
  onCloseModal: () => void;
  children: ReactElement | ReactElement[];
  contentClassName?: string;
  contentYPosition?: keyof typeof ContentYAlignment;
  contentXPosition?: keyof typeof ContentXAlignment;
  animation?: keyof typeof AnimationType;
  shouldCloseOnOverlayClick?: boolean;
  contentFullWidth?: boolean;
};

const BaseModal: React.FC<Props> = ({
  isOpen,
  onCloseModal,
  animation = 'fadeIn',
  shouldCloseOnOverlayClick = true,
  children,
  contentClassName,
  contentFullWidth = false,
  contentYPosition = ContentYAlignment.center,
  contentXPosition = ContentXAlignment.center,
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    return () => {
      document.body.style.overflow = '';
    };
  }, [pathname]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isOpen]);

  const { className, closeTimeoutMS, overlayClassName } =
    MODAL_ANIMATION_CONFIG[AnimationType[animation]];
  const verticalAlignmentClass = styles[`${ContentYAlignment[contentYPosition]}Vertical`];
  const horizontalAlignmentClass = styles[`${ContentXAlignment[contentXPosition]}Horizontal`];

  return (
    <Modal
      onRequestClose={onCloseModal}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      isOpen={isOpen}
      className={classNames(className, { [styles.fullWidth]: contentFullWidth }, contentClassName)}
      closeTimeoutMS={closeTimeoutMS}
      overlayClassName={classNames(
        overlayClassName,
        verticalAlignmentClass,
        horizontalAlignmentClass,
      )}
    >
      {children}
    </Modal>
  );
};

export default memo(BaseModal);
