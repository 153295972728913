import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import { reducer as configReducer } from 'packages/config';
import { reducer as authReducer } from 'packages/auth';
import { reducer as mainMenuReducer } from 'packages/main-menu';
import { reducer as profileReducer } from 'packages/profile';
import { reducer as userProfileReducer } from 'packages/user-profile';
import { reducer as userSearchReducer } from 'packages/user-search';
import { reducer as tournamentManagementReducer } from 'packages/tournament-management';
import { reducer as leaderboardReducer } from 'packages/leaderboard';
import { reducer as superExpressReducer } from 'packages/super-express';
import { reducer as quizReducer } from 'packages/quiz';
import { reducer as tournamentsReducer } from 'packages/tournaments';

export const store = configureStore({
  reducer: {
    config: configReducer,
    auth: authReducer,
    mainMenu: mainMenuReducer,
    profile: profileReducer,
    userProfile: userProfileReducer,
    userSearch: userSearchReducer,
    tournamentManagement: tournamentManagementReducer,
    leaderboard: leaderboardReducer,
    superExpress: superExpressReducer,
    quiz: quizReducer,
    tournaments: tournamentsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
