const getTennisClubName = (gender: number) => {
  switch (gender) {
    case 1:
      return 'profile_settings_user_tennis_1';
    case 2:
      return 'profile_settings_user_tennis_2';
    default:
      return '';
  }
};

export default getTennisClubName;
