import React, { memo } from 'react';
import { SingleValueProps, components } from 'react-select';
import { useTranslation } from 'react-i18next';

import { Option as OptionType } from '../types/interfaces';

const SingleValue = <V extends string | number, T extends OptionType<V>>({
  children,
  ...rest
}: SingleValueProps<T>) => {
  const { t } = useTranslation();

  return (
    <components.SingleValue {...rest}>
      {typeof children === 'string' ? t(children) : children}
    </components.SingleValue>
  );
};

export default memo(SingleValue);
