import { QuestionsTypes } from '../types';

type Params = {
  quiz: QuestionsTypes.Question[];
  activeQuestion?: QuestionsTypes.Question;
};

const updateActiveQuestionInQuiz = ({
  quiz,
  activeQuestion,
}: Params): QuestionsTypes.Question[] => {
  // todo use current() method from rtk
  const quizCopy = [...quiz];
  const activeQuestionIndex = quizCopy.findIndex(({ id }) => id === activeQuestion?.id);

  if (activeQuestionIndex >= 0 && activeQuestion) {
    const activeQuestionCopy = { ...activeQuestion };

    quizCopy.splice(activeQuestionIndex, 1, activeQuestionCopy);
  }

  return quizCopy;
};

export default updateActiveQuestionInQuiz;
