import { createAsyncThunk } from '@reduxjs/toolkit';

import { Quiz } from 'packages/rest';

import { QuestionsTypes } from '../../types';
import { mapQuestions } from '../../services';

interface Params {
  quizId: string | number;
}

const getQuestions = createAsyncThunk<QuestionsTypes.Data, Params>(
  'quiz/getQuestions',
  async ({ quizId }, { rejectWithValue }) => {
    try {
      const { data } = await Quiz.getQuestions({ quizId });
      const { startDate, endDate, questions } = data;
      const mappedQuestions = mapQuestions(questions);

      return {
        endDate,
        startDate,
        questions: mappedQuestions,
        activeQuestion: mappedQuestions[0],
        lastQuestionId: mappedQuestions.at(-1)?.id,
      };
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  },
);

export default getQuestions;
