import jwtDecode from 'jwt-decode';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { toCamelCase } from 'app/utils/Object.utils';
import { configureAppIdentifier } from 'packages/config/thunks';
import { Auth } from 'packages/rest';
import { localStorage, StorageKey } from 'app/storage';

interface ReturnType {
  userId: number;
  role: number;
}

const refresh = createAsyncThunk<ReturnType>('auth/refresh', async () => {
  const refreshToken = localStorage.get<string>(StorageKey.RefreshToken);

  if (refreshToken) {
    const response = await Auth.refresh({ refreshToken });
    const { refreshToken: responseRefreshToken, accessToken } = response.data;
    const { userId, role, identifier } = toCamelCase(jwtDecode(accessToken));

    localStorage.set(StorageKey.AccessToken, accessToken);
    localStorage.set(StorageKey.RefreshToken, responseRefreshToken);

    configureAppIdentifier(identifier);

    return { userId, role };
  }

  throw new Error('User unauthorized');
});

export default refresh;
