export interface TabBarItem {
  id: string;
  label: string;
}

export enum ScrollPointerVerticalAlignment {
  top = 'top',
  bottom = 'bottom',
}

export interface NavigationState {
  tabs: TabBarItem[];
  tabBarHeight: number;
  tabBarTopOffset: number;
  animationContainerTopOffset: number;
  animationContainerActive: boolean;
  activeTabId: string;
}

export enum NavigationActionType {
  SET_TAB_NAVIGATION_CONFIG = 'SET_TAB_NAVIGATION_CONFIG',
  SET_ANIMATION_CONTAINER_TOP_OFFSET = 'SET_ANIMATION_CONTAINER_TOP_OFFSET',
  SET_ANIMATION_CONTAINER_STATE = 'SET_ANIMATION_CONTAINER_STATE',
  CHANGE_ACTIVE_TAB = 'CHANGE_ACTIVE_TAB ',
}

export type NavigationAction =
  | {
      type: NavigationActionType.SET_TAB_NAVIGATION_CONFIG;
      height: number;
      topOffset: number;
    }
  | { type: NavigationActionType.SET_ANIMATION_CONTAINER_STATE; active: boolean }
  | { type: NavigationActionType.CHANGE_ACTIVE_TAB; tabId: string; active: boolean }
  | { type: NavigationActionType.SET_ANIMATION_CONTAINER_TOP_OFFSET; topOffset: number };
