import { createSelector } from '@reduxjs/toolkit';

import { Sport } from 'packages/types';

import { AvailablePredictionsTypes } from '../../types';
import getState from './getState';

export const getData = createSelector(
  getState,
  (state: AvailablePredictionsTypes.State): AvailablePredictionsTypes.Data => state.data,
);

export const getSports = createSelector(
  getData,
  (data: AvailablePredictionsTypes.Data): Sport[] => data.sports,
);

export const getSelectedMarketCodesMap = createSelector(
  getData,
  (data: AvailablePredictionsTypes.Data): Record<string, number[]> => {
    return Object.entries(data.selectedMarketCodes).reduce((countBySport, [sport, marketCodes]) => {
      if (marketCodes.length) {
        countBySport[sport] = marketCodes.map(({ code }) => code);
      }

      return countBySport;
    }, <Record<string, number[]>>{});
  },
);

export const getSelectedMarketCodesCountsMap = createSelector(
  getData,
  (data: AvailablePredictionsTypes.Data): Record<string, number> => {
    return Object.entries(data.selectedMarketCodes).reduce((countBySport, [sport, marketCodes]) => {
      countBySport[sport] = marketCodes.length;

      return countBySport;
    }, <Record<string, number>>{});
  },
);

export const getSelectedMarketCodesCount = createSelector(
  getSelectedMarketCodesCountsMap,
  (selectedPredictionsCountBySport: Record<string, number>): number => {
    return Object.values(selectedPredictionsCountBySport).reduce((count, marketCodesCount) => {
      count += marketCodesCount;

      return count;
    }, 0);
  },
);
