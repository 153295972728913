import React, { cloneElement, FC, memo, ReactElement, useMemo } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

type Props = {
  children: ReactElement | string;
  isLoading?: boolean;
  iconElement?: ReactElement;
  onClick?: () => void;
  className?: string;
};

const Item: FC<Props> = ({ onClick, iconElement, children, className, isLoading = false }) => {
  const icon = useMemo(
    () => (iconElement ? cloneElement(iconElement, { className: styles.icon }) : undefined),
    [iconElement],
  );

  return (
    <li
      className={classNames(styles.item, { [styles.loading]: isLoading }, className)}
      onClick={isLoading ? undefined : onClick}
    >
      {icon} <div className={styles.content}>{children}</div>
    </li>
  );
};

export default memo(Item);
