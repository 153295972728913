import React, { FC, memo } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';
import { Counter, Icon } from '../index';

type Props = {
  activeItems?: number;
  onClick?: () => void;
  className?: string;
};

const FilterCounter: FC<Props> = ({ activeItems, onClick, className }) => {
  return (
    <div
      className={classNames(styles.wrapper, { [styles.active]: !!activeItems }, className)}
      onClick={onClick}
    >
      <Icon className={styles.icon} id="filter" module="base" />
      {!!activeItems && <Counter className={styles.counter} count={activeItems} />}
    </div>
  );
};

export default memo(FilterCounter);
