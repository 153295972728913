import React, { FC, memo, useMemo } from 'react';

import { Table } from 'packages/shared/components';
import { Rating } from 'packages/rest';
import { DetailsTypes, filterSelectors, ratingSelectors } from 'packages/leaderboard';
import { useAppSelector } from 'app/store';

import styles from './table.module.scss';
import { getTeamRatingSchema, getTeamRowProps } from './services';

type Props = {
  data?: Rating.TeamRating[];
  onRowClick: (params: Omit<DetailsTypes.Data, 'details'>) => void;
};

const TeamRatingTable: FC<Props> = ({ data, onRowClick }) => {
  const activeFilter = useAppSelector(filterSelectors.getActiveFilter);
  const { id: userTeamId } = useAppSelector(ratingSelectors.getUserTeam) || {};
  const { measurement } = activeFilter;
  const tableSchema = useMemo(() => getTeamRatingSchema({ measurement }), [measurement]);
  const rowProps = useMemo(
    () =>
      getTeamRowProps({
        onClick: onRowClick,
        userTeamId,
      }),
    [onRowClick, userTeamId],
  );

  return (
    <Table<Rating.TeamRating>
      className={styles.table}
      headerClassName={styles.tableHeader}
      setRowProps={rowProps}
      isHeaderSticky
      columns={tableSchema}
      data={data || []}
    />
  );
};

export default memo(TeamRatingTable);
