import dayjs from 'dayjs';

import { Rating } from 'packages/rest';

import { DetailsTypes } from '../types';

const mapEventsDetails = (details: Rating.GetRatingDetailsResponse): DetailsTypes.Details[] => {
  return mapDetails(groupEvents(details));
};

const groupEvents = (details: Rating.GetRatingDetailsResponse) => {
  return details.reduce((accumulator, currentValue) => {
    const key = dayjs(currentValue.createdAt).format('MM/DD/YYYY');
    const events = accumulator.get(key);

    if (events) {
      events.push(currentValue);
    } else {
      accumulator.set(key, [currentValue]);
    }

    return accumulator;
  }, new Map<string, Array<Rating.QuizRatingDetails | Rating.EventRatingDetails>>());
};

const mapDetails = (
  eventsMap: Map<string, Array<Rating.QuizRatingDetails | Rating.EventRatingDetails>>,
) => {
  return Array.from(eventsMap.keys()).map((dateKey) => {
    const events = eventsMap.get(dateKey) || [];

    return {
      date: dateKey,
      dailyPoints: events.reduce((accumulator, { points }) => accumulator + points, 0) || 0,
      events,
    };
  });
};

export default mapEventsDetails;
