import React, { FC, memo, ReactElement } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

type Props = {
  children: ReactElement | ReactElement[];
  containerClassName?: string;
  contentClassName?: string;
  roundedCorners?: boolean;
  quiffDisplayed?: boolean;
};

const ModalContent: FC<Props> = ({
  children,
  containerClassName,
  contentClassName,
  roundedCorners = true,
  quiffDisplayed = true,
}) => {
  return (
    <div
      className={classNames(
        styles.wrapper,
        { [styles.roundedCorners]: roundedCorners },
        containerClassName,
      )}
    >
      {quiffDisplayed && (
        <div className={styles.quiffContainer}>
          <div className={styles.quiff} />
        </div>
      )}

      <div className={classNames(styles.content, contentClassName)}>{children}</div>
    </div>
  );
};

export default memo(ModalContent);
