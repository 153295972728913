import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { TeamInfoSchema } from 'packages/rest/types';

import Team from '../Team';
import UserRole from './UserRole';

type Props = {
  team: TeamInfoSchema;
};

const CardTopContent: FC<Props> = ({ team }) => {
  const { t } = useTranslation();

  return (
    <Team logo={team.photo} name={team.name}>
      <>
        <UserRole captainId={team.captainId} role={team.role} /> ・{' '}
        {t('common_count_players', { count: team.joined })}
      </>
    </Team>
  );
};

export default memo(CardTopContent);
