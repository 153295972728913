import { createSelector } from '@reduxjs/toolkit';
import { Rating } from 'packages/rest';

import { LeaderboardTypes } from '../../types';
import getState from './getState';

export const getData = createSelector(
  getState,
  (state: LeaderboardTypes.State): LeaderboardTypes.Data => state.data,
);

export const getAllPlayers = createSelector(
  getData,
  (state: LeaderboardTypes.Data): Rating.QuizRating[] => state.all,
);

export const getCurrentUser = createSelector(
  getData,
  (state: LeaderboardTypes.Data): Rating.QuizRating | undefined => state.me,
);
