import dayjs from 'dayjs';

interface Params {
  currentDate: dayjs.Dayjs;
  activeDate?: dayjs.Dayjs;
  disableBefore?: dayjs.Dayjs;
  disableAfter?: dayjs.Dayjs;
}

const todayDate = dayjs();

const useFlags = ({ currentDate, activeDate, disableBefore, disableAfter }: Params) => {
  const isCurrentDay = (day: dayjs.Dayjs) => {
    return day.isSame(todayDate, 'day');
  };

  const isActiveDay = (day: dayjs.Dayjs) => {
    return activeDate && day.isSame(activeDate, 'day');
  };

  const isDayDisabled = (day: dayjs.Dayjs) => {
    if (disableBefore && day.isBefore(disableBefore, 'day')) {
      return true;
    }

    if (disableAfter && day.isAfter(disableAfter, 'day')) {
      return true;
    }

    return false;
  };

  const isNextMonthDisabled =
    disableAfter && currentDate.add(1, 'month').isAfter(disableAfter, 'month');
  const isPreviousMonthDisabled =
    disableBefore && currentDate.subtract(1, 'month').isBefore(disableBefore, 'month');

  return {
    isActiveDay,
    isCurrentDay,
    isDayDisabled,
    isNextMonthDisabled,
    isPreviousMonthDisabled,
  };
};

export default useFlags;
