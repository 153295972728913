import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { translations } from '../dictionary';
import { LocaleCode } from '../types';

const configureDictionary = (): void => {
  const { en, ru } = translations;

  i18n.use(initReactI18next).init({
    fallbackLng: LocaleCode.En,
    lng: LocaleCode.En,
    resources: {
      [LocaleCode.Ru]: {
        translations: ru,
      },
      [LocaleCode.En]: {
        translations: en,
      },
    },
    ns: ['translations'],
    defaultNS: 'translations',
  });

  i18n.languages = [LocaleCode.Ru, LocaleCode.En];
};

export default configureDictionary;
