import { Quiz } from 'packages/rest';

import { QuestionsTypes } from '../types';

const mapQuestions = (questionList: Quiz.Question[]): QuestionsTypes.Question[] => {
  return questionList.map(({ questionGroup, text, answers, id, img }, index) => {
    return {
      stage: getQuestionStage(index),
      type: getQuestionType(questionGroup),
      description: text,
      points: 0,
      variants: mapAnswers(answers),
      selectedVariantId: undefined,
      rightVariantId: undefined,
      img: img || undefined,
      id,
    };
  });
};

const getQuestionStage = (index: number) =>
  index === 0 ? QuestionsTypes.QuestionStage.Active : QuestionsTypes.QuestionStage.Inactive;

const getQuestionType = (questionGroup: Quiz.QuestionGroup) =>
  questionGroup === Quiz.QuestionGroup.Sport
    ? QuestionsTypes.QuestionType.Default
    : QuestionsTypes.QuestionType.Bofp;

const mapAnswers = (answers: Quiz.QuestionAnswer[]): QuestionsTypes.AnswerVariant[] => {
  return answers.map(({ id, text }) => ({
    id,
    description: text,
  }));
};

export default mapQuestions;
