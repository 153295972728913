import { createAsyncThunk } from '@reduxjs/toolkit';

import { ProfileV2 } from 'packages/rest';

import { sortFanclubs } from '../services';

interface ReturnType {
  data: ProfileV2.GetProfileMenuResponse;
}

const getProfileMenu = createAsyncThunk<ReturnType>('main-menu/getProfileMenu', async () => {
  const { data } = await ProfileV2.getProfileMenu();

  return { data: { ...data, fanclubs: sortFanclubs(data.fanclubs) } };
});

export default getProfileMenu;
