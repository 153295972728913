import { createAsyncThunk } from '@reduxjs/toolkit';

import { TournamentsV2 } from 'packages/rest';

const getOthers = createAsyncThunk<TournamentsV2.Tournament[], void>(
  'tournaments/getOthers',
  async () => {
    const { data } = await TournamentsV2.getOtherTournaments();

    return data;
  },
);

export default getOthers;
