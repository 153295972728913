import React, { FC, memo } from 'react';

type Props = {
  value: {
    position: number;
    id: number;
    onClick: (id: number) => void;
  };
};

const PlayerPlaceCell: FC<Props> = ({ value }) => {
  const { position, id, onClick } = value;

  return <div onClick={() => onClick(id)}>{position}</div>;
};

export default memo(PlayerPlaceCell);
