import React, { Children, cloneElement, FC, memo, ReactElement, useState } from 'react';
import classNames from 'classnames';

import Group from '../Group';
import styles from './styles.module.scss';

interface Props {
  children: ReactElement<Parameters<typeof Group>[0]> | ReactElement<Parameters<typeof Group>[0]>[];
  className?: string;
}

const Container: FC<Props> = ({ children, className }) => {
  const [activeGroup, setActiveGroup] = useState<number>();

  const getActiveGroupChanger = (index: number) => (isOpen: boolean) => {
    setActiveGroup(isOpen ? index : undefined);
  };

  return (
    <div className={classNames(styles.container, className)}>
      {Children.map(children, (child, index) =>
        cloneElement(child, {
          isOpen: index === activeGroup,
          changeIsOpen: getActiveGroupChanger(index),
        }),
      )}
    </div>
  );
};

export default memo(Container);
