import { useContext, useEffect, useRef } from 'react';

import { NavigationActionType } from '../../../types';
import { Context } from '../../../context';

const usePointer = () => {
  const [state, dispatch] = useContext(Context);
  const elementRef = useRef<HTMLDivElement>(null);

  const { animationContainerTopOffset } = state;

  useEffect(() => {
    let isActive = false;

    const listener = () => {
      if (elementRef.current) {
        const { y } = elementRef.current.getBoundingClientRect();

        if (y >= animationContainerTopOffset && isActive) {
          isActive = false;

          dispatch({
            type: NavigationActionType.SET_ANIMATION_CONTAINER_STATE,
            active: isActive,
          });
        }

        if (y < animationContainerTopOffset && !isActive) {
          isActive = true;

          dispatch({
            type: NavigationActionType.SET_ANIMATION_CONTAINER_STATE,
            active: isActive,
          });
        }
      }
    };

    window.addEventListener('scroll', listener);

    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, [elementRef, animationContainerTopOffset, dispatch]);

  return elementRef;
};

export default usePointer;
