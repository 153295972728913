import { DetailsTypes } from '../types';

type ConcatParams = {
  leftEvents: DetailsTypes.Details[];
  rightEvents: DetailsTypes.Details[];
};

type MergeParams = {
  leftEvents: DetailsTypes.Details;
  rightEvents: DetailsTypes.Details;
};

const concatMappedEvents = ({ leftEvents, rightEvents }: ConcatParams): DetailsTypes.Details[] => {
  if (!leftEvents.length || !rightEvents.length) {
    return [...leftEvents, ...rightEvents];
  }

  const lastLeftEvents = leftEvents.at(-1);
  const firstRightEvents = rightEvents[0];

  if (lastLeftEvents?.date === firstRightEvents.date) {
    const leftEventsCopy = [...leftEvents];
    const rightEventsCopy = [...rightEvents];
    const mergedEvents = mergeEvents({ leftEvents: lastLeftEvents, rightEvents: firstRightEvents });

    leftEventsCopy.pop();
    rightEventsCopy.shift();

    return [...leftEventsCopy, mergedEvents, ...rightEventsCopy];
  }

  return [...leftEvents, ...rightEvents];
};

const mergeEvents = ({ leftEvents, rightEvents }: MergeParams): DetailsTypes.Details => ({
  date: leftEvents.date,
  dailyPoints: leftEvents.dailyPoints + rightEvents.dailyPoints,
  events: [...leftEvents.events, ...rightEvents.events],
});

export default concatMappedEvents;
