import { createReducer } from '@reduxjs/toolkit';

import { cleanupExpressEventsState } from '../actions';
import { getTournamentExpressEvents } from '../thunks';
import { ExpressEventsTypes } from '../types';

const defaultState: ExpressEventsTypes.State = {
  data: [],
  status: {
    isLoading: true,
  },
};

const expressEventsReducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(getTournamentExpressEvents.pending, (state) => {
      state.status.isLoading = true;
    })
    .addCase(getTournamentExpressEvents.fulfilled, (state, { payload }) => {
      state.status.isLoading = false;
      state.data = payload;
    })
    .addCase(getTournamentExpressEvents.rejected, (state) => {
      state.status.isLoading = false;
    })
    .addCase(cleanupExpressEventsState, () => {
      return defaultState;
    });
});

export default expressEventsReducer;
