import { createAsyncThunk } from '@reduxjs/toolkit';

import { Events } from 'packages/rest';
import { Sport } from 'packages/types';
import { RootState } from 'app/store';
import { LeagueSchema, QuestionsInfoSchema } from 'packages/rest/types';
import { CheckListTypes } from 'packages/shared/components';

import { matchesSelectors, metaSelectors } from '../../selectors';
import { groupEventsByDate } from '../../services';

interface ReturnType {
  eventsByDate: [string, Events.LeagueEventShortInfoSchema[]][];
  offset: number;
  hasNext: boolean;
}

interface Params {
  search?: string;
  fromDate?: string;
  toDate?: string;
  selectedLeaguesOptions: CheckListTypes.Option<LeagueSchema>[];
  selectedPredictionsOptions: CheckListTypes.Option<QuestionsInfoSchema>[];
}

const loadMoreFilteredMatches = createAsyncThunk<ReturnType, Params, { state: RootState }>(
  'tournamentManagement/loadMoreFilteredMatches',
  async (
    { search, fromDate, toDate, selectedLeaguesOptions, selectedPredictionsOptions },
    { getState },
  ) => {
    const state = getState();
    const { activeSport } = metaSelectors.getState(getState());
    const filteredEvents = matchesSelectors.getFilteredEvents(state);
    const { offset, limit } = matchesSelectors.getStatus(state);

    const { data: eventsData } = await Events.getEvents({
      leagueIds: selectedLeaguesOptions.map(({ value }) => value.id),
      codes: selectedPredictionsOptions.map(({ value }) => value.code),
      sportId: activeSport as Sport,
      fromDate,
      toDate,
      search,
      offset,
      limit,
    });
    const { events, hasNext, offset: newOffset, limit: newLimit } = eventsData;

    return {
      eventsByDate: groupEventsByDate(events, new Map(filteredEvents)),
      offset: newOffset + newLimit,
      hasNext,
    };
  },
);

export default loadMoreFilteredMatches;
