import React, { ReactElement, Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getLocaleCode } from 'packages/config/selectors';
import { getUserId } from 'packages/auth/selectors';
import { thunks } from 'packages/profile';

import { ModalListener, Toaster } from './components';
import { useAppDispatch, useAppSelector } from './store';
import { Theme, Modal } from './context';
import Router from './router';

const App = (): ReactElement => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const localeCode = useAppSelector(getLocaleCode);
  const userId = useAppSelector(getUserId);

  useEffect(() => {
    if (userId) {
      dispatch(thunks.getProfile());
    }
  }, [userId, dispatch]);

  useEffect(() => {
    i18n.changeLanguage(localeCode);
  }, [i18n, localeCode]);

  return (
    <Theme.Provider>
      <Modal.Provider>
        <Suspense fallback="...loading">
          <Router />
        </Suspense>
        <ModalListener />
        <Toaster />
      </Modal.Provider>
    </Theme.Provider>
  );
};

export default App;
