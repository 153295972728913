import { createAsyncThunk } from '@reduxjs/toolkit';

import { Friends } from 'packages/rest';

interface Params {
  userId: number;
}

interface ReturnType {
  isFriend: boolean;
}

const addFriend = createAsyncThunk<ReturnType, Params>(
  'user-search/addFriend',
  async ({ userId }) => {
    const { data } = await Friends.follow({ userId });

    return { isFriend: data.success };
  },
);

export default addFriend;
