import { ChangeEvent, MouseEventHandler, useCallback, useEffect, useState } from 'react';

interface Params {
  step: number;
  min: number;
  value?: number;
  max?: number;
  format?: (value: string) => string | number;
  onChange?: (value: string) => void;
}

const NUMBER_REGEXP = /[^0-9.]/g;

const useInput = ({ onChange, min, max, value, step, format }: Params) => {
  const validate = useCallback(
    (value: number) => {
      if (value < min) {
        value = min;
      } else if (max && value > max) {
        value = max;
      }

      return value;
    },
    [max, min],
  );

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [localValue, setLocalValue] = useState(value || 0);

  const isValueControlled = value !== undefined;
  const actualValue = isValueControlled ? value : localValue;
  const formattedValue = format ? format(`${actualValue}`) : actualValue;
  const displayedValue = isEditing ? localValue : formattedValue;

  useEffect(() => {
    setLocalValue(value || 0);
  }, [value]);

  const changeValue = useCallback(
    (sum: number) => {
      const validatedSum = validate(sum);

      setLocalValue(validatedSum);
      onChange?.(`${validatedSum}`);
    },
    [onChange, validate],
  );

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const sum = e.target.value.replace(NUMBER_REGEXP, '');

    setLocalValue(parseInt(sum === '' ? '0' : sum, 10));
  }, []);

  const handleAddClick: MouseEventHandler<SVGSVGElement> = useCallback(
    (e) => {
      e.preventDefault();

      changeValue(actualValue + step);
    },
    [changeValue, actualValue, step],
  );

  const handleRemoveClick: MouseEventHandler<SVGSVGElement> = useCallback(
    (e) => {
      e.preventDefault();

      changeValue(actualValue - step);
    },
    [changeValue, actualValue, step],
  );

  const handleFocus = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsEditing(false);
    changeValue(localValue);
  }, [localValue, changeValue]);

  return {
    value: displayedValue,
    handleChange,
    handleFocus,
    handleBlur,
    handleAddClick,
    handleRemoveClick,
  };
};

export default useInput;
