import React, { FC, memo } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface Props {
  children: string;
  onClick?: () => void;
  size?: 'sm' | 'md';
  theme?: 'primary' | 'secondary';
  disabled?: boolean;
  className?: string;
}

const TextButton: FC<Props> = ({
  children,
  theme = 'primary',
  onClick,
  size = 'md',
  disabled = false,
  className,
}) => {
  return (
    <div
      onClick={disabled ? undefined : onClick}
      className={classNames(
        styles.textButton,
        styles[size],
        styles[theme],
        {
          [styles.disabled]: disabled,
        },
        className,
      )}
    >
      {children}
    </div>
  );
};

export default memo(TextButton);
