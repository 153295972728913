import React, { FC, memo, useState } from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

interface Props {
  onChange?: (active: boolean) => void;
  active?: boolean;
  disabled?: boolean;
}

const Toggle: FC<Props> = ({ onChange, active: isActive = false, disabled = false }) => {
  const [active, setActive] = useState(isActive);

  const handleClick = () => {
    setActive((active) => {
      onChange?.(!active);

      return !active;
    });
  };

  return (
    <label className={styles.container}>
      <input
        className={styles.toggle}
        type="checkbox"
        checked={active}
        onChange={handleClick}
        disabled={disabled}
      />
      <span className={classNames(styles.button, { [styles.on]: active })} />
    </label>
  );
};

export default memo(Toggle);
