import dayjs from 'dayjs';

import { LeagueEventShortInfoSchema } from 'packages/rest/Events';

const groupEventsByDate = (
  events: LeagueEventShortInfoSchema[],
  eventsMap?: Map<string, LeagueEventShortInfoSchema[]>,
): [string, LeagueEventShortInfoSchema[]][] => {
  const eventsByDate = events.reduce<Map<string, LeagueEventShortInfoSchema[]>>(
    (eventsMap, currentEvent) => {
      const dateKey = dayjs(currentEvent.date).format('MM/DD/YYYY');
      const filteredEvents = eventsMap.get(dateKey);

      if (filteredEvents) {
        eventsMap.set(dateKey, [...filteredEvents, currentEvent]);
      } else {
        eventsMap.set(dateKey, [currentEvent]);
      }

      return eventsMap;
    },
    eventsMap || new Map(),
  );

  return Array.from(eventsByDate.entries());
};

export default groupEventsByDate;
