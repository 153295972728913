import { ReactElement, useCallback, useState } from 'react';

import Button from '../../Button';

interface Params<T extends string | number> {
  children: ReactElement<Parameters<typeof Button<T>>[0]>[];
  onChange?: (activeButtons: T[]) => void;
  isMultiple: boolean;
}

const useActiveButtons = <T extends string | number>({
  isMultiple,
  onChange,
  children,
}: Params<T>): [T[], (id: T) => void] => {
  const [activeButtons, setActiveButtons] = useState<T[]>(() => {
    return children.filter((child) => child.props.active).map((child) => child.props.id);
  });

  const handleButtonClick = useCallback(
    (id: T) => {
      let activeButtonsCopy = [...activeButtons];

      if (isMultiple) {
        const activeButtonIndex = activeButtonsCopy.findIndex(
          (activeButton) => activeButton === id,
        );

        if (activeButtonIndex < 0) {
          activeButtonsCopy.push(id);
        } else {
          activeButtonsCopy.splice(activeButtonIndex, 1);
        }
      } else {
        activeButtonsCopy = [id];
      }

      setActiveButtons(activeButtonsCopy);
      onChange?.(activeButtonsCopy);
    },
    [activeButtons, isMultiple, onChange],
  );

  return [activeButtons, handleButtonClick];
};

export default useActiveButtons;
