import React, { FC, memo, ReactElement } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

const MAX_DISPLAY_VALUE = 99;

type Props = {
  count: number;
  children: ReactElement;
};

const TopFixedCounter: FC<Props> = ({ children, count }) => {
  const countExceed = count > MAX_DISPLAY_VALUE;

  if (count === 0) {
    return children;
  }

  return (
    <div className={styles.wrapper}>
      {children}
      <span className={classNames(styles.counter, { [styles.extraSize]: countExceed })}>
        {countExceed ? `${MAX_DISPLAY_VALUE}+` : count}
      </span>
    </div>
  );
};

export default memo(TopFixedCounter);
