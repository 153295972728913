import { Language, LocaleCode } from 'app/types';

export const DEFAULT_LOCALE_CODE = LocaleCode.En;

export const languages: Language[] = [
  {
    id: 640,
    localeCode: LocaleCode.En,
    name: 'English',
    isRtl: false,
    pluralForms: ['one', 'other'],
  },
  {
    id: 597,
    localeCode: LocaleCode.Ru,
    name: 'Русский',
    isRtl: false,
    pluralForms: ['one', 'few', 'many', 'other'],
  },
];
