import { QuestionsTypes } from '../types';

const getNextActiveQuestion = (params: Omit<QuestionsTypes.Data, 'endDate' | 'startDate'>) => {
  const { activeQuestion, questions = [] } = params;

  if (activeQuestion) {
    const activeQuestionIndex = questions.findIndex(({ id }) => activeQuestion.id === id);
    const nextActiveQuestionIndex = activeQuestionIndex + 1;
    const nextActiveQuestion = questions[nextActiveQuestionIndex];

    if (nextActiveQuestion.id !== undefined) {
      return { ...nextActiveQuestion, stage: QuestionsTypes.QuestionStage.Active };
    }
  }

  return undefined;
};

export default getNextActiveQuestion;
