import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { Users } from 'packages/rest';

import { recalculateBalance } from '../actions';

interface Params {
  userId: string | number;
  count: number;
}

const refund = createAsyncThunk<void, Params, { state: RootState }>(
  'profile/refund',
  async ({ userId, count }, { dispatch }) => {
    await Users.refund({ userId, coins: count });

    dispatch(recalculateBalance({ amount: count, operation: 'add' }));
  },
);

export default refund;
