import React, { memo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { SegmentControlBar, Toggle } from 'packages/shared/components';
import { Rating } from 'packages/rest';

import Content from '../Content';
import styles from './unitFilter.module.scss';
import { Context } from '../../context';

const UnitFilter = () => {
  const { t } = useTranslation();
  const { filterPreset, updatePreset } = useContext(Context);
  const { unit, onlyFriends } = filterPreset;

  const handleUnitChange = useCallback(
    (selectedUnit: Rating.UnitType) => {
      updatePreset({ unit: selectedUnit });
    },
    [updatePreset],
  );

  const handleToggle = useCallback(
    (isActive: boolean) => {
      updatePreset({ onlyFriends: isActive });
    },
    [updatePreset],
  );

  return (
    <Content.Container header={t('leaderboard_units')}>
      <Content.Item className={styles.segmentControl}>
        <SegmentControlBar.Container<Rating.UnitType>
          onChange={handleUnitChange}
          darkAppearance
          activeId={unit}
        >
          <SegmentControlBar.Option id={Rating.UnitType.Players}>
            {t('common_players')}
          </SegmentControlBar.Option>
          <SegmentControlBar.Option id={Rating.UnitType.Teams}>
            {t('common_teams')}
          </SegmentControlBar.Option>
        </SegmentControlBar.Container>
      </Content.Item>

      <Content.Item
        className={classNames(styles.switcher, {
          [styles.disabled]: unit === Rating.UnitType.Teams,
        })}
      >
        <span className={styles.label}> {t('show_only_friends')}</span>
        <Toggle
          onChange={handleToggle}
          disabled={unit === Rating.UnitType.Teams}
          active={onlyFriends}
        />
      </Content.Item>
    </Content.Container>
  );
};

export default memo(UnitFilter);
