import { createSelector } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import { RootState } from 'app/store';
import { LocaleCode } from 'app/types';

import { State } from '../types';

const getState = (state: RootState): State => state.config;

export const getLocaleCode = createSelector(
  getState,
  (state: State): LocaleCode | undefined => state.localeCode,
);

export const getAppIdentifier = createSelector(
  getState,
  (state: State): ReturnType<typeof uuid> | undefined => state.appIdentifier,
);
