import { useCallback } from 'react';
import { DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

import { Item } from '../types';

interface Params<T extends Item> {
  items: T[];
  onOrderChange: (items: T[]) => void;
}

const useSortable = <T extends Item>({ items, onOrderChange }: Params<T>) => {
  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (active.id !== over?.id) {
        const activeIndex = items.findIndex(({ id }) => id === active.id);
        const overIndex = items.findIndex(({ id }) => id === over?.id);
        const reorderedItems = arrayMove(items, activeIndex, overIndex);

        onOrderChange(reorderedItems);
      }
    },
    [items, onOrderChange],
  );

  return {
    sensors,
    handleDragEnd,
  };
};

export default useSortable;
