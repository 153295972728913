import React, { FC, memo } from 'react';

import { Currency, Icon } from 'packages/shared/components';

import styles from './cardBottomContent.module.scss';

type Props = {
  balance: number;
};

const CardBottomContent: FC<Props> = ({ balance }) => {
  return (
    <div className={styles.container}>
      <Currency className={styles.currency} amount={balance} />

      <Icon className={styles.icon} id="addCircle" module="system" />
    </div>
  );
};

export default memo(CardBottomContent);
