import React, { DetailedHTMLProps, FC, memo } from 'react';
import classNames from 'classnames';

import styles from '../../styles.module.scss';

interface Props
  extends Omit<
    DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'size' | 'type' | 'value'
  > {
  size?: 'sm' | 'md' | 'lg';
  value?: string | number;
}

const Text: FC<Props> = ({ className, onChange, size = 'md', value = '', ...inputProps }) => {
  return (
    <input
      type="text"
      value={value}
      onChange={onChange}
      className={classNames(styles.input, styles[size], className)}
      {...inputProps}
    />
  );
};

export default memo(Text);
