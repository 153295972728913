import { createAsyncThunk } from '@reduxjs/toolkit';

import { Friends } from 'packages/rest';

interface Params {
  userId: number;
}

interface ReturnType {
  isFriend: boolean;
}

const removeFriend = createAsyncThunk<ReturnType, Params>(
  'user-search/removeFriend',
  async ({ userId }) => {
    const { data } = await Friends.unFollow({ userId });

    return { isFriend: !data.success };
  },
);

export default removeFriend;
