import React, { cloneElement, FC, memo, ReactElement, useMemo } from 'react';

import { Icon } from 'packages/shared/components';

import styles from './styles.module.scss';
import MenuLink from '../MenuLink';

type Props = {
  iconElement: ReactElement;
  label: string;
  topContent?: ReactElement;
  bottomContent?: ReactElement;
};

const MenuLinkWithContent: FC<Props> = ({ iconElement, label, topContent, bottomContent }) => {
  const icon = useMemo(() => cloneElement(iconElement, { className: styles.icon }), [iconElement]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        {icon}
        <div className={styles.contentWrapper}>
          <MenuLink className={styles.label} label={label} />
          {!!topContent && <div className={styles.topContent}> {topContent} </div>}
        </div>
        <Icon id="chevronRight" module="system" className={styles.icon} />
      </div>

      {!!bottomContent && <div className={styles.bottomContent}>{bottomContent}</div>}
    </div>
  );
};

export default memo(MenuLinkWithContent);
