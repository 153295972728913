import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';

import { LogoutBody } from '../types';

const logout = (body: LogoutBody): Promise<AxiosResponse<void>> => {
  return authInstance({
    method: 'post',
    url: '/profile/api/v1/logout',
    data: body,
  });
};

export default logout;
