import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { Sport } from 'packages/types';

import { matchesActions, settingsActions } from '../../actions';
import { matchesThunks } from '../../thunks';
import { MatchesTypes } from '../../types';

const defaultState: MatchesTypes.Data = {
  filteredEvents: [],
  leagueEvents: {},
  selectedEventsMapBySport: {},
};

const reducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(matchesActions.removeEvents, (state, { payload }) => {
      const { sport, eventIds } = payload;
      const selectedEventsMap = state.selectedEventsMapBySport[sport] || {};

      for (const eventId of eventIds) {
        delete selectedEventsMap[eventId];
      }

      state.selectedEventsMapBySport[sport] = selectedEventsMap;
    })
    .addCase(matchesActions.toggleEvent, (state, { payload }) => {
      const { sport, event } = payload;
      const selectedEventsMap = state.selectedEventsMapBySport[sport] || {};

      if (selectedEventsMap[event.id]) {
        delete selectedEventsMap[event.id];
      } else {
        selectedEventsMap[event.id] = event;
      }

      state.selectedEventsMapBySport[sport] = selectedEventsMap;
    })
    .addCase(matchesActions.updateSelectedEventsBySport, (state, { payload }) => {
      const { sport, events } = payload;

      state.selectedEventsMapBySport[sport] = events;
    })
    .addCase(matchesActions.updateSelectedSportEvents, (state, { payload }) => {
      state.selectedEventsMapBySport = payload;
    })
    .addCase(matchesThunks.loadInitialMatches.fulfilled, (state, { payload }) => {
      const { leagueEvents } = payload;

      state.leagueEvents = leagueEvents;
    })
    .addCase(matchesThunks.loadMoreMatches.fulfilled, (state, { payload }) => {
      const { leagueEvents } = payload;

      state.leagueEvents = { ...state.leagueEvents, ...leagueEvents };
    })
    .addCase(matchesThunks.loadInitialFilteredMatches.fulfilled, (state, { payload }) => {
      const { eventsByDate } = payload;

      state.filteredEvents = eventsByDate;
    })
    .addCase(settingsActions.applySettings, (state, { payload }) => {
      const { activeSports } = payload;

      for (const selectedEventsSport of Object.keys(state.selectedEventsMapBySport)) {
        const validSelectedEventsSport = <Sport>parseInt(selectedEventsSport, 10);

        if (!activeSports.includes(validSelectedEventsSport)) {
          delete state.selectedEventsMapBySport[validSelectedEventsSport];
        }
      }
    })
    .addMatcher(
      isAnyOf(
        matchesThunks.loadInitialFilteredMatches.fulfilled,
        matchesThunks.loadMoreFilteredMatches.fulfilled,
      ),
      (state, { payload }) => {
        const { eventsByDate } = payload;

        state.filteredEvents = eventsByDate;
      },
    )
    .addMatcher(
      isAnyOf(
        matchesThunks.loadInitialMatches.pending,
        matchesThunks.loadInitialMatches.rejected,
        matchesThunks.loadInitialFilteredMatches.pending,
        matchesThunks.loadInitialFilteredMatches.rejected,
        matchesActions.cleanMatches,
      ),
      (state) => {
        const { selectedEventsMapBySport } = state;

        return { ...defaultState, selectedEventsMapBySport };
      },
    );
});

export default reducer;
