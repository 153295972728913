export enum CounterTheme {
  active = 'active',
  available = 'available',
  disabled = 'disabled',
  invert = 'invert',
  secondary = 'secondary',
}

export enum Size {
  md = 'medium',
  sm = 'small',
}
