import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'packages/shared/components';
import { Rating } from 'packages/rest';

import HistoryItemWrapper from './HistoryItemWrapper';
import styles from './quizHistoryItem.module.scss';

type Props = {
  points: number;
  date: string;
  quiz: Rating.Quiz;
};

const QuizHistoryItem: FC<Props> = ({ points, date, quiz }) => {
  const { t } = useTranslation();
  const { rightQuestions, questionCount, name } = quiz;

  return (
    <HistoryItemWrapper
      points={points}
      date={date}
      header={t('quiz_bofp')}
      icon={<Icon module="base" id="quiz" />}
    >
      <div className={styles.name}>{name} </div>
      <div className={styles.results}>
        {t('leaderboard_quiz_details', [rightQuestions, questionCount])}
      </div>
    </HistoryItemWrapper>
  );
};

export default memo(QuizHistoryItem);
