import { Row } from 'react-table';

import { Tournaments } from 'packages/rest';

const getRowProps = () => {
  return ({ original }: Row<Tournaments.User>) => {
    const { isTop } = original;

    return {
      primaryGroup: isTop,
    };
  };
};

export default getRowProps;
