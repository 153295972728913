import { RefObject, useCallback, useEffect, useState } from 'react';
import { getMainTitleVisiblePoint, setContainerTopOffset } from '../services';

type useNavigationStateParams = {
  containerRef: RefObject<HTMLDivElement>;
  navigationRef: RefObject<HTMLDivElement>;
  titleRef?: RefObject<HTMLDivElement>;
};

const useNavigation = ({ containerRef, navigationRef, titleRef }: useNavigationStateParams) => {
  const [state, updateState] = useState({
    mounted: false,
    active: false,
    titleDisplayed: false,
  });

  const getUpdateActiveStateHandler = useCallback(() => {
    let navigationActive = false;

    return () => {
      if (window.scrollY > 1 && !navigationActive) {
        navigationActive = true;
        updateState((prevState) => ({ ...prevState, active: true }));
      }

      if (window.scrollY <= 1 && navigationActive) {
        navigationActive = false;
        updateState((prevState) => ({ ...prevState, active: false }));
      }
    };
  }, []);

  const getUpdateTitleStateHandler = useCallback((mainTitleVisiblePoint: number) => {
    let titleDisplayed = false;

    return () => {
      if (window.scrollY >= mainTitleVisiblePoint && !titleDisplayed) {
        titleDisplayed = true;
        updateState((prevState) => ({ ...prevState, titleDisplayed: true }));
      }

      if (window.scrollY < mainTitleVisiblePoint && titleDisplayed) {
        titleDisplayed = false;
        updateState((prevState) => ({ ...prevState, titleDisplayed: false }));
      }
    };
  }, []);

  useEffect(() => {
    const mainTitleVisiblePoint = getMainTitleVisiblePoint({ navigationRef, titleRef });
    const updateActiveState = getUpdateActiveStateHandler();
    const updateTitleState = getUpdateTitleStateHandler(mainTitleVisiblePoint);

    setContainerTopOffset({ navigationRef, containerRef });

    const scrollListener = () => {
      updateActiveState();

      if (mainTitleVisiblePoint > 0) {
        updateTitleState();
      }
    };

    window.addEventListener('scroll', scrollListener);
    updateState((prevState) => ({ ...prevState, mounted: true }));

    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, [
    navigationRef,
    titleRef,
    containerRef,
    updateState,
    getUpdateActiveStateHandler,
    getUpdateTitleStateHandler,
  ]);

  return state;
};

export default useNavigation;
