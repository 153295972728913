type GetRandomNumberParams = {
  from?: number;
  to: number;
};

export const getRandomNumber = ({ from = 0, to }: GetRandomNumberParams) => {
  const min = Math.ceil(from);
  const max = Math.floor(to);

  return Math.floor(Math.random() * (max - min) + min);
};
