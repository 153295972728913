import React, { memo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckList, CheckListTypes } from 'packages/shared/components';
import { filterConstants, FilterTypes } from 'packages/leaderboard';

import styles from './sportFilter.module.scss';
import { Context } from '../../context';

const SportFilter = () => {
  const { t } = useTranslation();
  const { filterPreset, updatePreset } = useContext(Context);
  const { sport } = filterPreset;

  const handleCheck = useCallback(
    (option: CheckListTypes.Option<FilterTypes.FilterSportId>) => {
      updatePreset({ sport: option });
    },
    [updatePreset],
  );

  return (
    <div>
      <div className={styles.header}>{t('common_sports')}</div>
      <CheckList.Single className={styles.checkList} onCheck={handleCheck} selectedOption={sport}>
        {filterConstants.SPORT_OPTIONS.map((sportOption) => (
          <CheckList.Option<FilterTypes.FilterSportId> key={sportOption.id} {...sportOption} />
        ))}
      </CheckList.Single>
    </div>
  );
};

export default memo(SportFilter);
