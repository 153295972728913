import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';

import { CreateTournamentRequest, CreateTournamentResponse } from '../types';

const createTournament = ({
  eventsCodes,
  ...rest
}: CreateTournamentRequest): Promise<AxiosResponse<CreateTournamentResponse>> => {
  return authInstance({
    method: 'post',
    url: `/v1/tournaments/create`,
    data: {
      events_codes: eventsCodes,
      ...rest,
    },
  });
};

export default createTournament;
