import { Column } from 'react-table';
import { t } from 'i18next';

import { Tournaments } from 'packages/rest';

import PlayerCell from '../PlayerCell';

const getTableSchema = (): Column<Tournaments.User>[] => {
  return [
    {
      id: 'position',
      Header: () => t('common_place'),
      accessor: 'position',
      cellStyle: { maxWidth: '37px', textAlign: 'center', paddingRight: '11px' },
      headerStyle: { maxWidth: '37px' },
    },
    {
      id: 'player',
      Header: () => t('common_player'),
      accessor: ({ firstName, lastName, photo, groups }) => ({
        firstName,
        lastName,
        photo,
        groups,
      }),
      Cell: PlayerCell,
    },
    {
      id: 'points',
      Header: () => t('common_pts'),
      accessor: 'points',
      cellStyle: { maxWidth: '25px', textAlign: 'right' },
      headerStyle: { maxWidth: '25px', textAlign: 'right' },
    },
  ];
};

export default getTableSchema;
