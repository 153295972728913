import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';

import { GetEventsByLeaguesResponse, GetEventsByLeaguesRequest } from '../types';

const getEvents = ({
  sportId,
  fromDate,
  toDate,
  leagueIds,
  codes,
  search,
  offset,
  limit,
}: GetEventsByLeaguesRequest): Promise<AxiosResponse<GetEventsByLeaguesResponse>> => {
  return authInstance({
    method: 'get',
    url: `/v2/events/sports/${sportId}`,
    params: {
      search,
      fromDate,
      toDate,
      leagueIds,
      codes,
      offset,
      limit,
    },
  });
};

export default getEvents;
