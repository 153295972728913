import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';

import { GetQuestionsRequest, GetQuestionsResponse } from '../types';

const getEvents = ({
  sportId,
}: GetQuestionsRequest): Promise<AxiosResponse<GetQuestionsResponse>> => {
  return authInstance({
    method: 'get',
    url: `/v2/events/sports/${sportId}/questions`,
  });
};

export default getEvents;
