import { AxiosResponse } from 'axios';

import { GetQuestionAnswerRequest, GetQuestionAnswerResponse } from '../types';
import getQuestionAnswer from './getQuestionAnswer';

const makeAnswerPolling = async (params: GetQuestionAnswerRequest, attempts = 3) => {
  let counter = 0;

  const makeCall = async (
    params: GetQuestionAnswerRequest,
  ): Promise<AxiosResponse<GetQuestionAnswerResponse>> => {
    counter++;

    try {
      return await getQuestionAnswer(params);
    } catch (error) {
      if (error.code === 'ECONNABORTED' && counter < attempts) {
        return makeCall(params);
      }

      throw error;
    }
  };

  return makeCall(params);
};

export default makeAnswerPolling;
