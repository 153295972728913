import { v4 as uuid } from 'uuid';

export enum Provider {
  Facebook = 'facebook-token',
  GoogleWeb = 'google',
  Google = 'custom-google',
  Apple = 'apple',
  AppleRedirectUrl = 'apple-redirect-url',
  Vkontakte = 'vkontakte',
}

export interface SignInBody {
  provider: Provider;
  identifier: ReturnType<typeof uuid>;
  accessToken: string;
  deviceType?: string;
  deviceModel?: string;
  ipAddress?: string;
  geo?: string;
  osVersion?: string;
}

export interface SignInResponse {
  refreshToken: string;
  accessToken: string;
}

export interface RefreshBody {
  refreshToken: string;
}

export interface RefreshResponse {
  refreshToken: string;
  accessToken: string;
}
