import React, { FC, memo, useCallback, useContext } from 'react';

import { TeamLabel, SportIcon } from 'packages/shared/components';
import { Rating } from 'packages/rest';
import { useAppDispatch, useAppSelector } from 'app/store';
import {
  detailsSelectors,
  filterSelectors,
  getInitialLineUpDetails,
  lineUpActions,
} from 'packages/leaderboard';

import HistoryItemWrapper from './HistoryItemWrapper';
import styles from './eventHistoryItem.module.scss';
import * as Content from '../../context';
import { ContentType } from '../../types';

type Props = {
  event: Rating.Event;
  tournament: Rating.Tournament;
  points: number;
  date: string;
};

const EventHistoryItem: FC<Props> = ({ tournament, event, date, points }) => {
  const [, setContentElement] = useContext(Content.Context);
  const dispatch = useAppDispatch();
  const { unit } = useAppSelector(filterSelectors.getActiveFilter);
  const details = useAppSelector(detailsSelectors.getData);

  const { img2, img1, club1Name, club2Name, sportId, id: eventId } = event;
  const { name, id: tournamentId } = tournament;

  const handleEventClick = useCallback(() => {
    if (unit === Rating.UnitType.Teams) {
      const { name: teamName = '', id: teamId = '' } = details;

      dispatch(lineUpActions.selectLineUp({ teamName, teamId, eventId, tournamentId }));
      dispatch(getInitialLineUpDetails({ teamId, eventId, tournamentId }));
      setContentElement(ContentType.LineUp);
    }
  }, [setContentElement, dispatch, eventId, tournamentId, details, unit]);

  return (
    <HistoryItemWrapper
      points={points}
      date={date}
      onClick={handleEventClick}
      header={name}
      icon={<SportIcon sportId={sportId} />}
    >
      <TeamLabel className={styles.team} logo={img1} name={club1Name} />
      <TeamLabel logo={img2} name={club2Name} />
    </HistoryItemWrapper>
  );
};

export default memo(EventHistoryItem);
