import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { t } from 'i18next';

import { addFriend, removeFriend, search } from '../thunks';
import { State } from '../types';
import { cleanupState, filterSearchResults } from '../actions';
import { filterUsers } from '../services';

const defaultState: State = {
  data: {
    results: undefined,
    filteredResults: undefined,
  },
  status: {
    users: {},
    isLoading: false,
    errorMessage: undefined,
  },
};

const reducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(search.pending, (state) => {
      state.status.isLoading = true;
      state.status.errorMessage = '';
    })
    .addCase(search.fulfilled, (state, { payload }) => {
      const { users } = payload;

      state.status.isLoading = false;
      state.data.results = users;
      state.data.filteredResults = users;
    })
    .addCase(search.rejected, (state) => {
      state.status.isLoading = false;
      state.status.errorMessage = t('user_search.error.search');
    })
    .addCase(cleanupState, () => {
      return defaultState;
    })
    .addCase(filterSearchResults, (state, { payload }) => {
      const { searchValue } = payload;

      state.data.filteredResults = filterUsers(state.data.results || [], searchValue);
    })
    .addMatcher(isAnyOf(addFriend.pending, removeFriend.pending), (state, { meta }) => {
      state.status.users[meta.arg.userId] = true;
    })
    .addMatcher(
      isAnyOf(addFriend.fulfilled, removeFriend.fulfilled),
      (state, { payload, meta }) => {
        const { isFriend } = payload;
        const { results, filteredResults } = state.data;
        const { userId } = meta.arg;
        const userInResults = results?.find(({ id }) => id === userId);
        const userInFilteredResults = filteredResults?.find(({ id }) => id === userId);

        if (userInResults) {
          userInResults.friendStatus = Number(isFriend);
        }

        if (userInFilteredResults) {
          userInFilteredResults.friendStatus = Number(isFriend);
        }
      },
    )
    .addMatcher(
      isAnyOf(
        addFriend.rejected,
        removeFriend.rejected,
        addFriend.fulfilled,
        removeFriend.fulfilled,
      ),
      (state, { meta }) => {
        state.status.users[meta.arg.userId] = false;
      },
    );
});

export default reducer;
