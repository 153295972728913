import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';

import { CompleteQuizResponse, CompleteQuizRequest } from '../types';

const completeQuiz = ({
  quizId,
}: CompleteQuizRequest): Promise<AxiosResponse<CompleteQuizResponse>> => {
  return authInstance({
    method: 'post',
    url: `/v1/quiz/${quizId}/complete`,
  });
};

export default completeQuiz;
