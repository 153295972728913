import React, { memo } from 'react';
import { ToastContainer } from 'react-toastify';

import styles from './styles.module.scss';

const Toaster = () => (
  <ToastContainer
    toastClassName={styles.toast}
    autoClose={2000}
    closeButton={false}
    hideProgressBar
  />
);

export default memo(Toaster);
