import { createReducer } from '@reduxjs/toolkit';

import { filterActions } from '../actions';
import { FilterTypes } from '../types';
import { filterConstants } from '../constants';

const defaultState: FilterTypes.State = {
  data: {
    active: filterConstants.ACTIVE_FILTER,
    options: filterConstants.DATE_RANGE_OPTIONS,
    search: '',
  },
};

const filterReducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(filterActions.applyPreset, (state, { payload }) => {
      state.data.active = { ...payload };
    })
    .addCase(filterActions.search, (state, { payload }) => {
      const { search } = payload;

      state.data.search = search;
    });
});

export default filterReducer;
