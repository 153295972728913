import React, { FC, memo, ReactElement, useState } from 'react';

import Context from './Context';
import { ContentType } from '../types';

type Props = {
  children: ReactElement[] | ReactElement;
};

const Provider: FC<Props> = ({ children }) => {
  const state = useState<ContentType>(ContentType.LeaderboardTable);

  return <Context.Provider value={state}>{children}</Context.Provider>;
};

export default memo(Provider);
