import { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';

import { getArrayFromNumber } from 'app/utils/Array.utils';

import { getCurrentMonthDays } from '../services';

const useCurrentDate = (initialDate: dayjs.ConfigType = dayjs()) => {
  const [currentDate, setCurrentDate] = useState(dayjs(initialDate));
  const currentMonthDays = useMemo(() => getCurrentMonthDays(currentDate), [currentDate]);
  const currentMonth = useMemo(() => currentDate.startOf('month'), [currentDate]);
  const prevMonthDayNumbers = useMemo(() => getArrayFromNumber(currentMonth.day()), [currentMonth]);

  const handleLeftClick = useCallback(() => {
    setCurrentDate(currentDate.subtract(1, 'month'));
  }, [currentDate]);

  const handleRightClick = useCallback(() => {
    setCurrentDate(currentDate.add(1, 'month'));
  }, [currentDate]);

  return {
    currentDate,
    prevMonthDayNumbers,
    currentMonthDays,
    handlers: {
      handleLeftClick,
      handleRightClick,
    },
  };
};

export default useCurrentDate;
