import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { Friends } from 'packages/rest';

import { getData } from '../selectors';
import { changeFollowersCount } from '../services';

interface ReturnType {
  isFriend: boolean;
  followersCount: number;
}

interface Params {
  userId: string | number;
}

const unFollow = createAsyncThunk<ReturnType, Params, { state: RootState }>(
  'user-profile/unFollow',
  async ({ userId }, { getState }) => {
    const { data } = await Friends.unFollow({ userId });

    const profile = getData(getState());
    const followersCount = changeFollowersCount({
      initialCount: profile?.followersCount,
      action: 'remove',
    });

    return { isFriend: !data.success, followersCount };
  },
);

export default unFollow;
