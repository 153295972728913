import { Tournaments } from 'packages/rest';

const isPlayersInvitations = (
  invitations: Tournaments.GetInvitationsResponse,
): invitations is Tournaments.PlayerInvitation[] => {
  const [invitation] = <Tournaments.PlayerInvitation[]>invitations;

  return !!invitations.length && !!invitation.firstName;
};

export default isPlayersInvitations;
