import React, { memo, ReactElement, useCallback } from 'react';
import classNames from 'classnames';

import { Option as OptionType } from '../../types';
import Context from '../../context';
import styles from './styles.module.scss';

interface Props<T> {
  className?: string;
  onCheck?: (option: OptionType<T>) => void;
  selectedOption?: OptionType<T>;
  children: ReactElement | ReactElement[];
}

const Single = <T,>({ className, onCheck, selectedOption, children }: Props<T>) => {
  const handleClick = useCallback(
    (option: OptionType<T>) => {
      onCheck?.(option);
    },
    [onCheck],
  );

  return (
    <Context.Provider value={[selectedOption ? [selectedOption] : [], handleClick]}>
      <ul className={classNames(styles.container, className)}>{children}</ul>
    </Context.Provider>
  );
};

export default memo(Single);
