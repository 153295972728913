import { createAsyncThunk } from '@reduxjs/toolkit';

import { Tournaments } from 'packages/rest';
import { RootState } from 'app/store';

import { lineUpSelectors } from '../selectors';

type ReturnType = {
  details: Tournaments.EventTeamsTopResponse;
  offset: number;
  hasNext: boolean;
};

type Params = {
  tournamentId: number;
  eventId: number;
  teamId: string | number;
};

const getInitialLineUpDetails = createAsyncThunk<ReturnType, Params, { state: RootState }>(
  'leaderboard/getInitialLineUpDetails',
  async ({ tournamentId, eventId, teamId }, { getState }) => {
    const { limit } = lineUpSelectors.getStatus(getState());
    const { data } = await Tournaments.getTournamentEventTeamsTop({
      offset: 0,
      limit,
      teamId,
      tournamentId,
      eventId,
    });

    return {
      details: data,
      offset: limit,
      hasNext: data.total > limit,
    };
  },
);

export default getInitialLineUpDetails;
