import React, { memo, ReactElement } from 'react';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DndContext, closestCenter } from '@dnd-kit/core';

import { useSortable } from './hooks';
import { SortableItem, DragHandle } from './components';
import { Item, AdditionalChildrenProps } from './types';

const modifiers = [restrictToVerticalAxis, restrictToParentElement];

interface Props<T extends Item> {
  items: T[];
  onOrderChange: (items: T[]) => void;
  useDragHandle?: boolean;
  children: (item: T, additionalProps: AdditionalChildrenProps) => ReactElement;
}

const SortableList = <T extends Item>({
  items,
  onOrderChange,
  useDragHandle,
  children,
}: Props<T>) => {
  const { sensors, handleDragEnd } = useSortable({ items, onOrderChange });

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={modifiers}
      onDragEnd={handleDragEnd}
    >
      <SortableContext strategy={verticalListSortingStrategy} items={items}>
        {items.map((item) => (
          <SortableItem key={item.id} id={item.id} useDragHandle={useDragHandle}>
            {children(item, {
              dragHandle: useDragHandle ? <DragHandle /> : undefined,
            })}
          </SortableItem>
        ))}
      </SortableContext>
    </DndContext>
  );
};

export default memo(SortableList);
