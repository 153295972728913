import { Cell, HeaderGroup, Hooks, Meta, TableCellProps, TableHeaderProps } from 'react-table';

import { getStickyStyle } from '../services';
import { FixedColumn } from '../types';

const useCustomSticky = <D extends object>(hooks: Hooks<D>) => {
  const fixedColumns = new Map<string, FixedColumn>();

  hooks.getHeaderProps.push(getHeaderProps(fixedColumns));
  hooks.getCellProps.push(getCellProps(fixedColumns));
};

type HeaderMeta<T extends object> = Meta<T, { column: HeaderGroup<T> }>;

const getHeaderProps =
  (fixedColumnsCollection: Map<string, FixedColumn>) =>
  <T extends object>(props: Partial<TableHeaderProps>, meta: HeaderMeta<T>) => {
    const { instance, column } = meta;
    const stickyStyle = getStickyStyle({
      column,
      columnInstance: instance.headers,
      accumulator: fixedColumnsCollection,
    });

    return [
      props,
      {
        style: stickyStyle,
      },
    ];
  };

type CellMeta<T extends object> = Meta<T, { cell: Cell<T> }>;

const getCellProps =
  (fixedColumnsCollection: Map<string, FixedColumn>) =>
  <T extends object>(props: Partial<TableCellProps>, meta: CellMeta<T>) => {
    const {
      instance,
      cell: { column },
    } = meta;
    const stickyStyle = getStickyStyle({
      column,
      columnInstance: instance.columns,
      accumulator: fixedColumnsCollection,
    });

    return [
      props,
      {
        style: stickyStyle,
      },
    ];
  };

useCustomSticky.pluginName = 'useCustomSticky';

export default useCustomSticky;
