import { FilterTypes, filterConstants } from 'packages/leaderboard';
import { Rating } from 'packages/rest';

type Params = {
  selected: FilterTypes.Filter;
  initial?: FilterTypes.Filter;
};

const getChangedFilterItems = ({ selected, initial = filterConstants.FILTER_PRESET }: Params) => {
  return Object.keys(selected).reduce((accumulator, key) => {
    const filterKey = key as keyof FilterTypes.Filter;

    if (filterKey === 'dateType') {
      return accumulator;
    }

    if (filterKey === 'onlyFriends' && selected.unit === Rating.UnitType.Teams) {
      return accumulator;
    }

    if (selected[filterKey] !== initial[filterKey]) {
      accumulator++;
    }

    return accumulator;
  }, 0);
};

export default getChangedFilterItems;
