import { createSelector } from '@reduxjs/toolkit';

import { State, Status } from '../types';
import getState from './getState';

export const getStatus = createSelector(getState, (state: State): Status => state.status);

export const getErrorMessage = createSelector(
  getStatus,
  (state: Status): string | undefined => state.errorMessage,
);

export const getUpdatingUsers = createSelector(
  getStatus,
  (state: Status): Record<number, boolean> => state.users,
);
