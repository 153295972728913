import React, { FC, memo, useContext } from 'react';

import { Icon } from 'packages/shared/components';

import { SortableItemContext } from '../context';
import styles from './dragHandle.module.scss';

const DragHandle: FC = () => {
  const { ref, attributes, listeners } = useContext(SortableItemContext);

  return (
    <div {...attributes} {...listeners} ref={ref}>
      <Icon className={styles.icon} module="base" id="reorder" />
    </div>
  );
};

export default memo(DragHandle);
