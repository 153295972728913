export enum Size {
  md = 'medium',
  sm = 'small',
}

export enum Theme {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  inverse = 'inverse',
  inactive = 'inactive',
  success = 'success',
  successInactive = 'successInactive',
  error = 'error',
}
