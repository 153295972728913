import React, { FC, memo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from 'packages/shared/components';

import styles from './styles.module.scss';

type Props = {
  onRefreshClick?: () => void;
  inProgress?: boolean;
  className?: string;
  header?: string;
  description?: string;
  centered?: boolean;
};

const RefreshData: FC<Props> = ({
  onRefreshClick,
  className,
  inProgress,
  header,
  description,
  centered = true,
}) => {
  const { t } = useTranslation();
  const headerText = t(header || 'error_loading_issues_title');
  const descriptionText = t(description || 'error_loading_issues_text');

  return (
    <div className={classNames(styles.wrapper, { [styles.centered]: centered }, className)}>
      <div className={styles.header}>{headerText}</div>
      <div className={styles.description}>{descriptionText}</div>

      {onRefreshClick && (
        <Button
          disabled={inProgress}
          onClick={onRefreshClick}
          className={styles.button}
          label={t('common_try_again_button')}
          textResponsive
          size="sm"
          theme="tertiary"
          iconElement={<Icon module="base" id="refresh" />}
        />
      )}
    </div>
  );
};

export default memo(RefreshData);
