import React, { FC, memo, useCallback, useContext, useMemo } from 'react';

import { Table } from 'packages/shared/components';
import { Rating } from 'packages/rest';
import { DetailsTypes, filterSelectors, ratingSelectors } from 'packages/leaderboard';
import { useAppSelector } from 'app/store';
import { selectors } from 'packages/auth';
import { Page } from 'app/router/types';
import { useAppNavigation } from 'app/router/hooks';
import { Modal } from 'app/context';

import styles from './table.module.scss';
import { getPlayerRatingSchema, getPlayerRowProps } from './services';

type Props = {
  data?: Rating.PlayerRating[];
  onRowClick: (params: Omit<DetailsTypes.Data, 'details'>) => void;
};

const PlayerRatingTable: FC<Props> = ({ data, onRowClick }) => {
  const { updateActiveModals } = useContext(Modal.Context);
  const { appNavigate } = useAppNavigation();
  const activeFilter = useAppSelector(filterSelectors.getActiveFilter);
  const { id: userId } = useAppSelector(ratingSelectors.getCurrentUser) || {};
  const currentUserId = useAppSelector(selectors.getUserId);
  const { measurement } = activeFilter;

  const rowProps = useMemo(
    () =>
      getPlayerRowProps({
        onClick: onRowClick,
        userId,
      }),
    [userId, onRowClick],
  );

  const handlePlayerClick = useCallback(
    (id: number) => {
      if (id === currentUserId) {
        appNavigate(Page.Profile, {});
      } else {
        appNavigate(Page.UserProfile, { id: `${id}` });
      }

      updateActiveModals({ leaderboard: false });
    },
    [appNavigate, currentUserId, updateActiveModals],
  );

  const tableSchema = useMemo(
    () => getPlayerRatingSchema({ measurement, onPlayerClick: handlePlayerClick }),
    [measurement, handlePlayerClick],
  );

  return (
    <Table<Rating.PlayerRating>
      className={styles.table}
      headerClassName={styles.tableHeader}
      setRowProps={rowProps}
      isHeaderSticky
      columns={tableSchema}
      data={data || []}
    />
  );
};
export default memo(PlayerRatingTable);
