import React, { FC, memo, useCallback, useContext, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import {
  DefaultNavBar,
  InfiniteScrollLoader,
  LoaderContainer,
  Table,
} from 'packages/shared/components';
import { useAppDispatch, useAppSelector } from 'app/store';
import { getMoreLineUpDetails, lineUpActions, lineUpSelectors } from 'packages/leaderboard';
import { Tournaments } from 'packages/rest';

import EventResult from './EventResult';
import DetailsHeader from './DetailsHeader';
import styles from './styles.module.scss';
import { getTableSchema, getRowProps } from './services';
import { ContentType } from '../../types';
import { Context } from '../../context';

const LineUp: FC = () => {
  const [, setContentElement] = useContext(Context);
  const dispatch = useAppDispatch();
  const { teamName } = useAppSelector(lineUpSelectors.getData);
  const { isInitialLoading, isMoreLoading, hasNext } = useAppSelector(lineUpSelectors.getStatus);
  const { all = [] } = useAppSelector(lineUpSelectors.getDetails) || {};
  const tableSchema = useMemo(getTableSchema, []);
  const rowProps = useMemo(getRowProps, []);

  const handleBackClick = useCallback(() => {
    setContentElement(ContentType.Details);
    dispatch(lineUpActions.cleanupState());
  }, [setContentElement, dispatch]);

  const handleLoadMore = useCallback(() => {
    if (!isMoreLoading) {
      dispatch(getMoreLineUpDetails());
    }
  }, [dispatch, isMoreLoading]);

  return (
    <>
      <DefaultNavBar
        className={styles.header}
        description={teamName}
        title={<EventResult />}
        onLeftClick={handleBackClick}
      />

      <LoaderContainer fullSize isLoading={isInitialLoading}>
        <InfiniteScroll
          hasMore={hasNext}
          loadMore={handleLoadMore}
          useWindow={false}
          initialLoad={false}
          loader={<InfiniteScrollLoader key="loader" />}
          threshold={10}
        >
          <div className={styles.detailsHeaderWrapper}>
            <DetailsHeader />
          </div>
          <Table<Tournaments.User>
            className={styles.table}
            headerClassName={styles.tableHeader}
            setRowProps={rowProps}
            columns={tableSchema}
            isHeaderSticky
            data={all}
          />
        </InfiniteScroll>
      </LoaderContainer>
    </>
  );
};

export default memo(LineUp);
