import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';

import { GetActiveResponse } from '../types';

const getActive = (): Promise<AxiosResponse<GetActiveResponse>> => {
  return authInstance({
    method: 'get',
    url: '/v1/quiz/active',
  });
};

export default getActive;
