import { SettingsTypes } from '../types';

const getPlacesFromPoolType = (prizePool: SettingsTypes.PrizePoolData) => {
  switch (prizePool.poolType) {
    case SettingsTypes.PoolType.TopOne: {
      return [];
    }
    case SettingsTypes.PoolType.WinnersPercent: {
      const { winnersPercent } = <SettingsTypes.WinnersPercentPrizePoolData>prizePool;
      const { percent } = winnersPercent;

      return [percent];
    }
    case SettingsTypes.PoolType.Custom: {
      const { custom } = <SettingsTypes.CustomPrizePoolData>prizePool;
      const { winners } = custom;

      return winners.map(({ percent }) => percent);
    }
    default: {
      throw new Error('unexpected PoolType');
    }
  }
};

export default getPlacesFromPoolType;
