import React, { memo } from 'react';
import { MenuProps } from 'react-select';
import classNames from 'classnames';

import { Option, Size } from '../types';
import styles from './menu.module.scss';

const Menu = <V extends string | number, T extends Option<V>>({
  innerRef,
  innerProps,
  children,
  className,
  selectProps,
}: MenuProps<T>) => {
  const { size } = selectProps;

  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={classNames(styles.menu, className, styles[Size[size]])}
    >
      {children}
    </div>
  );
};

export default memo(Menu);
