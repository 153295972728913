import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type Props = {
  topText?: string;
  bottomText?: string;
  onTopTextClick?: () => void;
};

export const NavigationHeader: FC<Props> = ({ topText, onTopTextClick, bottomText }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div onClick={onTopTextClick} className={styles.top}>
        {topText || t('common_button_cancel')}
      </div>

      {!!bottomText && <div className={styles.bottom}>{bottomText}</div>}
    </div>
  );
};

export default memo(NavigationHeader);
