import { RefObject } from 'react';

type Params = {
  navigationRef: RefObject<HTMLDivElement>;
  titleRef?: RefObject<HTMLDivElement>;
};

const getMainTitleVisiblePoint = ({ navigationRef, titleRef }: Params) => {
  if (!navigationRef.current || !titleRef?.current) {
    return 0;
  }

  const navigationElementHeight = navigationRef.current.getBoundingClientRect().height;
  const { height: titleElementHeight, y: titleTopOffset } =
    titleRef.current.getBoundingClientRect() || {};

  return titleTopOffset + titleElementHeight - navigationElementHeight;
};

export default getMainTitleVisiblePoint;
