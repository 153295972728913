import { createAsyncThunk } from '@reduxjs/toolkit';

import { Friends } from 'packages/rest';

interface Params {
  userId: string | number;
  searchType: Friends.SearchType;
}

interface ReturnType {
  users: Friends.SearchResponse[];
}

const search = createAsyncThunk<ReturnType, Params>(
  'user-search/search',
  async ({ userId, searchType }) => {
    const { data } = await Friends.search({ relation: searchType, userId });

    return { users: data };
  },
);

export default search;
