import React, { memo, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Counter } from 'packages/shared/components';

import { getChangedFilterItems } from '../../../../services';
import styles from './navBarTitle.module.scss';
import { Context } from '../../context';

const NavBarTitle = () => {
  const { t } = useTranslation();
  const { filterPreset } = useContext(Context);

  const changedFilterItems = useMemo(
    () => getChangedFilterItems({ selected: filterPreset }),
    [filterPreset],
  );

  return (
    <div className={styles.wrapper}>
      {t('common_filter')}
      {!!changedFilterItems && <Counter className={styles.counter} count={changedFilterItems} />}
    </div>
  );
};

export default memo(NavBarTitle);
