import React, { FC, memo } from 'react';
import classNames from 'classnames';

import { FilterCounter, Icon, Input } from 'packages/shared/components';

import { useSearch } from './hooks';
import styles from './styles.module.scss';

type Props = {
  className?: string;
  placeholder?: string;
  filterActiveItemsCount?: number;
  debounceTime?: number;
  onChange?: (value: string) => void;
  onFilterIconClick?: () => void;
  onActionTextClick?: () => void;
  useParentWidth?: boolean;
  useDebounce?: boolean;
  resetValueOn?: any[];
  initialValue?: string;
};

// TODO: refactor placeholder to be placeholderKey with default value 'common_search'
const Search: FC<Props> = ({
  className,
  placeholder,
  onFilterIconClick,
  filterActiveItemsCount,
  onActionTextClick,
  useParentWidth = true,
  onChange,
  debounceTime = 500,
  useDebounce = false,
  resetValueOn = [],
  initialValue = '',
}) => {
  const { state, handlers } = useSearch({
    useDebounce,
    debounceTime,
    resetValueOn,
    onChange,
    initialValue,
  });
  const { handleChange, handleClear, handleFocus, handleBlur } = handlers;
  const { focused, searchValue } = state;

  return (
    <div
      className={classNames(
        styles.wrapper,
        { [styles.useParentWidth]: useParentWidth, [styles.focused]: focused },
        className,
      )}
    >
      <label htmlFor="search">
        <Icon className={styles.searchIcon} id="search" module="tabbar" />
        <Input.Text
          id="search"
          onChange={handleChange}
          value={searchValue}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholder}
          className={styles.input}
        />
        <Icon
          onClick={handleClear}
          className={classNames(styles.clearIcon, { [styles.visible]: !!searchValue })}
          id="clearCircle"
          module="system"
        />
      </label>

      {!!onFilterIconClick && (
        <div className={styles.filter} onClick={onFilterIconClick}>
          <FilterCounter activeItems={filterActiveItemsCount} />
        </div>
      )}

      {onActionTextClick && <div className={styles.actionText}>Cancel</div>}
    </div>
  );
};

export default memo(Search);
