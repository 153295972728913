import { useCallback } from 'react';
import { NavigateOptions, useNavigate } from 'react-router-dom';

import { Page, RouterParams } from '../types';

const useAppNavigation = () => {
  const navigate = useNavigate();

  const appNavigate = useCallback(
    <T extends keyof RouterParams>(
      page: T,
      pageParams: RouterParams[T],
      query?: string,
      options?: NavigateOptions,
    ) => {
      switch (page) {
        case Page.Home: {
          navigate(`/`);

          break;
        }

        case Page.SignIn: {
          navigate(`/sign-in`);

          break;
        }

        case Page.Profile:
        case Page.OwnProfile: {
          navigate(`/profile`);

          break;
        }

        case Page.ProfileUserSearch: {
          const { type } = pageParams as RouterParams[Page.ProfileUserSearch];

          navigate(`/profile/user-search/${type}`, options);
          break;
        }

        case Page.UserProfile: {
          const { id } = pageParams as RouterParams[Page.UserProfile];

          navigate(`/user/${id}`);
          break;
        }

        case Page.UserSearch: {
          const { id, type } = pageParams as RouterParams[Page.UserSearch];

          navigate(`/user/${id}/user-search/${type}`, options);
          break;
        }

        case Page.CreateTournament: {
          navigate(`/create-tournament`);
          break;
        }

        case Page.Quiz:
        case Page.QuizHome: {
          navigate('/quiz', options);
          break;
        }

        case Page.QuizGame: {
          const { id } = pageParams as RouterParams[Page.QuizGame];

          navigate(`/quiz/${id}`, options);
          break;
        }

        case Page.QuizLeaderboard: {
          navigate('/quiz/leaderboard');
          break;
        }

        default:
          console.error('route undefined');
          break;
      }
    },
    [navigate],
  );

  const goBack = useCallback(() => navigate(-1), [navigate]);

  return { appNavigate, goBack };
};

export default useAppNavigation;
