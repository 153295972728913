import { SelectTypes } from 'packages/shared/components';
import { Sport } from 'packages/types';
import { Tournaments } from 'packages/rest';

export interface State {
  data: Data;
}

export interface Data {
  title: string;
  description: string;
  activeSports: Sport[];
  type: Tournaments.TournamentType;
  buyIn: number;
  royalty: number;
  isPrivate: boolean;
  prizePool: PrizePoolData;
}

export interface DefaultPrizePoolData {
  poolType: PoolType;
  winnersPercent?: {
    percent: number;
    activeTrophy: SelectTypes.Option<TrophyType> | null;
  };
  custom?: {
    numberOfWinners: number;
    winners: Record<number, Winner>;
    winnersEdited: boolean;
  };
}

export interface WinnersPercentPrizePoolData extends DefaultPrizePoolData {
  winnersPercent: {
    percent: number;
    activeTrophy: SelectTypes.Option<TrophyType> | null;
  };
}

export interface CustomPrizePoolData extends DefaultPrizePoolData {
  custom: {
    numberOfWinners: number;
    winners: Winner[];
    winnersEdited: boolean;
  };
}

export type PrizePoolData =
  | DefaultPrizePoolData
  | WinnersPercentPrizePoolData
  | CustomPrizePoolData;

export enum PoolType {
  TopOne = Tournaments.TournamentRule.WinnerTakesAll,
  WinnersPercent = Tournaments.TournamentRule.WinnerTakesPercent,
  Custom = Tournaments.TournamentRule.CustomPlaces,
}

export enum TrophyType {
  Low = 'low',
  DefaultLess = 'defaultLess',
  Default = 'default',
  DefaultMore = 'defaultMore',
  High = 'high',
}

export interface Winner {
  place: number;
  weight: number;
  percent: number;
}
