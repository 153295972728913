import { CounterTypes } from 'packages/shared/components';

import { Theme } from '../types';

const getCounterTheme = (tagTheme: Theme): keyof typeof CounterTypes.CounterTheme => {
  switch (tagTheme) {
    case Theme.primary:
      return 'active';
    case Theme.greyInverse:
      return 'disabled';
    case Theme.primaryAction:
      return 'secondary';
    default:
      return 'invert';
  }
};

export default getCounterTheme;
