import React, { FC, memo, ReactElement } from 'react';
import classNames from 'classnames';

import styles from './item.module.scss';

type Props = {
  children: ReactElement | ReactElement[];
  onClick?: () => void;
  className?: string;
};

const Item: FC<Props> = ({ children, className, onClick }) => {
  return (
    <li onClick={onClick} className={classNames(styles.item, className)}>
      {children}
    </li>
  );
};

export default memo(Item);
