import { AxiosInstance, AxiosRequestConfig } from 'axios';

import { localStorage } from 'app/storage';
import { Auth } from 'packages/rest';

import configureInstance from './configureInstance';

const configureAuthInstance = (requestConfig: AxiosRequestConfig): AxiosInstance => {
  const authInstance = configureInstance(requestConfig);

  addRequestInterceptor(authInstance);
  addResponseInterceptor(authInstance);

  return authInstance;
};

const addRequestInterceptor = (instance: AxiosInstance): void => {
  instance.interceptors.request.use((config) => {
    const accessToken = localStorage.get<string>('accessToken');

    if (config.headers) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      config.headers = { Authorization: `Bearer ${accessToken}` };
    }

    return config;
  });
};

const addResponseInterceptor = (instance: AxiosInstance): void => {
  let retried = false;

  instance.interceptors.response.use(
    (config) => config,
    async (error) => {
      const originalRequest = error.config;

      if (error?.response?.status === 401 && !retried) {
        if (!retried) {
          retried = true;

          const refreshToken = localStorage.get<string>('refreshToken');
          const response = await Auth.refresh({ refreshToken });
          const { refreshToken: responseRefreshToken, accessToken } = response.data;

          localStorage.set('accessToken', accessToken);
          localStorage.set('refreshToken', responseRefreshToken);

          return instance.request(originalRequest);
        }

        retried = false;
      }

      throw error;
    },
  );
};

export default configureAuthInstance;
