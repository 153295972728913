import React, { FC, memo, useMemo } from 'react';
import classNames from 'classnames';

import { Icon } from 'packages/shared/components';

import { useInput } from './hooks';
import styles from './styles.module.scss';

interface Props {
  value?: number;
  step?: number;
  min?: number;
  max?: number;
  useControls?: boolean;
  children?: (sum: string) => string | number;
  onChange?: (value: string) => void;
  controllableOnly?: boolean;
  appearanceBase?: boolean;
  disabled?: boolean;
  className?: string;
}

const InputSumm: FC<Props> = ({
  onChange,
  appearanceBase,
  disabled,
  className,
  max,
  value: controlledValue,
  min = 0,
  step = 10,
  controllableOnly = false,
  useControls = true,
  children = (sum) => `${sum}`,
}) => {
  const { handleChange, handleBlur, handleFocus, handleRemoveClick, handleAddClick, value } =
    useInput({ onChange, min, max, value: controlledValue, step, format: children });

  const controls = useMemo(
    () =>
      useControls ? (
        <>
          <Icon
            module="system"
            id="add"
            onClick={handleAddClick}
            className={classNames(styles.icon, styles.iconAdd)}
          />
          <Icon
            module="system"
            id="remove"
            onClick={handleRemoveClick}
            className={classNames(styles.icon, styles.iconRemove)}
          />
        </>
      ) : (
        <></>
      ),
    [handleAddClick, handleRemoveClick, useControls],
  );

  if (controllableOnly) {
    return (
      <div className={styles.inputContainer}>
        <div
          className={classNames(
            styles.inputSumm,
            { [styles.appearanceBase]: appearanceBase, [styles.controlled]: useControls },
            className,
          )}
        >
          {value}
        </div>
        {controls}
      </div>
    );
  }

  return (
    <label className={styles.inputContainer}>
      <input
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
        inputMode="numeric"
        type="text"
        value={value}
        disabled={disabled}
        className={classNames(
          styles.inputSumm,
          { [styles.appearanceBase]: appearanceBase, [styles.controlled]: useControls },
          className,
        )}
      />
      {controls}
    </label>
  );
};

export default memo(InputSumm);
