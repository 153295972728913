import { createReducer } from '@reduxjs/toolkit';

import { QuestionsTypes } from '../../types';
import { questionsThunks } from '../../thunks';
import { questionsActions } from '../../actions';
import { getNextActiveQuestion, updateActiveQuestionInQuiz } from '../../services';

const defaultState: QuestionsTypes.Data = {};

const questionsReducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(questionsThunks.getQuestions.fulfilled, (state, { payload }) => {
      return payload;
    })
    .addCase(questionsActions.setActiveQuestionTimerStage, (state, { payload }) => {
      const { questions = [] } = state;
      const { stage } = payload;

      if (state.activeQuestion) {
        state.activeQuestion.stage = stage;
        state.questions = updateActiveQuestionInQuiz({
          quiz: questions,
          activeQuestion: state.activeQuestion,
        });
      }
    })
    .addCase(questionsThunks.getAnswer.fulfilled, (state, { payload }) => {
      return { ...state, ...payload };
    })
    .addCase(questionsActions.setNextActiveQuestion, (state) => {
      const { activeQuestion, lastQuestionId, questions = [] } = state;
      const nextActiveQuestion = getNextActiveQuestion({
        activeQuestion,
        lastQuestionId,
        questions,
      });

      if (nextActiveQuestion) {
        state.activeQuestion = nextActiveQuestion;
        state.questions = updateActiveQuestionInQuiz({
          quiz: questions,
          activeQuestion: nextActiveQuestion,
        });
      }
    })
    .addCase(questionsActions.cleanupState, () => {
      return { ...defaultState };
    });
});

export default questionsReducer;
