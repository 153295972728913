import { Children, cloneElement, ReactElement, useMemo } from 'react';

interface Params {
  root: ReactElement;
  children: ReactElement | ReactElement[];
  activePageId: string | null;
  navigateTo: (id: string) => void;
  goBack: () => void;
}

const usePages = ({ root, children, activePageId, navigateTo, goBack }: Params) => {
  const childrenArray = useMemo(() => Children.toArray(children), [children]) as ReactElement[];

  const pages = useMemo(
    () => childrenArray.map((child) => cloneElement(child, { goBack })),
    [childrenArray, goBack],
  );

  const pagesMap = useMemo(
    () =>
      pages.reduce<Record<string, ReactElement>>((pagesMap, currentPage) => {
        const { id } = currentPage.props;

        pagesMap[id] = currentPage;

        return pagesMap;
      }, {}),
    [pages],
  );

  const rootElement = useMemo(() => cloneElement(root, { navigateTo }), [navigateTo, root]);

  return { activePage: activePageId && pagesMap[activePageId], rootElement };
};

export default usePages;
