import { AxiosResponse } from 'axios';

import { instance } from 'app/rest';

import { RefreshBody, RefreshResponse } from '../types';

const refresh = ({ refreshToken }: RefreshBody): Promise<AxiosResponse<RefreshResponse>> => {
  return instance({
    method: 'post',
    url: '/profile/api/v1/refresh-token/refresh',
    data: {
      refresh_token: refreshToken,
    },
  });
};

export default refresh;
