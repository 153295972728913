import React, { FC, memo, ReactElement } from 'react';

import { Icon } from 'packages/shared/components';

import { NavBar } from '../../components';

type Props = {
  title?: string | ReactElement;
  description?: string | ReactElement;
  onLeftClick?: () => void;
  onRightClick?: () => void;
  className?: string;
  sideContentClassName?: string;
};

const DefaultNavBar: FC<Props> = ({
  className,
  sideContentClassName,
  title,
  description,
  onRightClick,
  onLeftClick,
}) => {
  return (
    <NavBar
      className={className}
      sideContentClassName={sideContentClassName}
      titleContent={title}
      descriptionContent={description}
      leftContent={
        onLeftClick ? <Icon onClick={onLeftClick} id="chevronLeft" module="system" /> : undefined
      }
      rightContent={
        onRightClick ? <Icon onClick={onRightClick} id="more" module="system" /> : undefined
      }
    />
  );
};

export default memo(DefaultNavBar);
