import React, { FC, memo, useRef } from 'react';
import classNames from 'classnames';

import { ScrollPointerVerticalAlignment, TabBarItem } from '../../types';
import { usePointer } from './hooks';
import styles from './styles.module.scss';

type Props = {
  id: TabBarItem['id'];
  verticalAlignment?: keyof typeof ScrollPointerVerticalAlignment;
  className?: string;
};

const ScrollPointer: FC<Props> = ({ id, verticalAlignment = '', className }) => {
  const elementRef = usePointer({ id });

  return (
    <div
      className={classNames(styles.pointer, styles[verticalAlignment], className)}
      ref={elementRef}
      id={id}
    />
  );
};

export default memo(ScrollPointer);
