import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'packages/shared/components';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectors, thunks } from 'packages/auth';

import MenuLink from '../MenuLink';
import styles from './styles.module.scss';

const SettingsLinks = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectors.getIsLoading);
  const userId = useAppSelector(selectors.getUserId);

  const handleLogoutClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();

      if (!isLoading) {
        dispatch(thunks.logout({ identifier: `${userId}` }));
      }
    },
    [dispatch, isLoading, userId],
  );

  const { t } = useTranslation();

  return (
    <ul className={styles.wrapper}>
      <li>
        <MenuLink iconElement={<Icon id="settings" module="base" />} label={t('common_settings')} />
      </li>
      <li>
        <MenuLink
          onClick={handleLogoutClick}
          iconElement={<Icon id="logout" module="base" />}
          label={t('common_logout')}
        />
      </li>
    </ul>
  );
};

export default memo(SettingsLinks);
