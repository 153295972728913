import { createAction } from '@reduxjs/toolkit';
import { TournamentsV2 } from 'packages/rest';

export const cleanupState = createAction('superExpress/cleanupState');
export const cleanupExpressEventsState = createAction('superExpress/cleanupExpressEventsState');
export const addTicketToList = createAction<TournamentsV2.ExpressTicket>(
  'superExpress/addTicketToList',
);
export const allowToLoadMoreTickets = createAction('superExpress/allowToLoadMoreTickets');
export const cleanupMakePredictionState = createAction('superExpress/cleanupMakePredictionState');
