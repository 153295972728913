import React, { cloneElement, memo, ReactElement, useMemo } from 'react';
import classNames from 'classnames';

import { Button as CommonButton, Counter } from 'packages/shared/components';

import styles from './styles.module.scss';

interface Props<T extends string | number>
  extends Omit<Parameters<typeof CommonButton>[0], 'theme' | 'rightContent' | 'leftContent'> {
  id: T;
  count?: number;
  iconElement?: ReactElement;
  currentStage?: boolean;
  active?: boolean;
  className?: string;
}

const Button = <T extends string | number>({
  id,
  count,
  iconElement,
  currentStage = false,
  active = false,
  className,
  size = 'sm',
  textResponsive = true,
  ...commonButtonProps
}: Props<T>) => {
  const icon = useMemo(
    () =>
      iconElement ? (
        cloneElement(iconElement, {
          className: classNames(styles.icon, iconElement.props?.className),
        })
      ) : (
        <></>
      ),
    [iconElement],
  );

  const counter = useMemo(
    () => (count ? <Counter count={count} className={styles.counter} /> : undefined),
    [count],
  );

  return (
    <div className={styles.container}>
      <CommonButton
        id={id}
        size={size}
        theme="tertiary"
        iconElement={icon}
        rightContent={counter}
        textResponsive={textResponsive}
        className={classNames(
          styles.button,
          styles.important,
          { [styles.active]: active, [styles.currentStage]: currentStage },
          className,
        )}
        {...commonButtonProps}
      />
    </div>
  );
};

export default memo(Button);
