import { Events } from 'packages/rest';
import { Sport } from 'packages/types';

import { AvailablePredictionsTypes } from '../types';

const mapAvailablePredictions = (markets: Events.GetAvailableMarketsResponse) => {
  const predictionsBySport: Partial<Record<Sport, AvailablePredictionsTypes.Predictions>> = {};
  const sports: Sport[] = [];

  for (const market of markets) {
    const { sportId } = market;

    predictionsBySport[sportId] = getMarketCodes(market);
    sports.push(sportId);
  }

  return { predictionsBySport, sports };
};

const getMarketCodes = (
  market: Events.GetAvailableMarketsResponse[0],
): AvailablePredictionsTypes.Predictions => {
  const { marketsCodes } = market;
  const cleanMarketCodes: AvailablePredictionsTypes.CleanMarketCode[] = [];
  const marketCodesWithConflict: AvailablePredictionsTypes.MarketCodeWithConflicts[] = [];

  for (const marketCode of marketsCodes) {
    const { eventsWithoutCode, ...rest } = marketCode;

    if (!eventsWithoutCode.length) {
      cleanMarketCodes.push({ ...rest });
    } else {
      marketCodesWithConflict.push({
        conflictedEventIds: eventsWithoutCode,
        ...rest,
      });
    }
  }

  return { cleanMarketCodes, marketCodesWithConflict };
};

export default mapAvailablePredictions;
