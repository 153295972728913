import React, { memo, ReactElement, useCallback } from 'react';
import classNames from 'classnames';

import { Option as OptionType } from '../../types';
import Context from '../../context';
import styles from './styles.module.scss';

interface Props<T> {
  className?: string;
  children: ReactElement | ReactElement[];
  onCheck: (option: OptionType<T>[]) => void;
  selectedOptions?: OptionType<T>[];
}

const Multiple = <T,>({ className, children, onCheck, selectedOptions = [] }: Props<T>) => {
  const handleClick = useCallback(
    (option: OptionType<T>) => {
      const isOptionSelected = selectedOptions.some(
        (selectedItem) => selectedItem.id === option.id,
      );
      const options = isOptionSelected
        ? selectedOptions.filter((selectedItem) => selectedItem.id !== option.id)
        : [...selectedOptions, option];

      onCheck(options);
    },
    [onCheck, selectedOptions],
  );

  return (
    <Context.Provider value={[selectedOptions, handleClick]}>
      <ul className={classNames(styles.container, className)}>{children}</ul>
    </Context.Provider>
  );
};

export default memo(Multiple);
