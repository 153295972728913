import { createReducer } from '@reduxjs/toolkit';

import { DetailsTypes } from '../types';
import { detailsThunks } from '../thunks';
import { questionsActions } from '../actions';

const defaultState: DetailsTypes.State = {
  data: {
    isResettable: false,
  },
  status: {
    isCompleteLoading: false,
    isResettableLoading: false,
  },
};

const detailsReducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(detailsThunks.checkReset.pending, (state) => {
      state.status.isResettableLoading = true;
    })
    .addCase(detailsThunks.checkReset.fulfilled, (state, { payload }) => {
      state.status.isResettableLoading = false;
      state.data.isResettable = payload;
    })
    .addCase(detailsThunks.checkReset.rejected, (state) => {
      state.status.isResettableLoading = false;
    })
    .addCase(detailsThunks.completeQuiz.pending, (state) => {
      state.status.isCompleteLoading = true;
    })
    .addCase(detailsThunks.completeQuiz.fulfilled, (state, { payload }) => {
      state.data = { ...state.data, ...payload };
      state.status.isCompleteLoading = false;
    })
    .addCase(detailsThunks.completeQuiz.rejected, (state) => {
      state.status.isCompleteLoading = false;
    })
    .addCase(questionsActions.cleanupState, () => {
      return { ...defaultState };
    });
});

export default detailsReducer;
