import { createSelector } from '@reduxjs/toolkit';

import { PredictionsTypes } from '../../types';
import getState from './getState';

export const getStatus = createSelector(
  getState,
  (state: PredictionsTypes.State): PredictionsTypes.Status => state.status,
);

export const getIsLoading = createSelector(
  getStatus,
  (state: PredictionsTypes.Status): boolean => state.isLoading,
);
