import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';

import { GetQuizRatingRequest, GetQuizRatingResponse } from '../types';

const getQuizRating = ({
  limit,
  offset,
  search,
}: GetQuizRatingRequest): Promise<AxiosResponse<GetQuizRatingResponse>> => {
  return authInstance({
    method: 'get',
    url: '/v1/rating/quiz',
    params: {
      offset,
      limit,
      search,
    },
  });
};

export default getQuizRating;
