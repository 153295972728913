import { createAsyncThunk } from '@reduxjs/toolkit';

import { Rating } from 'packages/rest';
import { RootState } from 'app/store';

import { mapEventsDetails, concatMappedEvents } from '../services';
import { DetailsTypes, FilterTypes } from '../types';
import { detailsSelectors, filterSelectors } from '../selectors';

type ReturnType = {
  details: DetailsTypes.Details[];
  offset: number;
  hasNext: boolean;
};

const getMoreRatingItemDetails = createAsyncThunk<ReturnType, void, { state: RootState }>(
  'leaderboard/getMoreRatingItemDetails',
  async (params, { getState }) => {
    const { offset, limit } = detailsSelectors.getStatus(getState());
    const { details = [], id = '' } = detailsSelectors.getData(getState());
    const { unit, dateOffset, dateType, sport } = filterSelectors.getActiveFilter(getState());

    const { data } = await Rating.getRatingDetails({
      limit,
      offset,
      type: unit,
      id,
      dateOffset: dateOffset.value,
      dateType,
      sportId: sport.value === FilterTypes.SportId.All ? undefined : sport.value,
    });

    return {
      details: concatMappedEvents({ leftEvents: details, rightEvents: mapEventsDetails(data) }),
      offset: offset + limit,
      hasNext: limit <= (data || []).length,
    };
  },
);

export default getMoreRatingItemDetails;
