import { RefObject, useEffect } from 'react';

import { getScrollParent } from 'app/utils/Scroll.utils';

interface Params {
  containerRef: RefObject<HTMLDivElement>;
  useWindowScroll: boolean;
}

const useParentOverflowHidden = ({ containerRef, useWindowScroll }: Params) => {
  useEffect(() => {
    const scrollParent = getScrollParent(containerRef.current) as HTMLElement;

    scrollParent.style.overflowX = 'hidden';

    return () => {
      scrollParent.style.overflowX = '';
    };
  }, [containerRef, useWindowScroll]);
};

export default useParentOverflowHidden;
