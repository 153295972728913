import { createSelector } from '@reduxjs/toolkit';

import { TournamentsV2 } from 'packages/rest';

import { ExpressTypes } from '../../types';
import getState from './getState';

export const getData = createSelector(
  getState,
  (state: ExpressTypes.State): ExpressTypes.Data => state.data,
);

export const getEvents = createSelector(
  getData,
  (state: TournamentsV2.GetTournamentExpressResponse): TournamentsV2.ExpressEvent[] => state.events,
);

export const getTickets = createSelector(
  getData,
  (state: TournamentsV2.GetTournamentExpressResponse): TournamentsV2.ExpressTicket[] =>
    state.tickets,
);
