import { createAsyncThunk } from '@reduxjs/toolkit';

import { Quiz } from 'packages/rest';

interface Params {
  quizId: string | number;
}

const checkReset = createAsyncThunk<boolean, Params>('quiz/checkReset', async ({ quizId }) => {
  const { data } = await Quiz.checkQuiz({ quizId });

  const { success } = data;

  return success;
});

export default checkReset;
