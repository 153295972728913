import { createReducer } from '@reduxjs/toolkit';
import { t } from 'i18next';

import { signIn, refresh, logout } from '../thunks';
import { cleanupErrorMessage } from '../actions';
import { State } from '../types';

const defaultState: State = {
  data: {
    authorized: false,
  },
  status: {
    isLoading: false,
    errorMessage: undefined,
  },
};

const reducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(signIn.pending, (state) => {
      state.status.isLoading = true;
      state.status.errorMessage = '';
    })
    .addCase(signIn.fulfilled, (state, { payload }) => {
      const { userId, role } = payload;

      state.data.userId = userId;
      state.data.role = role;
      state.data.authorized = true;
      state.status.isLoading = false;
    })
    .addCase(signIn.rejected, (state) => {
      state.data.userId = undefined;
      state.data.authorized = false;
      state.status.isLoading = false;
      state.status.errorMessage = t('login.form.error.invalidLoginOrPassword');
    })

    .addCase(refresh.pending, (state) => {
      state.status.isLoading = true;
      state.status.errorMessage = '';
    })
    .addCase(refresh.fulfilled, (state, { payload }) => {
      const { userId, role } = payload;

      state.data.userId = userId;
      state.data.role = role;
      state.data.authorized = true;
      state.status.isLoading = false;
    })
    .addCase(refresh.rejected, (state) => {
      state.data.userId = undefined;
      state.data.authorized = false;
      state.status.isLoading = false;
      state.status.errorMessage = t('login.form.error.invalidLoginOrPassword');
    })
    .addCase(logout.pending, (state) => {
      state.status.isLoading = true;
    })
    .addCase(logout.fulfilled, (state) => {
      state.data.userId = undefined;
      state.data.role = undefined;
      state.data.authorized = false;
      state.status.isLoading = false;
    })
    .addCase(logout.rejected, (state) => {
      state.status.isLoading = false;
    })

    .addCase(cleanupErrorMessage, (state) => {
      state.status.errorMessage = undefined;
    });
});

export default reducer;
