import { Row } from 'react-table';

import { Rating } from 'packages/rest';
import { DetailsTypes } from 'packages/leaderboard';

type Params = {
  onClick: (params: Omit<DetailsTypes.Data, 'details'>) => void;
  userTeamId?: number;
};

const getPlayerRowProps = ({ onClick, userTeamId }: Params) => {
  return ({ original }: Row<Rating.TeamRating>) => {
    const { name, photo, id, points } = original;

    return {
      highlighted: id === userTeamId,
      onClick: () => onClick({ name, id, photo, points }),
    };
  };
};

export default getPlayerRowProps;
