type Params = {
  initialCount?: number;
  action: 'add' | 'remove';
};

const changeFollowersCount = ({ initialCount, action }: Params) => {
  if (!initialCount) {
    return 0;
  }

  if (action === 'remove') {
    return initialCount - 1;
  }

  return initialCount + 1;
};

export default changeFollowersCount;
