import React, { FC, memo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'packages/shared/components';
import { Modal } from 'app/context';

import MenuLink from '../MenuLink';
import styles from './styles.module.scss';

const MainLinks: FC = () => {
  const { updateActiveModals } = useContext(Modal.Context);
  const { t } = useTranslation();

  const handleLeaderboardClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      updateActiveModals({ leaderboard: true, mainMenu: false });
    },
    [updateActiveModals],
  );

  return (
    <ul className={styles.wrapper}>
      <li>
        <MenuLink iconElement={<Icon id="home" module="tabbar" />} label={t('common_dashboard')} />
      </li>
      <li>
        <MenuLink
          iconElement={<Icon id="career" module="base" />}
          label={t('common_sport_career')}
        />
      </li>
      <li>
        <MenuLink
          onClick={handleLeaderboardClick}
          iconElement={<Icon id="leaderboard" module="base" />}
          label={t('common_leaderboard')}
        />
      </li>
      <li>
        <MenuLink
          iconElement={<Icon id="tournament" module="tabbar" />}
          label={t('common_all_tournaments')}
        />
      </li>
      <li>
        <MenuLink
          iconElement={<Icon id="seriesScheduled" module="base" />}
          label={t('common_competitions')}
        />
      </li>
      <li>
        <MenuLink
          iconElement={<Icon id="notifications" module="tabbar" />}
          label={t('news_title')}
        />
      </li>
    </ul>
  );
};

export default memo(MainLinks);
