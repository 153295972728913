import { PredictionChoice } from 'packages/rest/types';
import { Nullable } from 'packages/types';

export enum TicketStatus {
  CanWin = 'CAN_WIN',
  Win = 'WIN',
  Lose = 'LOSE',
  Play = 'PLAY',
  Canceled = 'CANCELED',
}

export type ExpressEvent = {
  id: number;
  club1Id: number;
  club2Id: number;
  club1Name: string;
  club2Name: string;
  leagueId: number;
  leagueName: string;
  img1: string;
  img2: string;
  date: string;
  live: boolean;
  status: number;
  score: string;
  question: string;
};

export interface TicketEventChoice {
  choice: PredictionChoice;
  status: number;
}

export interface ExpressTicketEvent {
  id: number;
  choices: TicketEventChoice[];
}

export interface ExpressTicket {
  set: number;
  events: ExpressTicketEvent[];
  variants: number;
  reward: Nullable<number>;
  winCount: number;
  ticketStatus: TicketStatus;
}

export interface GetTournamentExpressResponse {
  events: ExpressEvent[];
  tickets: ExpressTicket[];
}

export interface GetTournamentExpressRequest {
  tournamentId: string | number;
  offset: number;
  limit: number;
}

export interface Tournament {
  auction: Nullable<unknown>;
  contestant: Nullable<unknown>;
  creator: TournamentCreator;
  finances: TournamentFinances;
  rating: Nullable<unknown>;
  settings: TournamentSettings;
  standingsPhases: Nullable<unknown>;
  tournament: TournamentDetails;
  waitingEventsIds: Nullable<unknown>;
  tournamentState: TournamentState;
  winner: Nullable<unknown>;
}

export interface TournamentCreator {
  id: number;
  img: string;
  name: string;
}

export interface TournamentFinances {
  cost: Nullable<unknown>;
  reward: {
    totalPrize: number;
    title: string;
  };
  eventChangingPrice: Nullable<unknown>;
}

export interface TournamentSettings {
  countPoints: boolean;
  level: string;
  rulesType: number;
  topCount: number;
  expert: boolean;
  plannedEventsCount: Nullable<unknown>;
}

export interface TournamentDetails {
  id: number;
  name: string;
  img: string;
  sportId: [number];
  startDate: string;
  endDate: string;
  globalType: string;
}

export interface TournamentState {
  joined: number;
  roundName: Nullable<string>;
  relation: Nullable<string>;
  isSkipped: boolean;
  timerDate: Nullable<unknown>;
  hasLiveEvents: boolean;
  commentsCount: Nullable<unknown>;
}
