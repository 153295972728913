import { createContext } from 'react';
import { Option as OptionType } from '../types';

export type ContextType<T = any> = [OptionType<T>[], (option: OptionType<T>) => void];

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const initialContentState: ContextType = [[], () => {}];

const Context = createContext(initialContentState);

export default Context;
