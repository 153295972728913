import { createReducer } from '@reduxjs/toolkit';

import { metaActions, settingsActions } from '../../actions';
import { MetaTypes } from '../../types';

const defaultState: MetaTypes.State = {};

const reducer = createReducer<MetaTypes.State>(defaultState, (builder) => {
  builder
    .addCase(metaActions.setActiveSport, (state, { payload }) => {
      return { ...state, activeSport: payload };
    })
    .addCase(settingsActions.applySettings, (state, { payload }) => {
      const { activeSports } = payload;

      return { ...state, activeSport: activeSports[0] };
    });
});

export default reducer;
