import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { getInitialRatingItemDetails, getMoreRatingItemDetails } from '../../thunks';
import { DetailsTypes } from '../../types';
import { commonActions, detailsActions } from '../../actions';

const defaultState: DetailsTypes.Status = {
  isInitialLoading: false,
  isMoreLoading: false,
  offset: 0,
  limit: 30,
  hasNext: true,
};

const reducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(getInitialRatingItemDetails.pending, (state) => {
      state.isInitialLoading = true;
    })
    .addCase(getInitialRatingItemDetails.fulfilled, (state, { payload }) => {
      const { offset, hasNext } = payload;

      return { ...state, isInitialLoading: false, offset, hasNext };
    })
    .addCase(getInitialRatingItemDetails.rejected, (state) => {
      state.isInitialLoading = false;
    })
    .addCase(getMoreRatingItemDetails.pending, (state) => {
      state.isMoreLoading = true;
    })
    .addCase(getMoreRatingItemDetails.fulfilled, (state, { payload }) => {
      const { offset, hasNext } = payload;

      return { ...state, isMoreLoading: false, offset, hasNext };
    })
    .addCase(getMoreRatingItemDetails.rejected, (state) => {
      state.isMoreLoading = false;
    })
    .addMatcher(isAnyOf(detailsActions.cleanupState, commonActions.cleanupState), () => {
      return defaultState;
    });
});

export default reducer;
