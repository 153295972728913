import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { t } from 'i18next';

import { follow, getProfile, unFollow } from '../thunks';
import { State } from '../types';
import { cleanupState } from '../actions';

const defaultState: State = {
  data: undefined,
  status: {
    isFollowerLoading: false,
    isProfileLoading: false,
    errorMessage: undefined,
  },
};

const reducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(getProfile.pending, (state) => {
      state.status.isProfileLoading = true;
      state.status.errorMessage = '';
    })
    .addCase(getProfile.fulfilled, (state, { payload }) => {
      const { data } = payload;

      state.status.isProfileLoading = false;
      state.data = data;
    })
    .addCase(getProfile.rejected, (state) => {
      state.status.isProfileLoading = false;
      state.status.errorMessage = t('profile.error.loading_profile_data');
    })
    .addCase(cleanupState, () => {
      return defaultState;
    })
    .addMatcher(isAnyOf(follow.pending, unFollow.pending), (state) => {
      state.status.isFollowerLoading = true;
    })
    .addMatcher(isAnyOf(follow.fulfilled, unFollow.fulfilled), (state, { payload }) => {
      const { isFriend, followersCount } = payload;

      if (state.data) {
        state.data.followersCount = followersCount;
        state.data.isFriend = isFriend;
      }
    })
    .addMatcher(
      isAnyOf(follow.rejected, unFollow.rejected, follow.fulfilled, unFollow.fulfilled),
      (state) => {
        state.status.isFollowerLoading = false;
      },
    );
});

export default reducer;
