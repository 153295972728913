import React, { memo, ReactElement } from 'react';
import { UniqueIdentifier } from '@dnd-kit/core';
import classNames from 'classnames';

import { useSortableItem } from '../hooks';
import { SortableItemContext } from '../context';
import styles from './sortableItem.module.scss';

type Props = {
  id: UniqueIdentifier;
  children: ReactElement | string;
  useDragHandle?: boolean;
};

export const SortableItem = ({ id, children, useDragHandle }: Props) => {
  const { context, props, sortableProps, isDragging } = useSortableItem({ id, useDragHandle });

  return (
    <SortableItemContext.Provider value={context}>
      {/* @ts-ignore */}
      <div
        className={classNames(styles.item, { [styles.dragging]: isDragging })}
        {...sortableProps}
        {...props}
      >
        {children}
      </div>
    </SortableItemContext.Provider>
  );
};

export default memo(SortableItem);
