import { AxiosResponse } from 'axios';
import { authInstance } from 'app/rest';

import { GetUserByIdRequest, GetUserByIdResponse } from '../types';

const getProfile = ({
  userId,
}: GetUserByIdRequest): Promise<AxiosResponse<GetUserByIdResponse>> => {
  return authInstance({ method: 'get', url: `/v2/users/${userId}` });
};

export default getProfile;
