import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { matchesActions, metaActions } from '../../actions';
import { leaguesThunks } from '../../thunks';
import { LeaguesTypes } from '../../types';

const defaultState: LeaguesTypes.Status = {
  isLoading: true,
};

const reducer = createReducer(defaultState, (builder) => {
  builder
    .addMatcher(
      isAnyOf(leaguesThunks.loadLeagues.fulfilled, leaguesThunks.loadLeagues.rejected),
      () => {
        return { isLoading: false };
      },
    )
    .addMatcher(
      isAnyOf(
        leaguesThunks.loadLeagues.pending,
        matchesActions.cleanMatches,
        metaActions.setActiveSport,
      ),
      () => {
        return { isLoading: true };
      },
    );
});

export default reducer;
