import React, { FC, memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Tag } from 'packages/shared/components';
import { QuizDefailInfoSchema } from 'packages/rest/ProfileV2';
import { useAppNavigation } from 'app/router/hooks';
import { Page } from 'app/router/types';
import { Modal } from 'app/context';

import MenuLinkWithContent from '../MenuLinkWithContent';
import styles from './styles.module.scss';

type Props = {
  activeQuiz?: QuizDefailInfoSchema;
};

const QuizBlock: FC<Props> = ({ activeQuiz }) => {
  const { appNavigate } = useAppNavigation();
  const { updateActiveModals } = useContext(Modal.Context);

  const { t } = useTranslation();

  const handleQuizClick = () => {
    appNavigate(Page.QuizHome, {});
    updateActiveModals({ mainMenu: false });
  };

  return (
    <div onClick={handleQuizClick} className={styles.wrapper}>
      <MenuLinkWithContent
        topContent={activeQuiz && <Tag theme="tertiary" label={t('quiz_is_waiting')} />}
        iconElement={<Icon id="quiz" module="base" />}
        label={t('common_quiz')}
      />
    </div>
  );
};

export default memo(QuizBlock);
