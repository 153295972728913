import { Sport } from 'packages/types';

import { availablePredictionsConstants } from '../constants';
import { AvailablePredictionsTypes } from '../types';

interface Params {
  predictionsBySport: Record<Sport, AvailablePredictionsTypes.Predictions>;
  selectedMarketCodes: Record<Sport, AvailablePredictionsTypes.CleanMarketCode[]>;
}

const reorderSelectedMarketCodes = ({ predictionsBySport, selectedMarketCodes }: Params) => {
  const selectedMarketCodesBySport = {
    ...availablePredictionsConstants.DEFAULT_SELECTED_PREDICTIONS_BY_SPORT,
  };

  for (const [sport, predictions] of Object.entries(predictionsBySport)) {
    const { cleanMarketCodes } = predictions;
    const validSport = sport as unknown as Sport;
    const selectedPredictions: AvailablePredictionsTypes.CleanMarketCode[] = [];

    for (const cleanMarketCode of cleanMarketCodes) {
      const foundPrediction = selectedMarketCodes[validSport].find(
        ({ code }) => code === cleanMarketCode.code,
      );

      if (foundPrediction) {
        selectedPredictions.push(foundPrediction);
      }
    }

    selectedMarketCodesBySport[validSport] = selectedPredictions;
  }

  return selectedMarketCodesBySport;
};

export default reorderSelectedMarketCodes;
