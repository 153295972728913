import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';
import { Tournament } from '../types';

const getOtherTournaments = (): Promise<AxiosResponse<Tournament[]>> => {
  return authInstance({
    method: 'get',
    url: '/v2/tournaments/other',
  });
};

export default getOtherTournaments;
