import { createReducer } from '@reduxjs/toolkit';

import { StatisticsTypes } from '../types';
import { statisticsThunks } from '../thunks';
import { statisticsActions } from '../actions';

const defaultState: StatisticsTypes.State = {
  data: undefined,
  status: {
    isLoading: false,
  },
};

const statisticsReducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(statisticsThunks.getQuizInfo.pending, (state) => {
      state.status.errorMessage = undefined;
      state.status.isLoading = true;
    })
    .addCase(statisticsThunks.getQuizInfo.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.status.isLoading = false;
    })
    .addCase(statisticsThunks.getQuizInfo.rejected, (state) => {
      state.status.isLoading = false;
    })
    .addCase(statisticsActions.cleanupState, () => {
      return { ...defaultState };
    });
});

export default statisticsReducer;
