import { createReducer } from '@reduxjs/toolkit';

import { LeaderboardTypes } from '../../types';
import { leaderboardThunks } from '../../thunks';
import { leaderboardActions } from '../../actions';

const defaultState: LeaderboardTypes.Data = {
  all: [],
  me: undefined,
};

const leaderboardReducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(leaderboardThunks.getInitialRating.fulfilled, (state, { payload }) => {
      return payload;
    })
    .addCase(leaderboardThunks.getMoreRating.fulfilled, (state, { payload }) => {
      return { ...state, all: [...state.all, ...payload.all] };
    })
    .addCase(leaderboardActions.cleanupState, () => {
      return { ...defaultState };
    });
});

export default leaderboardReducer;
