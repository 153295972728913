import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { Users } from 'packages/rest';

import { recalculateBalance } from '../actions';

interface Params {
  userId: string | number;
  count: number;
  countWithPercent: number;
}

const sendGift = createAsyncThunk<void, Params, { state: RootState }>(
  'profile/sendGift',
  async ({ userId, count, countWithPercent }, { dispatch }) => {
    await Users.donate({ userId, coins: count });

    dispatch(recalculateBalance({ amount: countWithPercent, operation: 'sub' }));
  },
);

export default sendGift;
