import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';

import { UnFollowRequest, UnFollowResponse } from '../types';

const unFollow = ({ userId }: UnFollowRequest): Promise<AxiosResponse<UnFollowResponse>> => {
  return authInstance({ method: 'post', url: `/v1/friends/${userId}/unfollow` });
};

export default unFollow;
