import { Nullable } from 'packages/types';

import { UserGroup } from '../../types';

export interface FollowResponse {
  success: boolean;
}

export interface FollowRequest {
  userId: string | number;
}

export interface UnFollowResponse {
  success: boolean;
}

export interface UnFollowRequest {
  userId: string | number;
}

export enum SearchType {
  Followers = 'followers',
  Following = 'following',
}

export interface SearchResponse {
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  photo: Nullable<string>;
  teamId: Nullable<number>;
  teamName: Nullable<string>;
  teamPhoto: Nullable<string>;
  position: Nullable<number>;
  points: Nullable<number>;
  id: number;
  friendStatus: Nullable<number>;
  status: Nullable<number>;
  groups: Nullable<UserGroup[]>;
}

export interface SearchRequest {
  userId: string | number;
  relation: SearchType;
}
