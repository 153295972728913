import { combineReducers } from '@reduxjs/toolkit';

import actual from './actual';
import completed from './completed';
import others from './others';
import user from './user';
import waiting from './waiting';

const reducer = combineReducers({
  actual,
  completed,
  others,
  user,
  waiting,
});

export default reducer;
