import { createAsyncThunk } from '@reduxjs/toolkit';

import { TournamentsV2 } from 'packages/rest';

const getWaiting = createAsyncThunk<TournamentsV2.Tournament[], void>(
  'tournaments/getWaiting',
  async () => {
    const { data } = await TournamentsV2.getWaitingTournaments();

    return data;
  },
);

export default getWaiting;
