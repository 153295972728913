import React, { createContext } from 'react';

import { NavigationAction, NavigationState } from '../types';

export const initialContextState: [NavigationState, React.Dispatch<NavigationAction>] = [
  {
    tabBarTopOffset: 0,
    tabBarHeight: 0,
    tabs: [],
    activeTabId: '',
    animationContainerActive: false,
    animationContainerTopOffset: 0,
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
];

const Context = createContext(initialContextState);

export default Context;
