import { useEffect, useState, RefObject } from 'react';

import { ScrollUtils } from 'app/utils';

type Params = {
  elementRef: RefObject<HTMLElement>;
  scrollContainer?: Element | Node;
};

const getVisiblePoint = ({ elementRef, scrollContainer }: Params) => {
  const parentContainer = scrollContainer?.parentNode;
  const { offsetHeight: navBarHeight = 0 } = elementRef.current || {};
  const { offsetHeight: quiffHeight = 0 } = parentContainer?.firstElementChild as HTMLElement;

  return navBarHeight + quiffHeight;
};

const isVisibleInitially = (elementRef: RefObject<HTMLElement>) => {
  const scrollContainer = ScrollUtils.getScrollParent(elementRef.current);
  const visiblePoint = getVisiblePoint({ elementRef, scrollContainer });
  const { scrollTop } = scrollContainer as Element;

  return scrollTop > visiblePoint;
};

const useFilterVisibility = (elementRef: RefObject<HTMLElement>) => {
  const [filterVisible, setVisibility] = useState<boolean>(false);

  useEffect(() => {
    setVisibility(isVisibleInitially(elementRef));

    const scrollContainer = ScrollUtils.getScrollParent(elementRef.current);
    const visiblePoint = getVisiblePoint({ elementRef, scrollContainer });
    let isVisible = false;

    const checkScrollPosition = () => {
      const { scrollTop } = scrollContainer as Element;

      if (scrollTop > visiblePoint && !isVisible) {
        isVisible = true;
        setVisibility(true);
      }

      if (scrollTop < visiblePoint && isVisible) {
        isVisible = false;
        setVisibility(false);
      }
    };

    scrollContainer?.addEventListener('scroll', checkScrollPosition);

    return () => {
      scrollContainer?.removeEventListener('scroll', checkScrollPosition);
    };
  }, [elementRef]);

  return filterVisible;
};

export default useFilterVisibility;
