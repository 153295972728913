import { combineReducers } from '@reduxjs/toolkit';

import tournament from './tournament';
import express from './express';
import expressEvents from './expressEvents';
import makePrediction from './makePrediction';

const reducer = combineReducers({ tournament, express, expressEvents, makePrediction });

export default reducer;
