import { createReducer } from '@reduxjs/toolkit';

import { getInitialRating, getMoreRating } from '../../thunks';
import { RatingTypes } from '../../types';
import { commonActions } from '../../actions';

const defaultState: RatingTypes.Status = {
  isInitialLoading: false,
  isMoreLoading: false,
  offset: 0,
  limit: 30,
  hasNext: true,
};

const reducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(getInitialRating.pending, (state) => {
      state.isInitialLoading = true;
    })
    .addCase(getInitialRating.fulfilled, (state, { payload }) => {
      const { offset, hasNext } = payload;

      return { ...state, isInitialLoading: false, offset, hasNext };
    })
    .addCase(getInitialRating.rejected, (state) => {
      state.isInitialLoading = false;
    })
    .addCase(getMoreRating.pending, (state) => {
      state.isMoreLoading = true;
    })
    .addCase(getMoreRating.fulfilled, (state, { payload }) => {
      const { offset, hasNext } = payload;

      return { ...state, isMoreLoading: false, offset, hasNext };
    })
    .addCase(getMoreRating.rejected, (state) => {
      state.isMoreLoading = false;
    })
    .addCase(commonActions.cleanupState, () => {
      return defaultState;
    });
});

export default reducer;
