import { createAsyncThunk } from '@reduxjs/toolkit';

import { Users } from 'packages/rest';
import { recalculateBalance } from '../actions';

interface Params {
  userId: string | number;
  count: number;
}

const donate = createAsyncThunk<void, Params>(
  'profile/donate',
  async ({ userId, count }, { dispatch }) => {
    await Users.donate({ userId, coins: count });

    dispatch(recalculateBalance({ amount: count, operation: 'sub' }));
  },
);

export default donate;
