import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Nullable } from 'packages/types';

import { getUserRoleKey } from './services';
import styles from './userRole.module.scss';

type Props = {
  role: number;
  captainId: Nullable<number>;
};

const UserRole: FC<Props> = ({ role, captainId }) => {
  const { t } = useTranslation();
  const roleKey = getUserRoleKey({ captainId, role });

  return (
    <span className={classNames({ [styles.noCaptain]: captainId === null })}>{t(roleKey)}</span>
  );
};

export default memo(UserRole);
