import React, {
  cloneElement,
  CSSProperties,
  FC,
  memo,
  ReactElement,
  RefObject,
  useMemo,
  useRef,
} from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';
import { useNavigation } from './hooks';

type Props = {
  children: ReactElement | ReactElement[];
  navigationElement: ReactElement;
  titleRef?: RefObject<HTMLDivElement>;
  style?: CSSProperties;
  className?: string;
};

const ContentHeader: FC<Props> = ({
  children,
  className,
  navigationElement,
  style = {},
  titleRef,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const navigationRef = useRef<HTMLDivElement>(null);
  const { active, titleDisplayed, mounted } = useNavigation({
    containerRef,
    titleRef,
    navigationRef,
  });
  const navigation = useMemo(
    () =>
      cloneElement(navigationElement, {
        ...navigationElement.props,
        active,
        titleDisplayed,
      }),
    [active, titleDisplayed, navigationElement],
  );

  return (
    <div style={style} ref={containerRef} className={className}>
      <div
        className={classNames(styles.navigation, { [styles.fixed]: mounted })}
        ref={navigationRef}
      >
        {navigation}
      </div>
      {children}
    </div>
  );
};

export default memo(ContentHeader);
