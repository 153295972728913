import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { t } from 'i18next';

import { getProfile, donate, refund, sendGift } from '../thunks';
import { State } from '../types';
import { cleanupState, recalculateBalance } from '../actions';
import { calculateBalance } from '../services';

const defaultState: State = {
  data: undefined,
  status: {
    isGiftLoading: false,
    isDonateLoading: false,
    isRefundLoading: false,
    isProfileLoading: false,
    errorMessage: undefined,
  },
};

const reducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(getProfile.pending, (state) => {
      state.status.isProfileLoading = true;
      state.status.errorMessage = '';
    })
    .addCase(getProfile.fulfilled, (state, { payload }) => {
      const { data } = payload;

      state.status.isProfileLoading = false;
      state.data = data;
    })
    .addCase(getProfile.rejected, (state) => {
      state.status.isProfileLoading = false;
      state.status.errorMessage = t('profile.error.loading_profile_data');
    })
    .addCase(recalculateBalance, (state, { payload }) => {
      if (state.data) {
        const { amount, operation } = payload;
        const balance = +state.data.user.balance;

        state.data.user.balance = calculateBalance({
          balance,
          amount,
          type: operation,
        });
      }
    })
    .addCase(cleanupState, () => {
      return defaultState;
    })
    .addCase(donate.pending, (state) => {
      state.status.isDonateLoading = true;
    })
    .addCase(refund.pending, (state) => {
      state.status.isRefundLoading = true;
    })
    .addCase(sendGift.pending, (state) => {
      state.status.isGiftLoading = true;
    })
    .addMatcher(isAnyOf(donate.rejected, donate.fulfilled), (state) => {
      state.status.isDonateLoading = false;
    })
    .addMatcher(isAnyOf(refund.rejected, refund.fulfilled), (state) => {
      state.status.isRefundLoading = false;
    })
    .addMatcher(isAnyOf(sendGift.rejected, sendGift.fulfilled), (state) => {
      state.status.isGiftLoading = false;
    });
});

export default reducer;
