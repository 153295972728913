import { createContext } from 'react';

import { filterConstants, FilterTypes } from 'packages/leaderboard';

export const initialContextState: {
  filterPreset: FilterTypes.Filter;
  updatePreset: (updates: Partial<FilterTypes.Filter>) => void;
} = {
  filterPreset: filterConstants.FILTER_PRESET,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updatePreset: () => {},
};

const Context = createContext(initialContextState);

export default Context;
