import { LeagueEventShortInfoSchema } from 'packages/rest/Events';

const groupEventsByLeagueIds = (events: LeagueEventShortInfoSchema[]) => {
  return events.reduce<Record<number, LeagueEventShortInfoSchema[]>>((eventsMap, currentEvent) => {
    const { leagueId } = currentEvent;

    if (eventsMap[leagueId]) {
      eventsMap[leagueId].push(currentEvent);
    } else {
      eventsMap[leagueId] = [currentEvent];
    }

    return eventsMap;
  }, {});
};

export default groupEventsByLeagueIds;
