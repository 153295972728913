import { createSelector } from '@reduxjs/toolkit';

import { QuestionsTypes } from '../../types';
import getState from './getState';

export const getData = createSelector(
  getState,
  (state: QuestionsTypes.State): QuestionsTypes.Data => state.data,
);

export const getQuestions = createSelector(
  getData,
  (state: QuestionsTypes.Data): QuestionsTypes.Question[] => state.questions || [],
);

export const getPoints = createSelector(getData, (state: QuestionsTypes.Data): number =>
  (state.questions || []).reduce((accumulator, currentElement) => {
    const { stage, points } = currentElement;
    if (stage === QuestionsTypes.QuestionStage.Correct) {
      accumulator += points;
    }

    return accumulator;
  }, 0),
);
