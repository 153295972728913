import React, { FC, memo } from 'react';

import { Currency, Icon } from 'packages/shared/components';
import { useAppSelector } from 'app/store';
import { selectors } from 'packages/profile';

import styles from './cardBottomContent.module.scss';

const CardBottomContent: FC = () => {
  const balance = useAppSelector(selectors.getBalance);

  return (
    <div className={styles.container}>
      <Currency className={styles.currency} amount={balance} />

      <Icon className={styles.icon} id="addCircle" module="system" />
    </div>
  );
};

export default memo(CardBottomContent);
