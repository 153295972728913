import { AvailablePredictionsTypes } from '../../types';

const insertMarketCodesIntoLastSelectedPosition = (
  cleanMarketCodes: AvailablePredictionsTypes.CleanMarketCode[],
  resolvedMarketCodes: AvailablePredictionsTypes.CleanMarketCode[],
  selectedMarketCodes: AvailablePredictionsTypes.CleanMarketCode[],
) => {
  const cleanMarketCodesCopy = [...cleanMarketCodes];
  const lastSelected = selectedMarketCodes.at(-1);
  const lastSelectedIndex = cleanMarketCodesCopy.findIndex(
    (marketCode) => marketCode.code === lastSelected?.code,
  );

  cleanMarketCodesCopy.splice(lastSelectedIndex + 1, 0, ...resolvedMarketCodes);

  return cleanMarketCodesCopy;
};

export default insertMarketCodesIntoLastSelectedPosition;
