import React, { FC, SVGProps } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';
import { IconId, Module } from './types';

interface Props extends SVGProps<SVGSVGElement> {
  id: keyof typeof IconId;
  module: keyof typeof Module;
}

const Icon: FC<Props> = ({ id, module, className, ...props }) => {
  return (
    <svg
      preserveAspectRatio="xMidYMid meet"
      className={classNames(styles.icon, styles[module], styles[id], className)}
      {...props}
    >
      <use href={`/images/sprite.svg#${module}-${IconId[id]}`} />
    </svg>
  );
};

export default Icon;
