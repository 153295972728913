import { Link } from '../types';

export const LINKS: Link[] = [
  {
    to: '/news',
    icon: { module: 'tabbar', id: 'notifications' },
    activeIcon: { module: 'tabbar', id: 'notificationsActive' },
    title: 'news_title',
  },
  {
    to: '/tournaments',
    icon: { module: 'tabbar', id: 'tournament' },
    activeIcon: { module: 'tabbar', id: 'tournamentActive' },
    title: 'tabbar_tournaments',
  },
  {
    to: '/',
    icon: { module: 'tabbar', id: 'home' },
    activeIcon: { module: 'tabbar', id: 'homeActive' },
    title: 'tabbar_home',
  },
  {
    to: '/search',
    icon: { module: 'tabbar', id: 'search' },
    activeIcon: { module: 'tabbar', id: 'searchActive' },
    title: 'common_search',
  },
];
