import React, { memo, useCallback, useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'app/store';
import { thunks, selectors as menuSelectors } from 'packages/main-menu';
import { selectors as profileSelectors } from 'packages/profile';
import { UserGroup } from 'packages/rest/types';
import { LoaderContainer, RefreshData } from 'packages/shared/components';

import styles from './styles.module.scss';

import {
  FunClubs,
  MainLinks,
  SettingsLinks,
  SupportLinks,
  TrackingLinks,
  UserCard,
  TeamCard,
  QuizBlock,
  NationalTeamCard,
} from './components';

const MainMenu = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(thunks.getProfileMenu());
  }, [dispatch]);

  const handleRefreshData = useCallback(() => {
    dispatch(thunks.getProfileMenu());
  }, [dispatch]);

  const userMenuData = useAppSelector(menuSelectors.getData);
  const isMenuLoading = useAppSelector(menuSelectors.getIsLoading);
  const { isProfileLoading } = useAppSelector(profileSelectors.getStatus);

  const supportLinkDisplayed = useMemo(
    () => userMenuData?.user.groups.some((group) => group === UserGroup.SUPPORT),
    [userMenuData?.user.groups],
  );

  return (
    <LoaderContainer isLoading={(isMenuLoading || isProfileLoading) && !userMenuData} fullSize>
      {userMenuData ? (
        <>
          <UserCard
            unreadMessagesCount={userMenuData.unreadMessagesCount}
            user={userMenuData.user}
          />
          <TeamCard team={userMenuData.team} />
          <NationalTeamCard team={userMenuData.country} />
          <FunClubs clubs={userMenuData.fanclubs} tennisClub={userMenuData.tennisRating} />
          <MainLinks />
          <QuizBlock />
          <TrackingLinks />
          <SupportLinks supportLinkDisplayed={supportLinkDisplayed} />
          <SettingsLinks />
        </>
      ) : (
        <RefreshData
          inProgress={isMenuLoading}
          onRefreshClick={handleRefreshData}
          className={styles.refreshBlock}
        />
      )}
    </LoaderContainer>
  );
};

export default memo(MainMenu);
