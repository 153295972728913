import { createReducer } from '@reduxjs/toolkit';

import { cleanupState } from '../actions';
import { getTournament, makePrediction } from '../thunks';
import { TournamentTypes } from '../types';

const defaultState: TournamentTypes.State = {
  data: undefined,
  status: {
    isLoading: true,
  },
};

const tournamentReducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(getTournament.pending, (state) => {
      state.status.isLoading = true;
    })
    .addCase(makePrediction.fulfilled, (state) => {
      if (state.data && !state.data.isJoined) {
        state.data.isJoined = true;
      }
    })
    .addCase(getTournament.fulfilled, (state, { payload }) => {
      state.status.isLoading = false;
      state.data = payload;
    })
    .addCase(getTournament.rejected, (state) => {
      state.status.isLoading = false;
    })
    .addCase(cleanupState, () => {
      return defaultState;
    });
});

export default tournamentReducer;
