import React, { memo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { SegmentControlBar } from 'packages/shared/components';
import { Rating } from 'packages/rest';

import Content from '../Content';
import styles from './measurementFilter.module.scss';
import { Context } from '../../context';

const MeasurementFilter = () => {
  const { t } = useTranslation();
  const { filterPreset, updatePreset } = useContext(Context);
  const { measurement } = filterPreset;

  const handleMeasurementChange = useCallback(
    (measurement: Rating.Order) => {
      updatePreset({ measurement });
    },
    [updatePreset],
  );

  return (
    <Content.Container header={t('leaderboard_measurements')}>
      <Content.Item className={styles.segmentControl}>
        <SegmentControlBar.Container<Rating.Order>
          onChange={handleMeasurementChange}
          darkAppearance
          activeId={measurement}
        >
          <SegmentControlBar.Option id={Rating.Order.Points}>
            {t('common_points')}
          </SegmentControlBar.Option>
          <SegmentControlBar.Option id={Rating.Order.Coins}>
            {t('common_salaries')}
          </SegmentControlBar.Option>
        </SegmentControlBar.Container>
      </Content.Item>
    </Content.Container>
  );
};

export default memo(MeasurementFilter);
