import { createAsyncThunk } from '@reduxjs/toolkit';

import { TournamentsV2 } from 'packages/rest';
import { RootState } from 'app/store';

import { expressSelectors } from '../selectors';

interface Params {
  tournamentId: string | number;
}

type ReturnType = {
  tickets: TournamentsV2.ExpressTicket[];
  offset: number;
  hasNext: boolean;
};

const getTournamentExpress = createAsyncThunk<ReturnType, Params, { state: RootState }>(
  'superExpress/getMoreTickets',
  async ({ tournamentId }, { getState }) => {
    const { offset, limit } = expressSelectors.getStatus(getState());

    const { data } = await TournamentsV2.getTournamentExpress({
      tournamentId,
      limit,
      offset,
    });

    return {
      tickets: data.tickets,
      offset: limit + offset,
      hasNext: data.tickets.length >= limit,
    };
  },
);

export default getTournamentExpress;
