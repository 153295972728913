import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { leaguesThunks } from '../../thunks';
import { metaActions, matchesActions } from '../../actions';
import { LeaguesTypes } from '../../types';

const defaultState: LeaguesTypes.Data = null;

const reducer = createReducer<LeaguesTypes.Data>(defaultState, (builder) => {
  builder
    .addCase(leaguesThunks.loadLeagues.fulfilled, (state, { payload }) => {
      return payload;
    })
    .addMatcher(
      isAnyOf(
        leaguesThunks.loadLeagues.pending,
        matchesActions.cleanMatches,
        metaActions.setActiveSport,
      ),
      () => {
        return defaultState;
      },
    );
});

export default reducer;
