import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import Modal from 'react-modal';
import 'react-toastify/dist/ReactToastify.min.css';

import 'app/config';
import { thunks as configThunks } from 'packages/config';
import { store } from 'app/store';
import 'styles/main.scss';

import App from './app';

Modal.setAppElement('#root');

const { dispatch } = store;

dispatch(async (dispatch) => {
  const container = document.getElementById('root');

  if (!container) {
    throw new Error('Failed to find the root element!');
  }

  await dispatch(configThunks.initApp());
  const root = createRoot(container);

  root.render(
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>,
  );
});
