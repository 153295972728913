import React, { memo } from 'react';
import { OptionProps } from 'react-select';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Icon } from 'packages/shared/components';

import { Option as OptionType } from '../types/interfaces';
import styles from './option.module.scss';

const Option = <V extends string | number, T extends OptionType<V>>({
  innerRef,
  innerProps,
  className,
  data,
  isSelected,
}: OptionProps<T>) => {
  const { t } = useTranslation();
  const { label, className: optionClassName } = data;

  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={classNames(
        styles.option,
        className,
        {
          [styles.selected]: isSelected,
        },
        optionClassName,
      )}
    >
      <Icon className={styles.icon} module="system" id="check" />
      <div className={styles.label}>{t(label)}</div>
    </div>
  );
};

export default memo(Option);
