import React, { FC, memo } from 'react';

import { Avatar } from 'packages/shared/components';
import { Rating } from 'packages/rest';

import styles from './teamCell.module.scss';

type Props = {
  value: Pick<Rating.TeamRating, 'photo' | 'name'>;
};

const TeamCell: FC<Props> = ({ value }) => {
  const { photo, name } = value;

  return (
    <div className={styles.wrapper}>
      <Avatar size="md" src={photo} />
      <div className={styles.name}>{name}</div>
    </div>
  );
};

export default memo(TeamCell);
