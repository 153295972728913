import React, { memo, ReactElement } from 'react';

import classNames from 'classnames';
import styles from './styles.module.scss';

interface Props<T extends number | string> {
  id: T;
  children: string | ReactElement;
  onClick?: () => void;
  counter?: number;
  plain?: boolean;
  marker?: boolean;
  active?: boolean;
  disabled?: boolean;
}

const Option = <T extends number | string>({
  children,
  onClick,
  counter,
  id,
  plain = false,
  marker = false,
  active = false,
  disabled = false,
}: Props<T>) => {
  return (
    <div
      id={`${id}`}
      onClick={!disabled ? onClick : undefined}
      className={classNames(styles.option, {
        [styles.active]: active,
        [styles.plain]: plain,
        [styles.disabled]: disabled,
      })}
    >
      <div className={styles.value}>
        {children}
        {counter !== undefined && <div className={styles.counter}>{counter}</div>}
      </div>
      {marker && <div className={styles.marker} />}
    </div>
  );
};

export default memo(Option);
