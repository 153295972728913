import React, { FC, memo, useContext } from 'react';

import { Modal as ModalWrapper } from 'packages/shared/components';
import { MainMenu } from 'packages/main-menu-ui';
import { Filter, Leaderboard } from 'packages/leaderboard-ui';

import { Modal } from '../../context';
import styles from './styles.module.scss';
import { useModalListener } from './hooks';

const ModalListener: FC = () => {
  const { activeModals, updateActiveModals } = useContext(Modal.Context);
  const { handleMainMenuClose, handleLeaderboardClose, handleLeaderboardFilterClose } =
    useModalListener(updateActiveModals);

  return (
    <>
      <ModalWrapper
        contentClassName={styles.mainMenuContent}
        animation="slideInRight"
        contentYPosition="top"
        contentXPosition="right"
        onCloseModal={handleMainMenuClose}
        isOpen={activeModals.mainMenu}
      >
        <MainMenu />
      </ModalWrapper>

      <ModalWrapper
        animation="slideInBottom"
        contentYPosition="bottom"
        onCloseModal={handleLeaderboardClose}
        isOpen={activeModals.leaderboard}
        contentFullWidth
      >
        <Leaderboard />
      </ModalWrapper>

      <ModalWrapper
        animation="slideInBottom"
        contentYPosition="bottom"
        onCloseModal={handleLeaderboardFilterClose}
        isOpen={activeModals.leaderboardFilter}
        contentFullWidth
      >
        <Filter />
      </ModalWrapper>
    </>
  );
};

export default memo(ModalListener);
