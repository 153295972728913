import { createAsyncThunk } from '@reduxjs/toolkit';

import { Quiz } from 'packages/rest';

import { StatisticsTypes } from '../../types';

const getQuizInfo = createAsyncThunk<StatisticsTypes.Data>('quiz/getQuizInfo', async () => {
  const { data: statisticsData } = await Quiz.getStatistics();
  const { data: activeData } = await Quiz.getActive();

  return { ...statisticsData, ...activeData };
});

export default getQuizInfo;
