import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Modal } from 'app/context';
import { useAppDispatch, useAppSelector } from 'app/store';
import { filterActions, filterConstants, filterSelectors } from 'packages/leaderboard';

import styles from './applyButton.module.scss';
import { useButtonState } from './hooks';
import { Context } from '../../context';

const ApplyButton = () => {
  const { updateActiveModals } = useContext(Modal.Context);
  const { filterPreset, updatePreset } = useContext(Context);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const activeFilter = useAppSelector(filterSelectors.getActiveFilter);
  const { clearable, applicable } = useButtonState({ activeFilter, filterPreset });

  const handleApplyClick = () => {
    updateActiveModals({ leaderboardFilter: false });
    dispatch(filterActions.applyPreset(filterPreset));
  };

  const handleClearClick = () => {
    updatePreset(filterConstants.FILTER_PRESET);
  };

  if (clearable && !applicable) {
    return <span onClick={handleClearClick}>{t('common_clear')}</span>;
  }

  return (
    <span
      className={classNames({ [styles.disabled]: !applicable })}
      onClick={applicable ? handleApplyClick : undefined}
    >
      {t('common_apply')}
    </span>
  );
};

export default memo(ApplyButton);
