import { createSelector } from '@reduxjs/toolkit';

import { ProfileV2 } from 'packages/rest';

import { State } from '../types';
import getState from './getState';

export const getData = createSelector(
  getState,
  (state: State): ProfileV2.GetProfileMenuResponse | undefined => state.data,
);
