import React, { FC, memo, ReactElement, useMemo, useState } from 'react';

import { useAppSelector } from 'app/store';
import { filterSelectors, FilterTypes } from 'packages/leaderboard';

import Context from './Context';

type Props = {
  children: ReactElement[] | ReactElement;
};

const Provider: FC<Props> = ({ children }) => {
  const activeFilter = useAppSelector(filterSelectors.getActiveFilter);
  const [filterPreset, updateFilterPreset] = useState(activeFilter);

  const value = useMemo(
    () => ({
      filterPreset,
      updatePreset: (updates: Partial<FilterTypes.Filter>) => {
        updateFilterPreset((prevState) => ({ ...prevState, ...updates }));
      },
    }),
    [filterPreset, updateFilterPreset],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default memo(Provider);
