import { LocaleCode } from '../index';

export enum OrderType {
  Ascending = 'asc',
  Descending = 'desc',
}

export interface Language {
  id: number;
  localeCode: LocaleCode;
  name: string;
  isRtl: boolean;
  pluralForms: string[];
}
