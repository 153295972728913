import { SelectTypes } from 'packages/shared/components';

import { Sport } from 'packages/types';

import { SettingsTypes } from '../types';

export const DEFAULT_CUSTOM_WINNERS: SettingsTypes.Winner[] = [
  { place: 1, weight: 50, percent: 50 },
  { place: 2, weight: 30, percent: 30 },
  { place: 3, weight: 20, percent: 20 },
];

export const TROPHY_OPTIONS: SelectTypes.Option<SettingsTypes.TrophyType>[] = [
  { label: 'user_tournament_rules_1st_prize_low', value: SettingsTypes.TrophyType.Low },
  {
    label: 'user_tournament_rules_1st_prize_def_minus',
    value: SettingsTypes.TrophyType.DefaultLess,
  },
  { label: 'user_tournament_rules_1st_prize_def', value: SettingsTypes.TrophyType.Default },
  {
    label: 'user_tournament_rules_1st_prize_def_plus',
    value: SettingsTypes.TrophyType.DefaultMore,
  },
  { label: 'user_tournament_rules_1st_prize_high', value: SettingsTypes.TrophyType.High },
];

export const SPORT_LABEL_BY_TYPE = {
  [Sport.Soccer]: 'common_sports_1',
  [Sport.Hockey]: 'common_sports_2',
  [Sport.Basketball]: 'common_sports_5',
  [Sport.Tennis]: 'common_sports_3',
};

export const SPORT_OPTIONS = [
  { id: Sport.Soccer, labelKey: SPORT_LABEL_BY_TYPE[Sport.Soccer] },
  { id: Sport.Hockey, labelKey: SPORT_LABEL_BY_TYPE[Sport.Hockey] },
  {
    id: Sport.Basketball,
    labelKey: SPORT_LABEL_BY_TYPE[Sport.Basketball],
  },
  { id: Sport.Tennis, labelKey: SPORT_LABEL_BY_TYPE[Sport.Tennis] },
];

export const DEFAULT_PRIZE_POOL_BY_TYPE: Record<
  SettingsTypes.PoolType,
  SettingsTypes.PrizePoolData
> = {
  [SettingsTypes.PoolType.TopOne]: {
    poolType: SettingsTypes.PoolType.TopOne,
  },
  [SettingsTypes.PoolType.WinnersPercent]: {
    poolType: SettingsTypes.PoolType.WinnersPercent,
    winnersPercent: {
      percent: 5,
      activeTrophy: TROPHY_OPTIONS[0],
    },
  },
  [SettingsTypes.PoolType.Custom]: {
    poolType: SettingsTypes.PoolType.Custom,
    custom: {
      numberOfWinners: 3,
      winners: DEFAULT_CUSTOM_WINNERS,
      winnersEdited: false,
    },
  },
};
