import { createAsyncThunk } from '@reduxjs/toolkit';

import { AvailablePredictionsTypes, matchesSelectors } from 'packages/tournament-management';
import { Events } from 'packages/rest';
import { RootState } from 'app/store';

import { mapAvailablePredictions } from '../../services';
import { Sport } from '../../../types';

type ReturnType = {
  predictionsBySport: Partial<Record<Sport, AvailablePredictionsTypes.Predictions>>;
  sports: Sport[];
};

const loadPredictions = createAsyncThunk<ReturnType, void, { state: RootState }>(
  'tournamentManagement/loadPredictions',
  async (_, { getState }) => {
    const eventIds = matchesSelectors.getSelectedEventsIds(getState());

    const { data } = await Events.getAvailableMarkets({ eventIds });

    return mapAvailablePredictions(data);
  },
);

export default loadPredictions;
