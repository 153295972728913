import { Cell, HeaderGroup, Hooks, Meta, TableCellProps, TableHeaderProps } from 'react-table';

const useCustomStyling = <D extends object>(hooks: Hooks<D>) => {
  hooks.getHeaderProps.push(getHeaderProps());
  hooks.getCellProps.push(getCellProps());
};

type HeaderMeta<T extends object> = Meta<T, { column: HeaderGroup<T> }>;

const getHeaderProps =
  () =>
  <T extends object>(props: Partial<TableHeaderProps>, meta: HeaderMeta<T>) => {
    const { column } = meta;
    const { headerStyle, headerClassName } = column;

    return [
      props,
      {
        style: headerStyle,
        className: headerClassName,
      },
    ];
  };

type CellMeta<T extends object> = Meta<T, { cell: Cell<T> }>;

const getCellProps =
  () =>
  <T extends object>(props: Partial<TableCellProps>, meta: CellMeta<T>) => {
    const {
      cell: { column },
    } = meta;
    const { cellStyle, cellClassName } = column;

    return [
      props,
      {
        style: cellStyle,
        className: cellClassName,
      },
    ];
  };

useCustomStyling.pluginName = 'useCustomStyling';

export default useCustomStyling;
