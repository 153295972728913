export enum QuestionStage {
  Inactive = 'inactive',
  Active = 'active',
  Paused = 'paused',
  Correct = 'correct',
  Incorrect = 'incorrect',
}

export enum QuestionType {
  Default = 'default',
  Bofp = 'bofp',
}

export interface AnswerVariant {
  id: number;
  description: string;
}

export interface Question {
  stage: QuestionStage;
  type: QuestionType;
  description: string;
  points: number;
  variants: AnswerVariant[];
  id: number;
  img?: string;
  selectedVariantId?: AnswerVariant['id'];
  rightVariantId?: AnswerVariant['id'];
}

export interface Data {
  endDate?: string;
  startDate?: string;
  questions?: Question[];
  activeQuestion?: Question;
  lastQuestionId?: number;
}

export interface Status {
  isQuestionsLoading: boolean;
  isAnswerLoading: boolean;
  errorMessage?: string;
}

export interface State {
  data: Data;
  status: Status;
}
