import React, { FC, memo, ReactElement } from 'react';
import classNames from 'classnames';

import { Spinner } from 'packages/shared/components';

import styles from './styles.module.scss';

type Props = {
  children: ReactElement | ReactElement[];
  className?: string;
  isLoading?: boolean;
  isContentVisible?: boolean;
  fullSize?: boolean;
};

const LoaderContainer: FC<Props> = ({
  children,
  className,
  isLoading = false,
  isContentVisible = false,
  fullSize = false,
}) => {
  if (isLoading) {
    return (
      <div className={classNames(styles.container, { [styles.fullSize]: fullSize }, className)}>
        {isLoading && (
          <div className={styles.spinnerWrapper}>
            <Spinner className={styles.loaderIcon} />
          </div>
        )}

        {isContentVisible && children}
      </div>
    );
  }

  return <>{children}</>;
};

export default memo(LoaderContainer);
