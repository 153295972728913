import React, { FC, memo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'app/store';
import { lineUpSelectors } from 'packages/leaderboard';

import styles from './detailsHeader.module.scss';

type Props = {
  className?: string;
};

const DetailsHeader: FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const {
    topPlayersCount = 0,
    topPlayersPoints = 0,
    benchPlayersPoints = 0,
  } = useAppSelector(lineUpSelectors.getDetails) || {};

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.row}>
        <div>
          {t('common_tems_points')}{' '}
          <span className={styles.top}>
            {' '}
            • {t('common_count_top_players', { count: topPlayersCount })}
          </span>
        </div>
        <div>{topPlayersPoints}</div>
      </div>
      <div className={styles.delimiter} />
      <div className={styles.row}>
        <div>{t('common_team_other_total_points')}</div>
        <div>{benchPlayersPoints}</div>
      </div>
    </div>
  );
};

export default memo(DetailsHeader);
