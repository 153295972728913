import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { matchesActions } from '../../actions';
import { matchesThunks } from '../../thunks';
import { MatchesTypes } from '../../types';

const defaultState: MatchesTypes.Status = {
  isInitialEventsLoading: true,
  isMoreEventsLoading: false,
  offset: 0,
  limit: 10,
  hasNext: true,
};

const reducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(matchesThunks.loadInitialMatches.fulfilled, (state, { payload }) => {
      const { offset } = payload;

      state.offset = offset;
      state.isInitialEventsLoading = false;
    })
    .addCase(matchesThunks.loadInitialFilteredMatches.fulfilled, (state, { payload }) => {
      const { offset, hasNext } = payload;

      state.offset = offset;
      state.hasNext = hasNext;
      state.isInitialEventsLoading = false;
    })
    .addMatcher(
      isAnyOf(matchesThunks.loadMoreMatches.pending, matchesThunks.loadMoreFilteredMatches.pending),
      (state) => {
        state.isMoreEventsLoading = true;
      },
    )
    .addMatcher(
      isAnyOf(
        matchesThunks.loadMoreMatches.fulfilled,
        matchesThunks.loadMoreFilteredMatches.fulfilled,
      ),
      (state, { payload }) => {
        const { offset, hasNext } = payload;

        state.offset = offset;
        state.hasNext = hasNext;
        state.isMoreEventsLoading = false;
      },
    )
    .addMatcher(
      isAnyOf(
        matchesThunks.loadMoreMatches.rejected,
        matchesThunks.loadMoreFilteredMatches.rejected,
      ),
      (state) => {
        state.isMoreEventsLoading = false;
      },
    )
    .addMatcher(
      isAnyOf(
        matchesThunks.loadInitialMatches.rejected,
        matchesThunks.loadInitialFilteredMatches.rejected,
      ),
      () => {
        return { ...defaultState, isInitialEventsLoading: false };
      },
    )
    .addMatcher(
      isAnyOf(
        matchesActions.cleanMatches,
        matchesThunks.loadInitialMatches.pending,
        matchesThunks.loadInitialFilteredMatches.pending,
      ),
      () => {
        return defaultState;
      },
    );
});

export default reducer;
