import { createSelector } from '@reduxjs/toolkit';

import { Rating } from 'packages/rest';

import { RatingTypes } from '../../types';
import getState from './getState';

export const getData = createSelector(
  getState,
  (state: RatingTypes.State): RatingTypes.Data => state.data,
);

export const getRating = createSelector(
  getData,
  (state: RatingTypes.Data): Rating.TeamRating[] | Rating.PlayerRating[] | undefined =>
    state.rating,
);

export const getUserTeam = createSelector(
  getData,
  (state: RatingTypes.Data): Rating.CurrentUserTeam | undefined => state.userTeam,
);

export const getCurrentUser = createSelector(
  getData,
  (state: RatingTypes.Data): Rating.CurrentUser | undefined => state.user,
);
