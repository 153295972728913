import { createContext } from 'react';

import { DEFAULT_ACTIVE_MODALS } from './constants';
import { ActiveModal } from './types';

export const initialThemeState = {
  activeModals: DEFAULT_ACTIVE_MODALS,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateActiveModals: (modals: Partial<ActiveModal>) => {},
};

const Context = createContext(initialThemeState);

export default Context;
