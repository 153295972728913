import { ChangeEvent, useEffect, useState } from 'react';

interface Params<T extends HTMLInputElement | HTMLTextAreaElement> {
  initialValue: string | number;
  onChange?: (e: ChangeEvent<T>) => void;
}

const useValueState = <T extends HTMLInputElement | HTMLTextAreaElement>({
  initialValue,
  onChange,
}: Params<T>): [string | number, (e: ChangeEvent<T>) => void] => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (e: ChangeEvent<T>) => {
    const { value } = e.target;

    setValue(value);
    onChange?.(e);
  };

  return [value, handleChange];
};

export default useValueState;
