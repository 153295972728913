import { KeyValueDatabase, Table } from './types';

class KeyValueDatabaseInstance implements KeyValueDatabase {
  storage: KeyValueDatabase | undefined;

  tables: Table;

  constructor() {
    this.storage = undefined;
    this.tables = {};
  }

  init(storage: KeyValueDatabase, tables = {}) {
    this.storage = storage;
    this.tables = tables;
  }

  set(key: string, value: any, tableName?: string) {
    return this.storage ? this.storage.set(key, value, tableName) : reject();
  }

  get(key: string, tableName?: string) {
    return this.storage ? this.storage.get(key, tableName) : reject();
  }

  remove(key: string, tableName?: string) {
    return this.storage ? this.storage.remove(key, tableName) : reject();
  }

  clearTable(tableName?: string) {
    return this.storage ? this.storage.clearTable(tableName) : reject();
  }

  getAll(tableName?: string) {
    return this.storage ? this.storage.getAll(tableName) : reject();
  }

  getStorageInstance() {
    return this.storage ? this.storage.getStorageInstance() : reject();
  }
}

const reject = () => Promise.reject(new Error('Storage: instance not initialized.'));

const keyValueDatabaseInstance = new KeyValueDatabaseInstance();

export default keyValueDatabaseInstance;
