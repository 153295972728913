import { Row } from 'react-table';

import { Rating } from 'packages/rest';
import { DetailsTypes } from 'packages/leaderboard';

type Params = {
  onClick: (params: Omit<DetailsTypes.Data, 'details'>) => void;
  userId?: number;
};

const getPlayerRowProps = ({ onClick, userId }: Params) => {
  return ({ original }: Row<Rating.PlayerRating>) => {
    const { firstName, lastName, photo, id, points } = original;
    const name = `${firstName}${lastName}`;

    return {
      highlighted: userId === id,
      onClick: () => onClick({ name, id, photo, points }),
    };
  };
};

export default getPlayerRowProps;
