import React, { FC, memo, ReactElement } from 'react';
import classNames from 'classnames';

import { Icon } from 'packages/shared/components';

import styles from './styles.module.scss';

type Props = {
  topContent: ReactElement | string | number;
  bottomContent: ReactElement | string | number;
  onClick?: () => void;
  className?: string;
  chevronVisible?: boolean;
};

const Item: FC<Props> = ({
  topContent,
  bottomContent,
  onClick,
  className,
  chevronVisible = true,
}) => {
  return (
    <div onClick={onClick} className={classNames(styles.item, className)}>
      <div className={styles.value}>{topContent}</div>
      <div className={styles.label}>
        {bottomContent}{' '}
        {chevronVisible && <Icon className={styles.icon} id="chevronRight" module="system" />}
      </div>
    </div>
  );
};
export default memo(Item);
