import insertMarketCodesIntoLastSelectedPosition from './insertMarketCodesIntoLastSelectedPosition';
import flushMarketCodes from './flushMarketCodes';
import { AvailablePredictionsTypes } from '../../types';

interface Params {
  eventIds: number[];
  cleanMarketCodes: AvailablePredictionsTypes.CleanMarketCode[];
  marketCodesWithConflict: AvailablePredictionsTypes.MarketCodeWithConflicts[];
  selectedMarketCodes: AvailablePredictionsTypes.CleanMarketCode[];
}

const resolveConflicts = ({
  marketCodesWithConflict,
  eventIds,
  cleanMarketCodes,
  selectedMarketCodes = [],
}: Params) => {
  const { flushedCleanMarketCodes, flushedMarketCodesWithConflict } = flushMarketCodes({
    marketCodesWithConflict,
    eventIds,
  });
  const mergedCleanMarketCodes = insertMarketCodesIntoLastSelectedPosition(
    cleanMarketCodes,
    flushedCleanMarketCodes,
    selectedMarketCodes,
  );

  return {
    selectedMarketCodes: [...selectedMarketCodes, ...flushedCleanMarketCodes],
    cleanMarketCodes: mergedCleanMarketCodes,
    marketCodesWithConflict: flushedMarketCodesWithConflict,
  };
};

export default resolveConflicts;
