import React, { FC, memo } from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';

import { NavigationLayout } from 'layouts';

import {
  Home,
  NotFound,
  Profile,
  ProfileUserSearch,
  SignIn,
  UserProfile,
  UserProfileUserSearch,
  CreateTournament,
  SuperExpress,
  QuizHome,
  QuizLeaderboard,
  QuizGame,
  TournamentsList,
} from 'pages';

import { ProtectedRoute } from './components';
import { PROFILE, QUIZ, ROOT, USER } from './constants';
import { Page } from './types';

const Router: FC = () => {
  return (
    <Routes>
      <Route
        path={ROOT[Page.Home]}
        element={
          <ProtectedRoute>
            <NavigationLayout>
              <Home />
            </NavigationLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path={ROOT[Page.SignIn]}
        element={
          <ProtectedRoute availableFor="guest">
            <SignIn />
          </ProtectedRoute>
        }
      />
      <Route
        path={ROOT[Page.CreateTournament]}
        element={
          <ProtectedRoute>
            <CreateTournament />
          </ProtectedRoute>
        }
      />
      <Route
        path={ROOT[Page.Profile]}
        element={
          <ProtectedRoute>
            <NavigationLayout>
              <Outlet />
            </NavigationLayout>
          </ProtectedRoute>
        }
      >
        <Route path={PROFILE[Page.OwnProfile]} element={<Profile />} />
        <Route path={PROFILE[Page.ProfileUserSearch]} element={<ProfileUserSearch />} />
      </Route>
      <Route
        path={ROOT[Page.User]}
        element={
          <ProtectedRoute>
            <NavigationLayout>
              <Outlet />
            </NavigationLayout>
          </ProtectedRoute>
        }
      >
        <Route path={USER[Page.UserProfile]} element={<UserProfile />} />
        <Route path={USER[Page.UserSearch]} element={<UserProfileUserSearch />} />
      </Route>
      <Route
        path={ROOT[Page.SuperExpress]}
        element={
          <ProtectedRoute>
            <NavigationLayout>
              <SuperExpress />
            </NavigationLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path={ROOT[Page.Quiz]}
        element={
          <ProtectedRoute>
            <Outlet />
          </ProtectedRoute>
        }
      >
        <Route
          path={QUIZ[Page.QuizHome]}
          element={
            <NavigationLayout>
              <QuizHome />
            </NavigationLayout>
          }
        />
        <Route path={QUIZ[Page.QuizGame]} element={<QuizGame />} />
        <Route
          path={QUIZ[Page.QuizLeaderboard]}
          element={
            <NavigationLayout>
              <QuizLeaderboard />
            </NavigationLayout>
          }
        />
      </Route>
      <Route
        path={ROOT[Page.Tournaments]}
        element={
          <NavigationLayout>
            <TournamentsList />
          </NavigationLayout>
        }
      ></Route>
      <Route path={ROOT[Page.NotFound]} element={<NotFound />} />
    </Routes>
  );
};

export default memo(Router);
