import { createSelector } from '@reduxjs/toolkit';

import { Friends } from 'packages/rest';

import { Data, State } from '../types';
import getState from './getState';

const getData = createSelector(getState, (state: State): Data => state.data);

export const getResults = createSelector(
  getData,
  (data: Data): Friends.SearchResponse[] | undefined => data.results,
);

export const getFilteredResults = createSelector(
  getData,
  (data: Data): Friends.SearchResponse[] | undefined => data.filteredResults,
);
