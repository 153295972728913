import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';

import { GetProfileResponse } from '../types';

const getProfile = (): Promise<AxiosResponse<GetProfileResponse>> => {
  return authInstance({ method: 'get', url: '/v2/profile/' });
};

export default getProfile;
