import { createSelector } from '@reduxjs/toolkit';

import { ProfileV2 } from 'packages/rest';

import { State } from '../types';
import getState from './getState';

export const getData = createSelector(
  getState,
  (state: State): ProfileV2.GetProfileResponse | undefined => state.data,
);

export const getPhoto = createSelector(
  getState,
  (state: State): string | undefined => state.data?.user.photo,
);

export const getBalance = createSelector(getData, (data?: ProfileV2.GetProfileResponse): number =>
  Number(data?.user.balance || '0'),
);
