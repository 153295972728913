export interface KeyValueStorage {
  storage: any;
  set<DataType>(key: string, value: DataType): void;
  get<DataType>(key: string): DataType;
  remove(key: string): any;
}

export interface KeyValueDatabase {
  storage: any;
  tables?: Table;
  set(key: string, value: any, tableName?: string): Promise<any>;
  get(key: string, tableName?: string): Promise<any>;
  getAll(tableName?: string): Promise<any>;
  remove(key: string, tableName?: string): Promise<any>;
  clearTable(tableName?: string): Promise<any>;
  getStorageInstance(): any;
}

export enum StorageKey {
  GlobalTheme = 'globalTheme',
  AppIdentifier = 'appIdentifier',
  LocaleCode = 'localeCode',
  AccessToken = 'accessToken',
  RefreshToken = 'refreshToken',
}

export type Table = Record<string, string>;
