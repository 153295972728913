import { StylesConfig } from 'react-select/dist/declarations/src/styles';
import { GroupBase } from 'react-select/dist/declarations/src/types';

import { Option as OptionType } from '../types';
import * as components from '../components';

const useSelectProps = <V extends string | number, T extends OptionType<V>>() => {
  const styles: StylesConfig<T, false, GroupBase<T>> = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  return { components, styles };
};

export default useSelectProps;
