import { t } from 'i18next';

import { Rating } from 'packages/rest';

import { FilterTypes } from '../types';
import { getMonthOptions, getWeekOptions, getYearOptions } from '../services';
import { Sport } from '../../types';

export const SPORT_OPTIONS = [
  { value: FilterTypes.SportId.All, id: FilterTypes.SportId.All, label: t('common_all_sports') },
  { value: Sport.Soccer, id: Sport.Soccer, label: t('common_sports_1') },
  { value: Sport.Hockey, id: Sport.Hockey, label: t('common_sports_2') },
  { value: Sport.Basketball, id: Sport.Basketball, label: t('common_sports_5') },
  { value: Sport.Tennis, id: Sport.Tennis, label: t('common_sports_3') },
];

export const DATE_RANGE_OPTIONS: FilterTypes.DateRangeOptions = {
  week: getWeekOptions(),
  month: getMonthOptions(),
  year: getYearOptions(),
};

export const ACTIVE_FILTER: FilterTypes.Filter = {
  dateType: Rating.DateType.Week,
  unit: Rating.UnitType.Players,
  onlyFriends: false,
  measurement: Rating.Order.Points,
  dateOffset: DATE_RANGE_OPTIONS.week[0],
  sport: SPORT_OPTIONS[0],
};

export const FILTER_PRESET = ACTIVE_FILTER;
