import dayjs from 'dayjs';
import { t } from 'i18next';

import { DateUtils } from 'app/utils';

import { FilterTypes } from '../types';

const getWeekOptions = (): FilterTypes.DateOffsetOption[] => {
  const currentWeek = dayjs().week();
  const previousWeek = dayjs().subtract(1, 'week').week();

  return DateUtils.getPeriods(8, 'week').map(({ start, end, offset }, index) => {
    if (start.week() === currentWeek) {
      return { value: offset, id: `week-${index}`, label: t('common_date_this_week') };
    }

    if (start.week() === previousWeek) {
      return { value: offset, id: `week-${index}`, label: t('common_date_last_week') };
    }

    if (start.month() !== end.month()) {
      return {
        value: offset,
        id: `week-${index}`,
        label: `${start.format('D MMM')} - ${end.format('D MMM')} `,
      };
    }

    return {
      value: offset,
      id: `week-${index}`,
      label: `${start.date()} - ${end.format('D MMM')}`,
    };
  });
};

export default getWeekOptions;
