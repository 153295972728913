import React, { DetailedHTMLProps, FC, memo, useRef } from 'react';
import classNames from 'classnames';

import commonStyles from '../../styles.module.scss';
import styles from './styles.module.scss';
import { useValueState } from '../../hooks';
import { useAutosize } from './hooks';

interface Props
  extends DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  size?: 'sm' | 'md' | 'lg';
  value?: string | number;
  rows?: number;
}

const TextArea: FC<Props> = ({
  className,
  size = 'md',
  value: initialValue = '',
  onChange,
  ...inputProps
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [value, setValue] = useValueState<HTMLTextAreaElement>({ initialValue, onChange });

  useAutosize(textAreaRef.current, value);

  return (
    <textarea
      value={value}
      ref={textAreaRef}
      onChange={setValue}
      className={classNames(commonStyles.input, commonStyles[size], styles.textArea, className)}
      {...inputProps}
    />
  );
};

export default memo(TextArea);
