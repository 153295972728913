import { createReducer } from '@reduxjs/toolkit';

import { Rating } from 'packages/rest';

import { getInitialRating, getMoreRating } from '../../thunks';
import { RatingTypes } from '../../types';
import { commonActions } from '../../actions';
import { isPlayerRating } from '../../services';

const defaultState: RatingTypes.Data = {};

const reducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(getInitialRating.fulfilled, (state, { payload }) => {
      const { rating, userTeam, user } = payload;

      return { rating, userTeam, user };
    })
    .addCase(getMoreRating.fulfilled, (state, { payload }) => {
      const { rating } = payload;
      const { rating: stateRating = [] } = state;

      if (isPlayerRating(rating)) {
        return { ...state, rating: [...(stateRating as Rating.PlayerRating[]), ...rating] };
      }

      return { ...state, rating: [...(stateRating as Rating.TeamRating[]), ...rating] };
    })
    .addCase(commonActions.cleanupState, () => {
      return defaultState;
    });
});

export default reducer;
