import { useMemo } from 'react';

import { filterConstants, FilterTypes } from 'packages/leaderboard';

import { getChangedFilterItems } from '../../../../../services';

type Params = {
  activeFilter: FilterTypes.Filter;
  filterPreset: FilterTypes.Filter;
};

const useButtonState = ({ activeFilter, filterPreset }: Params) => {
  return useMemo(
    () => ({
      clearable: getChangedFilterItems({
        selected: activeFilter,
        initial: filterConstants.FILTER_PRESET,
      }),
      applicable: getChangedFilterItems({
        selected: filterPreset,
        initial: activeFilter,
      }),
    }),
    [activeFilter, filterPreset],
  );
};

export default useButtonState;
