import { Page } from '../types';

export const ROOT = {
  [Page.Home]: '/',
  [Page.SignIn]: '/sign-in',
  [Page.Profile]: '/profile',
  [Page.User]: '/user',
  [Page.CreateTournament]: '/create-tournament',
  [Page.Tournaments]: '/tournaments',
  [Page.SuperExpress]: '/super-express/:tournamentId',
  [Page.Quiz]: '/quiz',
  [Page.NotFound]: '*',
};

export const PROFILE = {
  [Page.OwnProfile]: '',
  [Page.ProfileUserSearch]: 'user-search/:type',
};

export const USER = {
  [Page.UserProfile]: ':id',
  [Page.UserSearch]: ':id/user-search/:type',
};

export const QUIZ = {
  [Page.QuizHome]: '',
  [Page.QuizGame]: ':id',
  [Page.QuizLeaderboard]: 'leaderboard',
};
