import { createSelector } from '@reduxjs/toolkit';

import { Sport } from 'packages/types';
import { Events } from 'packages/rest';

import { MatchesTypes } from '../../types';
import getState from './getState';

export const getData = createSelector(
  getState,
  (state: MatchesTypes.State): MatchesTypes.Data => state.data,
);

export const getSelectedEventsMapBySport = createSelector(
  getData,
  (
    data: MatchesTypes.Data,
  ): Partial<Record<Sport, Record<number, Events.LeagueEventShortInfoSchema>>> => {
    return data.selectedEventsMapBySport;
  },
);

export const getSelectedEventsCountBySport = createSelector(
  getSelectedEventsMapBySport,
  (selectedEventsMapBySport): Record<string, number> => {
    return Object.entries(selectedEventsMapBySport).reduce((countBySport, [sport, events]) => {
      countBySport[sport] = Object.keys(events).length;

      return countBySport;
    }, <Record<string, number>>{});
  },
);

export const getSelectedEventsIds = createSelector(
  getSelectedEventsMapBySport,
  (selectedEventsMapBySport): number[] => {
    return Object.values(selectedEventsMapBySport)
      .map((eventsMap) => Object.values(eventsMap).map(({ id }) => id))
      .flat();
  },
);

export const getSelectedEventsCount = createSelector(
  getSelectedEventsMapBySport,
  (selectedEventsMapBySport): number => {
    return Object.values(selectedEventsMapBySport).reduce(
      (count, eventIds) => count + Object.keys(eventIds).length,
      0,
    );
  },
);

export const getFilteredEvents = createSelector(
  getData,
  (data: MatchesTypes.Data): [string, Events.LeagueEventShortInfoSchema[]][] => {
    return data.filteredEvents;
  },
);
