import React, { memo, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Currency, Icon } from 'packages/shared/components';
import { selectors } from 'packages/profile';
import { useAppSelector } from 'app/store';
import { Modal } from 'app/context';

import styles from './styles.module.scss';
import { LINKS } from './constants';

const Footer = () => {
  const { activeModals, updateActiveModals } = useContext(Modal.Context);
  const { t } = useTranslation();

  const balance = useAppSelector(selectors.getBalance);

  return (
    <footer className={styles.footer}>
      {LINKS.map(({ icon, to, title, activeIcon }) => (
        <NavLink
          key={to}
          className={({ isActive }) => classNames(styles.item, { [styles.active]: isActive })}
          to={to}
        >
          <Icon className={styles.activeIcon} module={activeIcon.module} id={activeIcon.id} />
          <Icon className={styles.icon} module={icon.module} id={icon.id} />
          <span>{t(title)}</span>
        </NavLink>
      ))}

      <div
        onClick={() => updateActiveModals({ mainMenu: true })}
        className={classNames(styles.item, { [styles.active]: activeModals.mainMenu })}
      >
        <Icon module="tabbar" id="menu" />
        <Currency amount={balance} />
      </div>
    </footer>
  );
};

export default memo(Footer);
