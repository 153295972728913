import { useCallback, useEffect, useRef, useState } from 'react';

interface Params {
  isOpen?: boolean;
  changeIsOpen?: (isOpen: boolean) => void;
}

const useGroup = ({ isOpen, changeIsOpen }: Params) => {
  const [height, setHeight] = useState(0);
  const summaryRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current && summaryRef.current) {
      if (isOpen) {
        setHeight(contentRef.current.clientHeight + summaryRef.current.clientHeight);
      } else {
        setHeight(summaryRef.current.clientHeight || 0);
      }
    }
  }, [isOpen]);

  const handleSummaryClick = useCallback(() => {
    changeIsOpen?.(!isOpen);
  }, [changeIsOpen, isOpen]);

  const handleContentResize = useCallback(
    (height: number) => {
      if (summaryRef.current && isOpen) {
        setHeight(summaryRef.current.clientHeight + height);
      }
    },
    [isOpen],
  );

  return {
    isOpen,
    height,
    summaryRef,
    contentRef,
    handlers: { handleSummaryClick, handleContentResize },
  };
};

export default useGroup;
