import React, { FC, memo } from 'react';
import classNames from 'classnames';

import { usePointer } from './hooks';
import { ScrollPointerVerticalAlignment } from '../../types';
import styles from './styles.module.scss';

type Props = {
  verticalAlignment?: keyof typeof ScrollPointerVerticalAlignment;
  className?: string;
};

const SlideInPointer: FC<Props> = ({ verticalAlignment = '', className }) => {
  const elementRef = usePointer();

  return (
    <div
      className={classNames(styles.pointer, styles[verticalAlignment], className)}
      ref={elementRef}
    />
  );
};

export default memo(SlideInPointer);
