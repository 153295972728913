import { createAsyncThunk } from '@reduxjs/toolkit';

import { Quiz } from 'packages/rest';
import { RootState } from 'app/store';

import { QuestionsTypes } from '../../types';
import { questionsSelectors } from '../../selectors';
import { updateActiveQuestionInQuiz } from '../../services';
import { questionsActions } from '../../actions';

type ReturnType = {
  activeQuestion?: QuestionsTypes.Question;
  questions: QuestionsTypes.Question[];
};

const getAnswer = createAsyncThunk<ReturnType, Quiz.GetQuestionAnswerRequest, { state: RootState }>(
  'quiz/getAnswer',
  async (params, { getState, dispatch, rejectWithValue }) => {
    dispatch(
      questionsActions.setActiveQuestionTimerStage({ stage: QuestionsTypes.QuestionStage.Paused }),
    );

    try {
      const { data } = await Quiz.makeAnswerPolling(params);

      const { answerId } = params;
      const { isCorrect, correctAnswerId, earnedPoints } = data;
      const { activeQuestion, questions: quiz = [] } = questionsSelectors.getData(getState());

      if (activeQuestion) {
        const updatedActiveQuestion = {
          ...activeQuestion,
          stage: isCorrect
            ? QuestionsTypes.QuestionStage.Correct
            : QuestionsTypes.QuestionStage.Incorrect,
          selectedVariantId: answerId,
          rightVariantId: correctAnswerId,
          points: isCorrect ? earnedPoints : 0,
        };

        return {
          activeQuestion: updatedActiveQuestion,
          questions: updateActiveQuestionInQuiz({ quiz, activeQuestion: updatedActiveQuestion }),
        };
      }

      return {
        questions: [...quiz],
      };
    } catch (error) {
      return rejectWithValue(error?.response?.data?.error);
    }
  },
);

export default getAnswer;
