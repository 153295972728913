import React from 'react';
import { Column } from 'react-table';
import { t } from 'i18next';

import { Rating } from 'packages/rest';
import { UserGroup } from 'packages/rest/types';
import { UserBasePreview } from 'packages/shared/components';

import PlayerPlaceCell from '../PlayerPlaceCell';

type Params = {
  measurement: Rating.Order;
  onPlayerClick: (id: number) => void;
};

type UserBasePreviewProps = {
  firstName: string;
  lastName: string;
  id: number;
  groups: UserGroup[];
  onClick: (id: number) => void;
  photo?: string;
  teamName?: string;
};

const getPlayerRatingSchema = ({
  measurement,
  onPlayerClick,
}: Params): Column<Rating.PlayerRating>[] => {
  return [
    {
      id: 'place',
      Header: () => t('common_place'),
      accessor: ({ position, id }) => ({ position, id, onClick: onPlayerClick }),
      cellStyle: { maxWidth: '37px' },
      headerStyle: { maxWidth: '37px' },
      Cell: PlayerPlaceCell,
    },
    {
      id: 'player',
      Header: () => t('common_player'),
      accessor: ({ firstName, lastName, photo, groups, teamName, id }): UserBasePreviewProps => ({
        firstName,
        lastName,
        photo,
        groups,
        teamName: teamName || '',
        id,
        onClick: onPlayerClick,
      }),
      Cell: ({ value: props }: { value: UserBasePreviewProps }) =>
        React.createElement(UserBasePreview, props),
    },
    {
      id: 'points',
      Header: () => t(measurement === Rating.Order.Points ? 'common_pts' : 'common_text_coins'),
      accessor: measurement === Rating.Order.Points ? 'points' : 'coins',
      cellStyle: { minWidth: '25px', maxWidth: '70px', textAlign: 'right' },
      headerStyle: { minWidth: '25px', maxWidth: '70px', textAlign: 'right' },
    },
  ];
};

export default getPlayerRatingSchema;
