import { createAsyncThunk } from '@reduxjs/toolkit';

import { QuestionsInfoSchema } from 'packages/rest/types';
import { Events } from 'packages/rest';

type ReturnType = QuestionsInfoSchema[];

interface Params {
  sportId: string | number;
}

const loadPredictions = createAsyncThunk<ReturnType, Params>(
  'tournamentManagement/loadPredictions',
  async ({ sportId }) => {
    const { data: predictions } = await Events.getQuestions({
      sportId,
    });

    return predictions;
  },
);

export default loadPredictions;
