import React, { FC, memo, useMemo } from 'react';
import numeral from 'numeral';
import classNames from 'classnames';

const CURRENCY_FORMAT = '0,0,0000 $';

type Props = {
  amount: number;
  className?: string;
  format?: string;
};

const Currency: FC<Props> = ({ amount, className, format = CURRENCY_FORMAT }) => {
  const formattedAmount = useMemo(() => numeral(amount).format(format), [amount, format]);

  return <span className={classNames(className)}>{formattedAmount}</span>;
};

export default memo(Currency);
