import { createAsyncThunk } from '@reduxjs/toolkit';

import { Tournaments } from 'packages/rest';
import { actions as profileActions } from 'packages/profile';
import { RootState } from 'app/store';

import { expressSelectors } from '../selectors';
import { mapPredictionToTicket } from '../services';
import { addTicketToList, allowToLoadMoreTickets } from '../actions';

interface Params extends Tournaments.MakePredictionRequest {
  bid: number;
  variants: number;
}

const makePrediction = createAsyncThunk<void, Params, { state: RootState }>(
  'superExpress/makePrediction',
  async (params, { dispatch, getState }) => {
    const { offset: displayedTicketsCount } = expressSelectors.getStatus(getState());
    const loadedTickets = expressSelectors.getTickets(getState());
    const { bid, predictions, variants } = params;

    const { data } = await Tournaments.makePrediction(params);

    if (displayedTicketsCount > loadedTickets.length) {
      const newTicket = mapPredictionToTicket({ predictions, variants, set: data.setNumber });

      dispatch(addTicketToList(newTicket));
    } else {
      dispatch(allowToLoadMoreTickets());
    }

    dispatch(profileActions.recalculateBalance({ amount: bid, operation: 'sub' }));
  },
);

export default makePrediction;
