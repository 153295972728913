import React, { memo, Children, ReactElement, cloneElement } from 'react';
import classNames from 'classnames';

import { useActiveButtons } from './hooks';
import Button from '../Button';
import styles from './styles.module.scss';

interface Props<T extends string | number> {
  children:
    | ReactElement<Parameters<typeof Button<T>>[0]>
    | ReactElement<Parameters<typeof Button<T>>[0]>[];
  onChange?: (activeButtons: T[]) => void;
  isMultiple?: boolean;
  className?: string;
}

const ButtonSelect = <T extends string | number>({
  children,
  isMultiple = false,
  onChange,
  className,
}: Props<T>) => {
  const childrenArray = Children.toArray(children) as ReactElement<
    Parameters<typeof Button<T>>[0]
  >[];

  const [activeButtons, handleButtonClick] = useActiveButtons<T>({
    children: childrenArray,
    isMultiple,
    onChange,
  });

  return (
    <div className={classNames(styles.selectContainer, className)}>
      {Children.map(childrenArray, (child) => {
        const { id, onClick } = child.props;

        const handleClick = () => {
          handleButtonClick(id);
          onClick?.();
        };

        return cloneElement(child, { onClick: handleClick, active: activeButtons.includes(id) });
      })}
    </div>
  );
};

export default memo(ButtonSelect);
