import { createAsyncThunk } from '@reduxjs/toolkit';

import { Tournaments } from 'packages/rest';
import { RootState } from 'app/store';

import { InvitationsTypes } from '../../types';
import { settingsSelectors } from '../../selectors';
import { invitationsConstants } from '../../constants';
import { mapInvitations } from '../../services';

type Params =
  | {
      searchKey?: string;
    }
  | undefined;

interface ReturnType {
  invitations: InvitationsTypes.Invitation[];
  offset: number;
  hasNext: boolean;
}

const loadInitialInvitations = createAsyncThunk<ReturnType, Params, { state: RootState }>(
  'tournamentManagement/loadInitialInvitations',
  async ({ searchKey } = {}, { getState }) => {
    const { type } = settingsSelectors.getData(getState());
    const offset = invitationsConstants.INITIAL_OFFSET;
    const limit = invitationsConstants.INITIAL_LIMIT;

    const { data } = await Tournaments.getInvitations({
      search: searchKey,
      type,
      offset,
      limit,
    });

    return {
      invitations: mapInvitations(data),
      offset: offset + limit,
      hasNext: offset < data.length,
    };
  },
);

export default loadInitialInvitations;
