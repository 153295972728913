import { createReducer } from '@reduxjs/toolkit';
import { t } from 'i18next';

import { allowToLoadMoreTickets, cleanupState } from '../../actions';
import { getTournamentExpress, getMoreTickets } from '../../thunks';
import { ExpressTypes } from '../../types';

const defaultState: ExpressTypes.Status = {
  limit: 10,
  offset: 0,
  hasNext: false,
  isInitialLoading: true,
  isMoreLoading: false,
  errorMessage: undefined,
};

const expressReducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(getTournamentExpress.pending, (state) => {
      return { ...state, isInitialLoading: true, errorMessage: undefined };
    })
    .addCase(getTournamentExpress.fulfilled, (state, { payload }) => {
      const { offset, hasNext } = payload;

      return { ...state, isInitialLoading: false, offset, hasNext };
    })
    .addCase(getTournamentExpress.rejected, (state) => {
      return {
        ...state,
        isInitialLoading: false,
        errorMessage: t('superexpress_load_express_error'),
      };
    })
    .addCase(getMoreTickets.pending, (state) => {
      return { ...state, isMoreLoading: true };
    })
    .addCase(getMoreTickets.fulfilled, (state, { payload }) => {
      const { offset, hasNext } = payload;

      return { ...state, isMoreLoading: false, offset, hasNext };
    })
    .addCase(getMoreTickets.rejected, (state) => {
      return { ...state, isMoreLoading: false };
    })
    .addCase(allowToLoadMoreTickets, (state) => {
      state.hasNext = true;
    })
    .addCase(cleanupState, () => {
      return defaultState;
    });
});

export default expressReducer;
