import { combineReducers } from '@reduxjs/toolkit';

import rating from './rating';
import filter from './filter';
import details from './details';
import lineUp from './lineUp';

const reducer = combineReducers({ rating, filter, details, lineUp });

export default reducer;
