import React, { cloneElement, FC, memo, ReactElement, useMemo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import styles from './styles.module.scss';

type Props = {
  label: string;
  iconElement?: ReactElement;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
};

const MenuLink: FC<Props> = ({ iconElement, label, className, onClick }) => {
  const icon = useMemo(
    () => (iconElement ? cloneElement(iconElement, { className: styles.icon }) : undefined),
    [iconElement],
  );

  return (
    <Link onClick={onClick} className={classNames(styles.wrapper, className)} to="/">
      {!!icon && icon} <span className={styles.label}> {label}</span>
    </Link>
  );
};

export default memo(MenuLink);
