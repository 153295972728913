import React, { FC, memo, ReactElement } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

type Props = {
  children: ReactElement | ReactElement[];
  className?: string;
};

const Container: FC<Props> = ({ children, className }) => {
  return <div className={classNames(styles.container, className)}>{children}</div>;
};

export default memo(Container);
