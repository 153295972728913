import React, { FC, memo, ReactElement } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

type Props = {
  topContent: ReactElement;
  bottomContent?: ReactElement;
  className?: string;
};

const Card: FC<Props> = ({ topContent, bottomContent, className }) => {
  const bottomContentExist = !!bottomContent;

  return (
    <div className={classNames(styles.card, className)}>
      <div className={classNames({ [styles.top]: bottomContentExist })}>{topContent}</div>
      {bottomContentExist && <div className={styles.bottom}>{bottomContent}</div>}
    </div>
  );
};

export default memo(Card);
