import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';

import { RefundRequest, RefundResponse } from '../types';

const refund = ({ userId, coins }: RefundRequest): Promise<AxiosResponse<RefundResponse>> => {
  return authInstance({ method: 'post', url: `/v1/users/${userId}/coins/refund`, data: { coins } });
};

export default refund;
