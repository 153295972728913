import { localStorage, StorageKey } from '../storage';
import { Theme } from '../types';

const configureTheme = () => {
  const userSavedTheme = localStorage.get<Theme>(StorageKey.GlobalTheme) || Theme.Dark;

  document.documentElement.setAttribute('data-theme', userSavedTheme);
};

export default configureTheme;
