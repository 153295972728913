export enum Theme {
  gray = 'gray',
  yellow = 'yellow',
  green = 'green',
  red = 'red',
  mint = 'mint',
  violet = 'violet',
  purple = 'purple',
  redContrast = 'redContrast',
  grayInverse = 'grayInverse',
}
