import React, { FC, memo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'app/store';
import { detailsSelectors } from 'packages/leaderboard';

import styles from './overall.module.scss';

type Props = {
  className: string;
};

const Overall: FC<Props> = ({ className }) => {
  const { points } = useAppSelector(detailsSelectors.getData);

  const { t } = useTranslation();

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.label}>{t('comon_overall_with_colon')}:</div>
      <div className={styles.points}>{t('quiz_results_param_2', { count: points })}</div>
    </div>
  );
};

export default memo(Overall);
