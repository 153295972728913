import { AppThunk } from 'app/store';
import { LocaleCode } from 'app/types';

import { localStorage, StorageKey } from 'app/storage';

import { setLocaleCode } from '../actions';

const configureLanguage = (localeCode: LocaleCode): AppThunk => {
  return (dispatch) => {
    localStorage.set<LocaleCode | undefined>(StorageKey.LocaleCode, localeCode);

    dispatch(setLocaleCode(localeCode));
  };
};

export default configureLanguage;
