import { createAction } from '@reduxjs/toolkit';

import { Sport } from 'packages/types';
import { Events } from 'packages/rest';

export const updateSelectedEventsBySport = createAction<{
  sport: Sport;
  events: Record<number, Events.LeagueEventShortInfoSchema>;
}>('tournamentManagement/updateSelectedEventsBySport');

export const updateSelectedSportEvents = createAction<
  Partial<Record<Sport, Record<number, Events.LeagueEventShortInfoSchema>>>
>('tournamentManagement/updateSelectedSportEvents');

export const toggleEvent = createAction<{ sport: Sport; event: Events.LeagueEventShortInfoSchema }>(
  'tournamentManagement/toggleEvent',
);

export const removeEvents = createAction<{ sport: Sport; eventIds: number[] }>(
  'tournamentManagement/removeEvents',
);

export const cleanMatches = createAction('tournamentManagement/cleanMatches');
