import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';

import { GetLeaguesBySportIdResponse, GetLeaguesBySportIdRequest } from '../types';

const getLeaguesBySportId = ({
  sportId,
}: GetLeaguesBySportIdRequest): Promise<AxiosResponse<GetLeaguesBySportIdResponse>> => {
  return authInstance({ method: 'get', url: `/v1/events/sports/${sportId}/leagues` });
};

export default getLeaguesBySportId;
