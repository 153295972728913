import { lazy } from 'react';

const SignIn = lazy(() => import('./SignIn'));
const Home = lazy(() => import('./Home'));
const Profile = lazy(() => import('./Profile'));
const ProfileUserSearch = lazy(() => import('./ProfileUserSearch'));
const NotFound = lazy(() => import('./NotFound'));
const UserProfile = lazy(() => import('./UserProfile'));
const UserProfileUserSearch = lazy(() => import('./UserProfileUserSearch'));
const CreateTournament = lazy(() => import('./CreateTournament'));
const SuperExpress = lazy(() => import('./SuperExpress'));
const QuizGame = lazy(() => import('./QuizGame'));
const QuizLeaderboard = lazy(() => import('./QuizLeaderboard'));
const QuizHome = lazy(() => import('./QuizHome'));
const TournamentsList = lazy(() => import('./TournamentsList'));

export {
  SignIn,
  Home,
  Profile,
  ProfileUserSearch,
  NotFound,
  UserProfile,
  UserProfileUserSearch,
  CreateTournament,
  SuperExpress,
  QuizGame,
  QuizLeaderboard,
  QuizHome,
  TournamentsList,
};
