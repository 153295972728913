import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';
import { GetRatingDetailsRequest, GetRatingDetailsResponse } from '../types';

const getRatingDetails = ({
  type,
  dateType,
  dateOffset,
  sportId,
  id,
  limit,
  offset,
}: GetRatingDetailsRequest): Promise<AxiosResponse<GetRatingDetailsResponse>> => {
  return authInstance({
    method: 'get',
    url: `/v1/rating/${type}/${id}`,
    params: {
      limit,
      offset,
      dateType,
      dateOffset,
      sportId,
    },
  });
};

export default getRatingDetails;
