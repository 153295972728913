import { createContext } from 'react';

import { Theme } from '../../types';

type ThemeType = { theme: Theme; switchTheme: () => Theme };
export const initialThemeState: ThemeType = {
  theme: Theme.Dark,
  switchTheme: () => Theme.Light,
};

const Context = createContext(initialThemeState);

export default Context;
