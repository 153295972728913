import React, { FC, memo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, SegmentControlBar } from 'packages/shared/components';
import { Rating } from 'packages/rest';
import { useAppSelector } from 'app/store';
import { filterSelectors } from 'packages/leaderboard';

import Content from '../Content';
import styles from './appearanceFilter.module.scss';
import { Context } from '../../context';

type Props = {
  onDateRangeClick: () => void;
};

const OPTIONS = [
  { id: Rating.DateType.Week, label: 'common_weekly' },
  { id: Rating.DateType.Month, label: 'common_monthly' },
  { id: Rating.DateType.Year, label: 'common_yearly' },
];

const AppearanceFilter: FC<Props> = ({ onDateRangeClick }) => {
  const { t } = useTranslation();
  const { filterPreset, updatePreset } = useContext(Context);
  const { dateType, dateOffset } = filterPreset;
  const options = useAppSelector(filterSelectors.getOptions);

  const handleDateTypeChange = useCallback(
    (dateType: Rating.DateType) => {
      updatePreset({ dateType, dateOffset: options[dateType][0] });
    },
    [updatePreset, options],
  );

  return (
    <Content.Container header={t('common_appearance')}>
      <Content.Item className={styles.segmentWrapper}>
        <SegmentControlBar.Container<Rating.DateType>
          onChange={handleDateTypeChange}
          activeId={dateType}
          darkAppearance
        >
          {OPTIONS.map(({ id, label }) => (
            <SegmentControlBar.Option key={id} id={id}>
              {t(label)}
            </SegmentControlBar.Option>
          ))}
        </SegmentControlBar.Container>
      </Content.Item>
      <Content.Item onClick={onDateRangeClick} className={styles.dateRange}>
        <div className={styles.label}>{t('common_date_range')}</div>

        <div className={styles.date}>
          {dateOffset.label} <Icon id="chevronRight" module="system" />
        </div>
      </Content.Item>
    </Content.Container>
  );
};

export default memo(AppearanceFilter);
