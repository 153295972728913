import jwtDecode from 'jwt-decode';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { getAppIdentifier } from 'packages/config/selectors';
import { Auth } from 'packages/rest';
import { Provider } from 'packages/rest/Auth';
import { localStorage, StorageKey } from 'app/storage';
import { RootState } from 'app/store';
import { toCamelCase } from 'app/utils/Object.utils';

interface Params {
  provider: Provider;
  accessToken: string;
}

interface ReturnType {
  userId: number;
  role: number;
}

const signIn = createAsyncThunk<ReturnType, Params, { state: RootState }>(
  'auth/signIn',
  async ({ provider, accessToken }, { getState }) => {
    const appIdentifier = getAppIdentifier(getState()) || '';

    const response = await Auth.signIn({
      provider,
      identifier: appIdentifier,
      accessToken,
    });

    const { accessToken: responseAccessToken, refreshToken } = response.data;
    const { userId, role } = toCamelCase(jwtDecode(responseAccessToken));

    localStorage.set(StorageKey.AccessToken, responseAccessToken);
    localStorage.set(StorageKey.RefreshToken, refreshToken);

    return { userId, role };
  },
);

export default signIn;
