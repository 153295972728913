import { createAsyncThunk } from '@reduxjs/toolkit';

import { Rating } from 'packages/rest';
import { RootState } from 'app/store';

import { LeaderboardTypes } from '../../types';
import { leaderboardSelectors } from '../../selectors';

interface ReturnParams extends LeaderboardTypes.Data {
  offset: number;
  hasNext: boolean;
}

const getMoreRating = createAsyncThunk<ReturnParams, void, { state: RootState }>(
  'quiz/getMoreRating',
  async (params, { getState }) => {
    const { offset, limit } = leaderboardSelectors.getStatus(getState());
    const { data } = await Rating.getQuizRating({ limit, offset });

    return {
      ...data,
      offset: limit + offset,
      hasNext: limit <= (data.all || []).length,
    };
  },
);

export default getMoreRating;
