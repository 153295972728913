import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';

import { GetTournamentByIdRequest, GetTournamentByIdResponse } from '../types';

const getTournamentById = ({
  tournamentId,
}: GetTournamentByIdRequest): Promise<AxiosResponse<GetTournamentByIdResponse>> => {
  return authInstance({
    method: 'get',
    url: `/v1/tournaments/${tournamentId}`,
  });
};

export default getTournamentById;
