import { createAsyncThunk } from '@reduxjs/toolkit';

import { Tournaments } from 'packages/rest';

import { lineUpSelectors } from '../selectors';
import { RootState } from '../../../app/store';

type ReturnType = {
  details: Tournaments.EventTeamsTopResponse;
  offset: number;
  hasNext: boolean;
};

const getLineUpDetails = createAsyncThunk<ReturnType, void, { state: RootState }>(
  'leaderboard/getMoreLineUpDetails',
  async (params, { getState }) => {
    const { offset, limit } = lineUpSelectors.getStatus(getState());
    const {
      teamId = '',
      tournamentId = 0,
      eventId = 0,
    } = lineUpSelectors.getData(getState()) || {};
    const { data } = await Tournaments.getTournamentEventTeamsTop({
      limit,
      offset,
      teamId,
      tournamentId,
      eventId,
    });

    return {
      details: data,
      offset: limit + offset,
      hasNext: data.total > limit + offset,
    };
  },
);

export default getLineUpDetails;
