import React, { createContext } from 'react';

import { ContentType } from '../types';

export const initialContentState: [ContentType, React.Dispatch<React.SetStateAction<ContentType>>] =
  [
    ContentType.LeaderboardTable,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    () => {},
  ];

const Context = createContext(initialContentState);

export default Context;
