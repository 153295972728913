import { reviewConstants } from '../constants';
import { SettingsTypes } from '../types';

const getLValueFromPoolType = (prizePool: SettingsTypes.PrizePoolData) => {
  const { poolType } = prizePool;

  if (poolType === SettingsTypes.PoolType.WinnersPercent) {
    const { winnersPercent } = <SettingsTypes.WinnersPercentPrizePoolData>prizePool;
    const { activeTrophy } = winnersPercent;

    if (activeTrophy?.value) {
      return reviewConstants.L_VALUE_BY_TROPHY_TYPE[activeTrophy.value];
    }
  }

  return undefined;
};

export default getLValueFromPoolType;
