import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';

import { GetProfileMenuResponse } from '../types';

const getProfileMenu = (): Promise<AxiosResponse<GetProfileMenuResponse>> => {
  return authInstance({
    method: 'get',
    url: '/v2/profile/menu',
  });
};

export default getProfileMenu;
