import smoothscroll from 'smoothscroll-polyfill';

import configureRest from './configureRest';
import configureDictionary from './configureDictionary';
import configureTheme from './configureTheme';
import configureAppIdentifier from './configureAppIdentifier';
import configureCurrency from './configureCurrency';
import configureDayjs from './configureDayjs';

configureRest();
configureDictionary();
configureTheme();
configureAppIdentifier();
configureCurrency();
configureDayjs();

smoothscroll.polyfill();
