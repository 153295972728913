import React, { cloneElement, ReactElement, RefObject, useCallback, useMemo } from 'react';

import Summary from '../../Summary';
import Content from '../../Content';

interface Params {
  ref: RefObject<HTMLDivElement>;
  children: (
    | ReactElement<Parameters<typeof Summary>[0]>
    | ReactElement<Parameters<typeof Content>[0]>
  )[];
  changeIsOpen?: (isOpen: boolean) => void;
  isOpen?: boolean;
}

const useContentElement = ({ isOpen, changeIsOpen, ref, children }: Params) => {
  const toggleIsOpen = useCallback(() => {
    changeIsOpen?.(!isOpen);
  }, [changeIsOpen, isOpen]);

  return useMemo(() => {
    const summaryElement = children.find((child) => child.type === Content) as
      | ReactElement<Parameters<typeof Content>[0]>
      | undefined;

    return summaryElement ? (
      cloneElement(summaryElement, { isOpen, toggleIsOpen, forwardedRef: ref })
    ) : (
      <></>
    );
  }, [children, isOpen, ref, toggleIsOpen]);
};

export default useContentElement;
