import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';

import { MakePredictionRequest, MakePredictionResponse } from '../types';

const makePrediction = ({
  predictions,
  tournamentId,
}: MakePredictionRequest): Promise<AxiosResponse<MakePredictionResponse>> => {
  const mappedPredictions = predictions.map(({ sportId, eventId, choice }) => ({
    choice,
    sport_id: sportId,
    event_id: eventId,
  }));

  return authInstance({
    method: 'post',
    url: `/v1/tournaments/${tournamentId}/express/join`,
    data: {
      predictions: mappedPredictions,
    },
  });
};

export default makePrediction;
