export enum LayoutType {
  Flex = 'flex',
  Block = 'block',
  Absolute = 'absolute',
}

export enum StickyColumnDirection {
  Left = 'left',
  Right = 'right',
}

export interface FixedColumn {
  leftOffset: number;
  totalWidth: number;
}
