import { Sport as BaseSportId } from 'packages/types';
import { Rating } from 'packages/rest';
import { CheckListTypes } from 'packages/shared/components';

export enum SportId {
  All = 0,
}

export type FilterSportId = SportId | BaseSportId;

export interface Filter {
  dateType: Rating.DateType;
  dateOffset: DateOffsetOption;
  sport: CheckListTypes.Option<FilterSportId>;
  unit: Rating.UnitType;
  onlyFriends: boolean;
  measurement: Rating.Order;
}

export interface DateOffsetOption extends CheckListTypes.Option<number> {}

export type DateRangeOptions = Record<Rating.DateType, DateOffsetOption[]>;

export interface Data {
  active: Filter;
  options: DateRangeOptions;
  search: string;
}

export interface State {
  data: Data;
}
