import { createReducer } from '@reduxjs/toolkit';
import { t } from 'i18next';

import { QuestionsTypes } from '../../types';
import { questionsThunks } from '../../thunks';
import { questionsActions } from '../../actions';

const defaultState: QuestionsTypes.Status = {
  isAnswerLoading: false,
  isQuestionsLoading: false,
  errorMessage: undefined,
};

const questionsReducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(questionsThunks.getQuestions.pending, (state) => {
      state.isQuestionsLoading = true;
    })
    .addCase(questionsThunks.getQuestions.fulfilled, (state) => {
      state.isQuestionsLoading = false;
    })
    .addCase(questionsThunks.getQuestions.rejected, (state, action) => {
      state.isQuestionsLoading = false;
      state.errorMessage = (action.payload as string) || t('quiz_network_error');
    })
    .addCase(questionsThunks.getAnswer.pending, (state) => {
      state.isAnswerLoading = true;
    })
    .addCase(questionsThunks.getAnswer.fulfilled, (state) => {
      state.isAnswerLoading = false;
    })
    .addCase(questionsThunks.getAnswer.rejected, (state, action) => {
      state.isAnswerLoading = false;
      state.errorMessage = (action.payload as string) || t('quiz_network_error');
    })
    .addCase(questionsActions.cleanupState, () => {
      return { ...defaultState };
    });
});

export default questionsReducer;
