import React, { FC, memo } from 'react';

import { Icon } from 'packages/shared/components';
import { Sport } from 'packages/types';

import { getSportIconId } from './services';

type Props = {
  className?: string;
  sportId: Sport;
};

const SportIcon: FC<Props> = ({ className, sportId }) => (
  <Icon className={className} module="sports" id={getSportIconId(sportId)} />
);

export default memo(SportIcon);
