import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Note } from 'packages/shared/components';
import { CountryInfoSchema } from 'packages/rest/types';

import { Nullable } from 'packages/types';

import Team from '../Team';
import Card from '../Card';
import styles from './styles.module.scss';

type Props = {
  team: Nullable<CountryInfoSchema>;
};

const NationalTeamCard: FC<Props> = ({ team }) => {
  const { t } = useTranslation();

  return (
    <>
      {team ? (
        <Card className={styles.offset} topContent={<Team name={team.name} logo={team.img} />} />
      ) : (
        <Note
          iconElement={<Icon id="flag" module="base" />}
          header={t('country_title_choose_national_team')}
          theme="purple"
          className={styles.offset}
        >
          {t('country_info_choose_national_team')}
        </Note>
      )}
    </>
  );
};

export default memo(NationalTeamCard);
