import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { Rating } from 'packages/rest';

import EventHistoryItem from './EventHistoryItem';
import QuizHistoryItem from './QuizHistoryItem';
import styles from './history.module.scss';

type Props = {
  date: string;
  dailyPoints: number;
  events: Array<Rating.QuizRatingDetails | Rating.EventRatingDetails>;
};

const History: FC<Props> = ({ date, dailyPoints, events }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.date}>{dayjs(date).format('D MMMM')}</div>
        <div className={styles.points}>{t('quiz_results_param_2', { count: dailyPoints })} </div>
      </div>
      {events.map((item) => {
        const { points, createdAt } = item;

        if ('tournament' in item) {
          const { event, tournament } = item;

          return (
            <EventHistoryItem
              points={points}
              date={date}
              tournament={tournament}
              event={event}
              key={event.id}
            />
          );
        }

        const { quiz } = item;

        return <QuizHistoryItem date={createdAt} key={quiz.id} quiz={quiz} points={points} />;
      })}
    </div>
  );
};

export default memo(History);
