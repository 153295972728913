import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { availablePredictionsThunks } from '../../thunks';
import { availablePredictionsActions } from '../../actions';
import { LeaguesTypes } from '../../types';

const defaultState: LeaguesTypes.Status = {
  isLoading: true,
};

const reducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(availablePredictionsThunks.loadPredictions.pending, () => {
      return { isLoading: true };
    })
    .addCase(availablePredictionsActions.cleanupPredictions, () => {
      return defaultState;
    })
    .addMatcher(
      isAnyOf(
        availablePredictionsThunks.loadPredictions.fulfilled,
        availablePredictionsThunks.loadPredictions.rejected,
      ),
      () => {
        return { isLoading: false };
      },
    );
});

export default reducer;
