import { Nullable } from 'packages/types';

type Params = {
  captainId: Nullable<number>;
  role: number;
};

const getUserRoleKey = ({ captainId, role }: Params) => {
  if (captainId === null) {
    return 'fc_details_no_captain';
  }

  switch (role) {
    case 0:
      return 'common_player';
    case 1:
      return 'common_team_role_vice_captain';
    case 2:
      return 'team_edit_tab_captain';
    default:
      return '';
  }
};

export default getUserRoleKey;
