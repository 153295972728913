import React, { FC, memo } from 'react';

import { Spinner } from 'packages/shared/components';

import styles from './styles.module.scss';

const InfiniteScrollLoader: FC = () => {
  return (
    <div className={styles.infiniteScrollLoader}>
      <Spinner />
    </div>
  );
};

export default memo(InfiniteScrollLoader);
