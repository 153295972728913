import { Friends } from 'packages/rest';

export enum Page {
  Home = 'Home',
  SignIn = 'SignIn',

  Profile = 'Profile',
  OwnProfile = 'OwnProfile',
  ProfileUserSearch = 'ProfileUserSearch',

  User = 'User',
  UserProfile = 'UserProfile',
  UserSearch = 'UserSearch',

  CreateTournament = 'CreateTournament',

  Tournaments = 'Tournaments',

  SuperExpress = 'SuperExpress',

  Quiz = 'Quiz',
  QuizHome = 'QuizHome',
  QuizGame = 'QuizGame',
  QuizLeaderboard = 'QuizLeaderboard',

  NotFound = 'NotFound',
}

export interface RouterParams {
  [Page.Home]: Record<string, never>;
  [Page.SignIn]: Record<string, never>;
  [Page.Profile]: Record<string, never>;
  [Page.OwnProfile]: Record<string, never>;
  [Page.Quiz]: Record<string, never>;
  [Page.QuizLeaderboard]: Record<string, never>;
  [Page.QuizHome]: Record<string, never>;
  [Page.Tournaments]: Record<string, never>;
  [Page.QuizGame]: { id: string };
  [Page.ProfileUserSearch]: {
    type: Friends.SearchType;
  };
  [Page.User]: Record<string, never>;
  [Page.UserProfile]: {
    id: string;
  };
  [Page.UserSearch]: {
    id: string;
    type: Friends.SearchType;
  };
  [Page.CreateTournament]: Record<string, never>;
  [Page.SuperExpress]: {
    tournamentId: string;
  };
  [Page.NotFound]: Record<string, never>;
}
