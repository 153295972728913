import { createReducer } from '@reduxjs/toolkit';

import { tournamentsThunks } from '../../../thunks';
import { TournamentsTypes } from '../../../types';

const defaultState: TournamentsTypes.Data = [];

const reducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(tournamentsThunks.getWaiting.fulfilled, (state, { payload }) => {
      return payload;
    })
    .addCase(tournamentsThunks.getWaiting.rejected, () => {
      return [];
    });
});

export default reducer;
