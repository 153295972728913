import { createReducer } from '@reduxjs/toolkit';
import { t } from 'i18next';

import { getProfileMenu } from '../thunks';
import { State } from '../types';
import { cleanupState } from '../actions';

const defaultState: State = {
  data: undefined,
  status: {
    isLoading: false,
    errorMessage: undefined,
  },
};

const reducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(getProfileMenu.pending, (state) => {
      state.status.isLoading = true;
      state.status.errorMessage = '';
    })
    .addCase(getProfileMenu.fulfilled, (state, { payload }) => {
      const { data } = payload;

      state.status.isLoading = false;
      state.data = data;
    })
    .addCase(getProfileMenu.rejected, (state) => {
      state.status.isLoading = false;
      state.status.errorMessage = t('burger_menu.error.loading_profile_data');
    })
    .addCase(cleanupState, () => {
      return defaultState;
    });
});

export default reducer;
