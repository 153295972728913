import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app/store';

import { State, Status, Data } from '../types';

const getState = (state: RootState): State => state.auth;
const getData = createSelector(getState, (state: State): Data => state.data);

export const getStatus = createSelector(getState, (state: State): Status => state.status);

export const getAuthorized = createSelector(getData, (data: Data): boolean => data.authorized);
export const getUserId = createSelector(getData, (data: Data): number | undefined => data.userId);
export const getIsLoading = createSelector(getStatus, (state: Status): boolean => state.isLoading);
export const getErrorMessage = createSelector(
  getStatus,
  (state: Status): string | undefined => state.errorMessage,
);
