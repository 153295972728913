import React, { memo, useCallback } from 'react';
import ReactSelect from 'react-select';
import classNames from 'classnames';
import { MenuPlacement, OnChangeValue } from 'react-select/dist/declarations/src/types';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

import { Option as OptionType, OnChange, Size } from './types';
import { useSelectProps } from './hooks';
import styles from './styles.module.scss';

type Props<V extends string | number, T extends OptionType<V>> = {
  options: T[];
  menuShouldScrollIntoView?: boolean;
  menuPlacement?: MenuPlacement;
  onChange?: OnChange<V>;
  className?: string;
  invertAppearance?: boolean;
  menuShouldBlockScroll?: boolean;
  value?: T | null;
  size?: keyof typeof Size;
} & Partial<StateManagerProps<T, false>>;

const Select = <V extends string | number, T extends OptionType<V>>({
  options,
  value,
  onChange,
  className,
  invertAppearance = false,
  menuShouldBlockScroll = true,
  menuPlacement = 'auto',
  size = 'md',
  ...props
}: Props<V, T>) => {
  const selectProps = useSelectProps<V, T>();

  const handleChange = useCallback(
    (value: OnChangeValue<OptionType<V>, false>) => {
      onChange?.(value);
    },
    [onChange],
  );

  return (
    <ReactSelect<T>
      value={value}
      onChange={handleChange}
      menuPlacement={menuPlacement}
      menuPortalTarget={document.getElementById('react-select-portal')}
      menuShouldBlockScroll={menuShouldBlockScroll}
      className={classNames(
        styles.selectContainer,
        styles[Size[size]],
        { [styles.invertAppearance]: invertAppearance },
        className,
      )}
      options={options}
      size={size}
      {...selectProps}
      {...props}
    />
  );
};

export default memo(Select);
