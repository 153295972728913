import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { FanclubInfoSchema, TennisRatingInfoSchema } from 'packages/rest/types';
import { Nullable } from 'packages/types';

import Team from '../Team';
import styles from './styles.module.scss';
import { getTennisClubName } from './services';

type Props = {
  clubs: FanclubInfoSchema[];
  tennisClub: Nullable<TennisRatingInfoSchema>;
};

const FunClubs: FC<Props> = ({ clubs, tennisClub }) => {
  const { t } = useTranslation();
  const tennisRanking =
    typeof tennisClub?.position === 'number'
      ? `${t('common_ranking_with_param', [tennisClub?.position])}`
      : undefined;

  return (
    <ul className={styles.wrapper}>
      {clubs.map(({ id, name, img, tournament }) => (
        <li key={id}>
          <Team name={name} logo={img}>
            {tournament.name}
          </Team>
        </li>
      ))}

      {tennisClub && (
        <li>
          <Team name={t(getTennisClubName(tennisClub.gender))} logo={tennisClub.img}>
            {tennisRanking}
          </Team>
        </li>
      )}
    </ul>
  );
};

export default memo(FunClubs);
