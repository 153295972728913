import { Nullable, Sport } from 'packages/types';

export enum UserGroup {
  ADMIN_VIEW = 'ADMIN_VIEW',
  BALANCE_LEADERBOARD = 'BALANCE_LEADERBOARD',
  BLOCKED_TOURNAMENTS_COMMENTS = 'BLOCKED_TOURNAMENTS_COMMENTS',
  CELEBRITY = 'CELEBRITY',
  DEV = 'DEV',
  EXTENDED_ACTIVE_TOURNAMENTS = 'EXTENDED_ACTIVE_TOURNAMENTS',
  STOCKS_TEST = 'STOCKS_TEST',
  SUPERADMIN = 'SUPERADMIN',
  SUPPORT = 'SUPPORT',
  SYSTEM_ACCOUNT = 'SYSTEM_ACCOUNT',
  TEST_AWARDS = 'TEST_AWARDS',
  BOFP_COMMENTS = 'BOFP_COMMENTS',
  PARTNERS = 'PARTNERS',
  VIP_TOURNAMENT_CREATOR = 'VIP_TOURNAMENT_CREATOR',
  COMPACT_ACTIVE_TOURNAMENTS = 'COMPACT_ACTIVE_TOURNAMENTS',
  BOFP_FINANCES = 'BOFP_FINANCES',
  NEGATIVE_BALANCE_ALLOWED = 'NEGATIVE_BALANCE_ALLOWED',
  AUTO_PREDICT = 'AUTO_PREDICT',
  TEST_FEATURES = 'TEST_FEATURES',
  SERVICE_ACCOUNT = 'SERVICE_ACCOUNT',
  INVITE_FOLLOWERS_IN_MY_TOURNAMENT = 'INVITE_FOLLOWERS_IN_MY_TOURNAMENT',
  INVITE_FOLLOWERS_IN_OTHER_TOURNAMENT = 'INVITE_FOLLOWERS_IN_OTHER_TOURNAMENT',
}

export interface CountryInfoSchema {
  name: string;
  img: string;
  code: string;
}

export interface TournamentInfoSchema {
  id: number;
  name: string;
  img: string;
}

export interface TeamInfoSchema {
  id: number;
  name: string;
  photo: string;
  joined: number;
  balance: number;
  captainId: Nullable<number>;
  role: number;
}

export interface TennisRatingInfoSchema {
  gender: number;
  position: Nullable<number>;
  img: string;
}

export interface UserInfoSchema {
  groups: UserGroup[];
  id: number;
  firstName: string;
  lastName: string;
  photo: string;
  balance: string;
  position: number;
  points: number;
  createdAt: string;
  status: number;
  isTemporaryBlocked: boolean;
  reservedBalance?: Nullable<number>;
}

export interface FanclubInfoSchema {
  id: number;
  name: string;
  img: string;
  sportId: Nullable<number>;
  tournament: TournamentInfoSchema;
}

export interface LeagueSchema {
  id: number;
  name: string;
}

export interface EventShortInfoSchema {
  id: number;
  olimpId?: Nullable<number>;
  club1Id?: Nullable<number>;
  club2Id?: Nullable<number>;
  club1Name?: Nullable<string>;
  club2Name?: Nullable<string>;
  img1?: Nullable<string>;
  img2?: Nullable<string>;
  date: string;
  leagueId?: Nullable<number>;
  leagueName?: Nullable<string>;
  sportId: Sport;
}

export interface QuestionsInfoSchema {
  code: number;
  question: string;
}

export interface MarketsCodesSchema {
  code: number;
  question: string;
  eventsWithoutCode: Array<number>;
}

export enum PredictionChoice {
  Win1 = 'coeff_win_1',
  Draw = 'coeff_draw',
  Win2 = 'coeff_win_2',
}
