import React, { FC, memo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, TopFixedCounter } from 'packages/shared/components';
import { UserInfoSchema } from 'packages/rest/types';
import { useAppNavigation } from 'app/router/hooks';
import { Page } from 'app/router/types';
import { Modal } from 'app/context';

import styles from './cardTopContent.module.scss';

type Props = {
  user: UserInfoSchema;
  unreadMessagesCount: number;
};

const CardTopContent: FC<Props> = ({ user, unreadMessagesCount }) => {
  const { appNavigate } = useAppNavigation();
  const { updateActiveModals } = useContext(Modal.Context);

  const { t } = useTranslation();

  const handleCloseModal = useCallback(() => {
    updateActiveModals({ mainMenu: false });
  }, [updateActiveModals]);

  const handleProfileClick = () => {
    appNavigate(Page.Profile, {});
    handleCloseModal();
  };

  return (
    <div className={styles.header}>
      <div onClick={handleProfileClick} className={styles.iconContainer}>
        <img alt="user" src={user.photo} className={styles.icon} />
      </div>

      <div className={styles.content}>
        <div onClick={handleProfileClick} className={styles.userName}>
          {user.firstName} {user.lastName}
        </div>
        <div className={styles.userStatistics}>
          {t('common_rating_with_param', [user.position])} ・{' '}
          {t('common_points_with_param', { count: user.points })}
          <Icon className={styles.chevron} module="system" id="chevronRight" />
        </div>
      </div>

      <div className={styles.messagesContainer}>
        <TopFixedCounter count={unreadMessagesCount}>
          <Icon className={styles.messagesIcon} module="base" id="chat" />
        </TopFixedCounter>
      </div>
    </div>
  );
};

export default memo(CardTopContent);
