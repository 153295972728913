import { createAsyncThunk } from '@reduxjs/toolkit';

import { ProfileV2 } from 'packages/rest';

interface ReturnType {
  data: ProfileV2.GetProfileResponse;
}

const getProfile = createAsyncThunk<ReturnType>('profile/getProfile', async () => {
  const { data } = await ProfileV2.getProfile();

  return { data };
});

export default getProfile;
