import { createReducer } from '@reduxjs/toolkit';

import { matchesFilterActions, metaActions } from '../../actions';
import { matchesFilterConstants } from '../../constants';

const reducer = createReducer(matchesFilterConstants.DEFAULT_STATE, (builder) => {
  builder
    .addCase(matchesFilterActions.setSearchKey, (state, { payload }) => {
      const { sport, searchKey } = payload;

      state[sport].searchKey = searchKey;
    })
    .addCase(matchesFilterActions.applyMatchFilter, (state, { payload }) => {
      const { sport, filter } = payload;

      state[sport] = {
        ...state[sport],
        ...filter,
      };
    })
    .addCase(metaActions.setActiveSport, (state) => {
      Object.values(state).forEach((filter) => {
        filter.searchKey = '';
      });
    });
});

export default reducer;
