import { createSelector } from '@reduxjs/toolkit';

import { DetailsTypes } from '../../types';
import getState from './getState';

export const getData = createSelector(
  getState,
  (state: DetailsTypes.State): DetailsTypes.Data => state.data,
);

export const getDetails = createSelector(
  getData,
  (state: DetailsTypes.Data): DetailsTypes.Details[] | undefined => state.details,
);
