import React, { FC, memo, ReactElement, useContext, useEffect } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';
import { Context } from '../../context';
import { NavigationActionType } from '../../types';

type Props = {
  topOffset?: number;
  children: ReactElement | ReactElement[];
};

const AnimationContainer: FC<Props> = ({ children, topOffset = 0 }) => {
  const [state, dispatch] = useContext(Context);
  const { animationContainerActive } = state;

  useEffect(() => {
    dispatch({ type: NavigationActionType.SET_ANIMATION_CONTAINER_TOP_OFFSET, topOffset });
  }, [topOffset, dispatch]);

  return (
    <div className={classNames(styles.container, { [styles.active]: animationContainerActive })}>
      <div style={{ top: topOffset }} className={styles.contentWrapper}>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default memo(AnimationContainer);
