import React, { FC, memo } from 'react';

import { UserInfoSchema } from 'packages/rest/types';

import Card from '../Card';
import CardTopContent from './CardTopContent';
import CardBottomContent from './CardBottomContent';
import styles from './styles.module.scss';

type Props = {
  user: UserInfoSchema;
  unreadMessagesCount: number;
};

const UserCard: FC<Props> = ({ user, unreadMessagesCount }) => {
  return (
    <Card
      className={styles.offset}
      topContent={<CardTopContent unreadMessagesCount={unreadMessagesCount} user={user} />}
      bottomContent={<CardBottomContent />}
    />
  );
};

export default memo(UserCard);
