import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';

import { EventTeamsTopResponse, EventTeamsTopRequest } from '../types';

const getTournamentEventTeamsTop = ({
  tournamentId,
  teamId,
  eventId,
  limit,
  offset,
}: EventTeamsTopRequest): Promise<AxiosResponse<EventTeamsTopResponse>> => {
  return authInstance({
    method: 'post',
    url: `/v1/tournaments/${tournamentId}/teams/${teamId}/events/${eventId}/top`,
    data: {
      limit,
      offset,
    },
  });
};

export default getTournamentEventTeamsTop;
