import React, { FC, memo, ReactElement, RefObject } from 'react';
import classNames from 'classnames';

import { Icon } from 'packages/shared/components';

import styles from './styles.module.scss';

interface Props {
  children: string | ReactElement;
  onClick?: () => void;
  isOpen?: boolean;
  forwardedRef?: RefObject<HTMLDivElement>;
  value?: string | number | ReactElement;
}

const Summary: FC<Props> = ({ onClick, isOpen, children, forwardedRef, value }) => {
  return (
    <div className={styles.container}>
      <div ref={forwardedRef} className={styles.summary} onClick={onClick}>
        <div className={styles.label}>{children}</div>
        <div className={classNames(styles.valueContainer, { [styles.opened]: isOpen })}>
          {!!value && <span className={styles.value}>{value}</span>}
          <Icon module="system" id="chevronRight" className={styles.icon} />
        </div>
      </div>
    </div>
  );
};

export default memo(Summary);
