import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { invitationsConstants } from '../../constants';
import { invitationsThunks } from '../../thunks';
import { InvitationsTypes } from '../../types';

const defaultState: InvitationsTypes.Status = {
  isInitialLoading: true,
  isMoreLoading: false,
  offset: invitationsConstants.INITIAL_OFFSET,
  limit: invitationsConstants.INITIAL_LIMIT,
  hasNext: true,
};

const reducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(invitationsThunks.loadInitialInvitations.fulfilled, (state, { payload }) => {
      const { offset, hasNext } = payload;

      state.offset = offset;
      state.hasNext = hasNext;
      state.isInitialLoading = false;
    })
    .addCase(invitationsThunks.loadMoreInvitations.pending, (state) => {
      state.isMoreLoading = true;
    })
    .addCase(invitationsThunks.loadMoreInvitations.rejected, (state) => {
      state.isMoreLoading = false;
    })
    .addCase(invitationsThunks.loadMoreInvitations.fulfilled, (state, { payload }) => {
      const { offset, hasNext } = payload;

      state.offset = offset;
      state.hasNext = hasNext;
      state.isMoreLoading = false;
    })
    .addMatcher(isAnyOf(invitationsThunks.loadInitialInvitations.rejected), () => {
      return { ...defaultState, isInitialEventsLoading: false };
    })
    .addMatcher(isAnyOf(invitationsThunks.loadInitialInvitations.pending), () => {
      return defaultState;
    });
});

export default reducer;
