import React, { FC, memo, ReactElement, useMemo, useState } from 'react';

import { ActiveModal } from './types';
import { DEFAULT_ACTIVE_MODALS } from './constants';
import Context from './Context';

type Props = {
  children: ReactElement[] | ReactElement;
};

const Provider: FC<Props> = ({ children }) => {
  const [activeModals, updateActiveModals] = useState<ActiveModal>(DEFAULT_ACTIVE_MODALS);

  const modalState = useMemo(
    () => ({
      activeModals,
      updateActiveModals: (modals: Partial<ActiveModal>) => {
        updateActiveModals((prevState) => ({ ...prevState, ...modals }));
      },
    }),
    [activeModals],
  );

  return <Context.Provider value={modalState}>{children}</Context.Provider>;
};

export default memo(Provider);
