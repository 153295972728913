import { createSelector } from '@reduxjs/toolkit';

import { FilterTypes } from '../../types';
import getState from './getState';

export const getData = createSelector(
  getState,
  (state: FilterTypes.State): FilterTypes.Data => state.data,
);

export const getActiveFilter = createSelector(
  getData,
  (state: FilterTypes.Data): FilterTypes.Filter => state.active,
);

export const getOptions = createSelector(
  getData,
  (state: FilterTypes.Data): FilterTypes.DateRangeOptions => state.options,
);

export const getSearch = createSelector(getData, (state: FilterTypes.Data): string => state.search);
