import { createAsyncThunk } from '@reduxjs/toolkit';

import { Events } from 'packages/rest';
import { RootState } from 'app/store';

type ReturnType = Events.GetLeaguesBySportIdResponse;

interface Params {
  sportId: string | number;
}

const loadLeagues = createAsyncThunk<ReturnType, Params, { state: RootState }>(
  'tournamentManagement/loadLeagues',
  async ({ sportId }) => {
    const { data: leagues } = await Events.getLeaguesBySportId({ sportId });

    return leagues;
  },
);

export default loadLeagues;
