import { v4 as uuid } from 'uuid';
import { localStorage, StorageKey } from '../storage';

const configureAppIdentifier = () => {
  if (!localStorage.get(StorageKey.AppIdentifier)) {
    const appIdentifier = uuid();

    localStorage.set(StorageKey.AppIdentifier, appIdentifier);
  }
};

export default configureAppIdentifier;
