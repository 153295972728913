import { createReducer } from '@reduxjs/toolkit';

import { addTicketToList, cleanupState } from '../../actions';
import { getTournamentExpress, getMoreTickets } from '../../thunks';
import { ExpressTypes } from '../../types';

const defaultState: ExpressTypes.Data = {
  tickets: [],
  events: [],
};

const expressReducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(getTournamentExpress.fulfilled, (state, { payload }) => {
      const { data } = payload;

      return data;
    })
    .addCase(getMoreTickets.fulfilled, (state, { payload }) => {
      const { tickets } = payload;

      state.tickets = [...state.tickets, ...tickets];
    })
    .addCase(addTicketToList, (state, { payload: newTicket }) => {
      state.tickets = [...state.tickets, newTicket];
    })
    .addCase(cleanupState, () => {
      return defaultState;
    });
});

export default expressReducer;
