export const getScrollParent = (
  node: Element | Document | ParentNode | null,
): Element | Node | undefined => {
  if (node === document || !node) {
    return document.body;
  }

  const overflowRegex = /(auto|scroll)/;
  const style = getComputedStyle(node as Element);

  if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
    return node;
  }

  return getScrollParent(node.parentNode);
};
