import dayjs from 'dayjs';
import { t } from 'i18next';

import { DateUtils } from 'app/utils';

import { FilterTypes } from '../types';

const getMonthOptions = (): FilterTypes.DateOffsetOption[] => {
  const currentYear = dayjs().get('year');

  return DateUtils.getPeriods(3, 'year').map(({ start, offset }, index) => {
    if (start.get('year') === currentYear) {
      return { value: offset, id: `year-${index}`, label: t('common_date_this_year') };
    }

    return { value: offset, id: `year-${index}`, label: start.format('YYYY') };
  });
};

export default getMonthOptions;
