import { memo, RefObject, useContext, useEffect } from 'react';

import { Context } from '../../../../context';
import { TabBarItem } from '../../../../types';

type Params = {
  id: TabBarItem['id'];
  containerRef: RefObject<HTMLDivElement>;
  tabRef: RefObject<HTMLDivElement>;
};

const useTab = ({ id, containerRef, tabRef }: Params) => {
  const [state] = useContext(Context);
  const { tabBarHeight, tabBarTopOffset, animationContainerTopOffset, activeTabId } = state;
  const isActive = id === activeTabId;

  useEffect(() => {
    if (isActive) {
      const { scrollLeft = 0, offsetWidth: containerWidth = 0 } = containerRef?.current || {};
      const { left: tabLeftOffset = 0, right: tabRightOffset = 0 } =
        tabRef?.current?.getBoundingClientRect() || {};
      const { offsetWidth: tabWidth = 0 } = tabRef?.current || {};

      if (tabLeftOffset < 0) {
        containerRef.current?.scrollTo({ left: scrollLeft + tabLeftOffset, behavior: 'smooth' });
      }

      if (tabLeftOffset > containerWidth - tabWidth) {
        containerRef.current?.scrollTo({
          left: scrollLeft + tabRightOffset - containerWidth,
          behavior: 'smooth',
        });
      }
    }
  }, [tabRef, isActive, containerRef]);

  const handleClick = () => {
    const scrollElement = document.querySelector(`#${id}`);
    const { top = 0 } = scrollElement?.getBoundingClientRect() || {};

    window.scrollTo({
      top: top + window.scrollY - tabBarHeight - tabBarTopOffset - animationContainerTopOffset,
      behavior: 'smooth',
    });
  };

  return {
    handleClick,
    isActive,
  };
};

export default useTab;
