import React, { memo } from 'react';
import classNames from 'classnames';

import { Icon } from 'packages/shared/components';
import { IconId } from 'packages/shared/components/Icon/types';

import { SocialMedia } from './types';
import styles from './styles.module.scss';

type Props = {
  label: string;
  socialMedia: keyof typeof SocialMedia;
  onClick?: () => void;
  isDisabled?: boolean;
  isContentWidth?: boolean;
};

const socialMediaIcon: Record<SocialMedia, keyof typeof IconId> = {
  [SocialMedia.apple]: 'apple',
  [SocialMedia.google]: 'google',
  [SocialMedia.facebook]: 'facebook',
  [SocialMedia.vkontakte]: 'vk',
};

const SocialMediaButton = ({ label, socialMedia, onClick, isDisabled, isContentWidth }: Props) => {
  return (
    <button
      className={classNames(styles.button, styles[socialMedia], {
        [styles.contentWidth]: isContentWidth,
      })}
      type="button"
      disabled={isDisabled}
      onClick={onClick}
    >
      <Icon
        module="social"
        id={socialMediaIcon[socialMedia]}
        className={classNames(styles.icon, styles[socialMedia])}
      />
      {label}
    </button>
  );
};

export default memo(SocialMediaButton);
