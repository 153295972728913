import { createSelector } from '@reduxjs/toolkit';

import { Tournaments } from 'packages/rest';

import { LineUpTypes } from '../../types';
import getState from './getState';

export const getData = createSelector(
  getState,
  (state: LineUpTypes.State): LineUpTypes.Data => state.data,
);

export const getDetails = createSelector(
  getData,
  (state: LineUpTypes.Data): Tournaments.EventTeamsTopResponse | undefined => state.details,
);
