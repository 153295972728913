import { createAsyncThunk } from '@reduxjs/toolkit';

import { Quiz } from 'packages/rest';

interface Params {
  quizId: string | number;
}

interface ReturnType {
  maxEarnPoints: number;
  rightQuestions: number;
  earnedPoints: number;
  questionsCount: number;
}

const completeQuiz = createAsyncThunk<ReturnType, Params>(
  'quiz/completeQuiz',
  async ({ quizId }) => {
    const { data } = await Quiz.completeQuiz({ quizId });

    const { maxEarnedPoints: maxEarnPoints, rightQuestions, earnedPoints, questionsCount } = data;

    return {
      maxEarnPoints,
      rightQuestions,
      earnedPoints,
      questionsCount,
    };
  },
);

export default completeQuiz;
