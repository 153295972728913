import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';

import { GetInvitationsRequest, GetInvitationsResponse, TournamentType } from '../types';

const TYPE_BY_TOURNAMENT_TYPE = {
  [TournamentType.User]: 'players',
  [TournamentType.Team]: 'teams',
};

const getInvitations = ({
  type,
  tournamentId,
  offset,
  limit,
  search,
}: GetInvitationsRequest): Promise<AxiosResponse<GetInvitationsResponse>> => {
  return authInstance({
    method: 'get',
    url: `/v1/tournaments/invitations/${TYPE_BY_TOURNAMENT_TYPE[type]}`,
    params: {
      tournament_id: tournamentId,
      offset,
      limit,
      search,
    },
  });
};

export default getInvitations;
