import { useContext, useEffect, useRef } from 'react';

import { NavigationActionType, TabBarItem } from '../../../types';
import { Context } from '../../../context';

type Params = {
  id: TabBarItem['id'];
};

const usePointer = ({ id }: Params) => {
  const [settings, dispatch] = useContext(Context);
  const { tabBarTopOffset, tabBarHeight, animationContainerTopOffset } = settings;
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const topTriggerPosition = tabBarTopOffset + tabBarHeight + animationContainerTopOffset;
    let isActive = false;

    const listener = () => {
      if (elementRef.current) {
        const { y } = elementRef.current.getBoundingClientRect();

        if (y > topTriggerPosition && isActive) {
          isActive = false;

          dispatch({
            type: NavigationActionType.CHANGE_ACTIVE_TAB,
            tabId: id,
            active: isActive,
          });
        }

        if (y <= topTriggerPosition && !isActive) {
          isActive = true;

          dispatch({
            type: NavigationActionType.CHANGE_ACTIVE_TAB,
            tabId: id,
            active: isActive,
          });
        }
      }
    };

    window.addEventListener('scroll', listener);

    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, [id, elementRef, tabBarTopOffset, tabBarHeight, animationContainerTopOffset, dispatch]);

  return elementRef;
};

export default usePointer;
