import React, { memo, ReactElement, useRef } from 'react';
import classNames from 'classnames';

import { useParentOverflowHidden, useNavigation, useContainerScroll, usePages } from './hooks';
import styles from './styles.module.scss';

interface Props {
  root: ReactElement;
  children: ReactElement | ReactElement[];
  useWindowScroll?: boolean;
}

const NestedContentNavigation = ({ root, children, useWindowScroll = false }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { isActivePageShown, activePageId, isRootHidden, navigateTo, goBack } = useNavigation();
  const { activePage, rootElement } = usePages({
    root,
    children,
    activePageId,
    navigateTo,
    goBack,
  });

  useContainerScroll({ activePageId, containerRef, useWindowScroll });
  useParentOverflowHidden({ containerRef, useWindowScroll });

  return (
    <div
      ref={containerRef}
      className={classNames(styles.container, {
        [styles.navigated]: isActivePageShown,
      })}
    >
      <div className={styles.root}>
        <div
          className={classNames(styles.content, {
            [styles.hidden]: isRootHidden,
          })}
        >
          {rootElement}
        </div>
      </div>

      <div className={styles.activePage}>{activePage}</div>
    </div>
  );
};

export default memo(NestedContentNavigation);
