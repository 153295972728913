import dayjs, { Dayjs } from 'dayjs';
import { t } from 'i18next';

export const getPeriods = (periodsCount: number, unit: 'week' | 'month' | 'year') => {
  const currentDate = dayjs().startOf(unit);
  const dates = [];

  for (let offset = 0; offset < periodsCount; offset++) {
    const date = currentDate.subtract(offset, unit);

    dates.push({ start: date.startOf(unit), end: date.endOf(unit), offset });
  }

  return dates;
};

export const getExtendedDiff = (futureDate: Dayjs) => {
  const rightDate = dayjs();
  const days = futureDate.diff(rightDate, 'd');
  const hours = futureDate.subtract(days, 'days').diff(rightDate, 'h');
  const minutes = futureDate.subtract(days, 'days').subtract(hours, 'hours').diff(rightDate, 'm');
  const seconds = futureDate
    .subtract(days, 'days')
    .subtract(hours, 'hours')
    .subtract(minutes, 'minutes')
    .diff(rightDate, 's');

  return {
    days,
    hours,
    minutes,
    seconds,
    label: getExtendedDiffLabel({ days, hours, minutes, seconds }),
    isDateInPast: days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0,
  };
};

type GetExtendedDiffLabelParams = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

const prettifyExtendedDiffTime = (time: number) => (time <= 9 ? `0${time}` : time);

const getExtendedDiffLabel = ({ days, hours, minutes, seconds }: GetExtendedDiffLabelParams) => {
  const time = [];

  if (hours) {
    time.push(prettifyExtendedDiffTime(hours));
  }

  time.push(prettifyExtendedDiffTime(minutes));
  time.push(prettifyExtendedDiffTime(seconds));

  if (days) {
    return `${t('common_time_days', { count: days, 0: days })} ${time.join(':')}`;
  }

  return time.join(':');
};
