import React, { memo } from 'react';
import { DropdownIndicatorProps, GroupBase } from 'react-select';
import classNames from 'classnames';

import { Icon } from 'packages/shared/components';

import styles from './dropdown-indicator.module.scss';
import { Option, Size } from '../types';

const DropdownIndicator = <V extends string | number, T extends Option<V>>({
  selectProps,
}: DropdownIndicatorProps<T, false, GroupBase<T>>) => {
  const { size } = selectProps;

  return (
    <Icon className={classNames(styles.icon, styles[Size[size]])} module="system" id="expandMore" />
  );
};

export default memo(DropdownIndicator);
