import React, { ReactElement, useMemo, useState } from 'react';

import { Theme } from '../../types';
import { localStorage, StorageKey } from '../../storage';
import Context from './Context';

interface Props {
  children: ReactElement;
}

const Provider = ({ children }: Props) => {
  const [theme, setTheme] = useState<Theme>(
    () => localStorage.get<Theme>(StorageKey.GlobalTheme) || Theme.Dark,
  );

  const themeState = useMemo(
    () => ({
      theme,
      switchTheme: () => {
        const nextTheme = theme === Theme.Dark ? Theme.Light : Theme.Dark;

        setTheme(nextTheme);
        localStorage.set<Theme>(StorageKey.GlobalTheme, nextTheme);
        document.documentElement.setAttribute('data-theme', nextTheme);

        return nextTheme;
      },
    }),
    [theme],
  );

  return <Context.Provider value={themeState}>{children}</Context.Provider>;
};

export default Provider;
