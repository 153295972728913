import camelcase from 'camelcase';
import camelcaseKeys from 'camelcase-keys';

export const isObject = (object: any): object is object => object === Object(object);

export const isString = (value: any): value is string => {
  return typeof value === 'string' || value instanceof String;
};

export const toCamelCase = (value: Record<string, any> | string, deep = false): any => {
  return isString(value)
    ? camelcase(value as string)
    : camelcaseKeys(value as Record<string, any>, { deep });
};
