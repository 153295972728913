import { createAsyncThunk } from '@reduxjs/toolkit';

import { Rating } from 'packages/rest';
import { RootState } from 'app/store';

import { mapEventsDetails } from '../services';
import { DetailsTypes, FilterTypes } from '../types';
import { filterSelectors, detailsSelectors } from '../selectors';

type ReturnType = {
  details: DetailsTypes.Details[];
  offset: number;
  hasNext: boolean;
};

type Params = {
  id: string | number;
};

const getInitialRatingItemDetails = createAsyncThunk<ReturnType, Params, { state: RootState }>(
  'leaderboard/getInitialRatingItemDetails',
  async (params, { getState }) => {
    const { limit } = detailsSelectors.getStatus(getState());
    const { unit, dateOffset, dateType, sport } = filterSelectors.getActiveFilter(getState());
    const { id } = params;

    const { data } = await Rating.getRatingDetails({
      offset: 0,
      limit,
      type: unit,
      id,
      dateOffset: dateOffset.value,
      dateType,
      sportId: sport.value === FilterTypes.SportId.All ? undefined : sport.value,
    });

    return {
      details: mapEventsDetails(data),
      offset: limit,
      hasNext: limit <= (data || []).length,
    };
  },
);

export default getInitialRatingItemDetails;
