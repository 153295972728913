export enum Size {
  sm = 'small',
  md = 'medium',
  lg = 'large',
  exLg = 'extraLarge',
}

export enum ContentYAlignment {
  bottom = 'bottom',
  center = 'center',
}
