import React, { cloneElement, FC, memo, ReactElement, useMemo } from 'react';
import classNames from 'classnames';

import { Theme } from './types';
import styles from './style.module.scss';

type Props = {
  description: string;
  iconElement?: ReactElement;
  theme?: keyof typeof Theme;
  className?: string;
  header?: string;
};

const InfoBubble: FC<Props> = ({ description, header, iconElement, theme = 'gray', className }) => {
  const icon = useMemo(
    () => (iconElement ? cloneElement(iconElement, { className: styles.icon }) : undefined),
    [iconElement],
  );

  return (
    <div className={classNames(styles.bubble, styles[Theme[theme]], className)}>
      {!!icon && icon}
      <div className={styles.content}>
        {header && <h4 className={styles.header}>{header}</h4>}
        {description && <div className={styles.description}>{description}</div>}
      </div>
    </div>
  );
};

export default memo(InfoBubble);
