import { createAsyncThunk } from '@reduxjs/toolkit';

import { UsersV2 } from 'packages/rest';

interface ReturnType {
  data: UsersV2.GetUserByIdResponse;
}

interface Params {
  userId: string | number;
}

const getProfile = createAsyncThunk<ReturnType, Params>(
  'user-profile/getProfile',
  async ({ userId }) => {
    const { data } = await UsersV2.getUser({ userId });

    return { data };
  },
);

export default getProfile;
