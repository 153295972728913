type Params = {
  balance: number;
  amount: number;
  type: 'add' | 'sub';
};

const calculateBalance = ({ balance, amount, type }: Params) => {
  if (type === 'add') {
    return `${balance + amount}`;
  }

  return `${balance - amount}`;
};

export default calculateBalance;
