import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { getInitialRatingItemDetails, getMoreRatingItemDetails } from '../../thunks';
import { DetailsTypes } from '../../types';
import { commonActions, detailsActions } from '../../actions';

const defaultState: DetailsTypes.Data = {};

const reducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(detailsActions.selectItemDetails, (state, { payload }) => {
      const { points, name, photo, id } = payload;

      return { ...state, points, name, photo, id };
    })
    .addMatcher(
      isAnyOf(getInitialRatingItemDetails.fulfilled, getMoreRatingItemDetails.fulfilled),
      (state, { payload }) => {
        const { details } = payload;

        state.details = details;
      },
    )
    .addMatcher(isAnyOf(detailsActions.cleanupState, commonActions.cleanupState), () => {
      return defaultState;
    });
});

export default reducer;
