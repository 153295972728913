import { createAsyncThunk } from '@reduxjs/toolkit';

import { Tournaments } from 'packages/rest';

interface Params {
  tournamentId: string | number;
}

const getTournamentExpressEvents = createAsyncThunk<
  Tournaments.GetTournamentExpressEventsResponse,
  Params
>('superExpress/getTournamentExpressEvents', async ({ tournamentId }) => {
  const { data } = await Tournaments.getTournamentExpressEvents({ tournamentId });

  return data;
});

export default getTournamentExpressEvents;
