import { Nullable } from 'packages/types';

import {
  CountryInfoSchema,
  FanclubInfoSchema,
  TeamInfoSchema,
  TennisRatingInfoSchema,
  UserInfoSchema,
} from '../../types';

export enum ProfileLocale {
  EN = 'en',
  RU = 'ru',
  KK = 'kk',
  BE = 'be',
  UK = 'uk',
}

export interface QuizDefailInfoSchema {
  id: number;
  img: string;
  name: string;
  endDate: string;
  isActive: boolean;
  startDate: string;
}

export interface QuizInfoSchema {
  activeQuiz: Nullable<QuizDefailInfoSchema>;
}

export interface GetProfileMenuResponse {
  user: UserInfoSchema;
  tennisRating: Nullable<TennisRatingInfoSchema>;
  team: Nullable<TeamInfoSchema>;
  country: Nullable<CountryInfoSchema>;
  fanclubs: FanclubInfoSchema[];
  quiz: QuizInfoSchema;
  unreadMessagesCount: number;
}

export interface GetProfileResponse {
  isVip: Nullable<number>;
  description: Nullable<string>;
  friendsCount: number;
  followersCount: number;
  userAgreementAccepted: boolean;
  locale: ProfileLocale;
  theme: string;
  user: UserInfoSchema;
  tennisRating: Nullable<TennisRatingInfoSchema>;
  fanclubs: FanclubInfoSchema[];
  team: Nullable<TeamInfoSchema>;
  country: Nullable<CountryInfoSchema>;
}
