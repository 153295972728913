import React, { FC, memo, ReactElement } from 'react';

import { Button } from 'packages/shared/components';

import styles from './styles.module.scss';

type Props = {
  buttonText: string;
  onButtonClick: () => void;
  children: ReactElement | ReactElement[];
};

const Container: FC<Props> = ({ children, buttonText, onButtonClick }) => {
  return (
    <div className={styles.container}>
      <ul>{children}</ul>

      <div className={styles.buttonWrapper}>
        <Button theme="tertiary" onClick={onButtonClick}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

export default memo(Container);
