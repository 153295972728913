import { createAsyncThunk } from '@reduxjs/toolkit';

import { TournamentsV2 } from 'packages/rest';

const getUser = createAsyncThunk<TournamentsV2.Tournament[], void>(
  'tournaments/getUser',
  async () => {
    const { data } = await TournamentsV2.getUserTournaments();

    return data;
  },
);

export default getUser;
