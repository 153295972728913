import React, { FC, memo } from 'react';

type Props = {
  className: string;
};

const UserIcon: FC<Props> = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 60 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30 0C26.0218 0 22.2064 1.59128 19.3934 4.42376C16.5804 7.25624 15 11.0979 15 15.1037C15 19.1094 16.5804 22.951 19.3934 25.7835C22.2064 28.616 26.0218 30.2073 30 30.2073C33.9782 30.2073 37.7936 28.616 40.6066 25.7835C43.4196 22.951 45 19.1094 45 15.1037C45 11.0979 43.4196 7.25624 40.6066 4.42376C37.7936 1.59128 33.9782 0 30 0ZM30 34.9079C19.9875 34.9079 0 39.9676 0 50.0116V57.5634H30H60V50.0116C60 39.9676 40.0125 34.9079 30 34.9079Z" />
    </svg>
  );
};
export default memo(UserIcon);
