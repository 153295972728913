import { Column } from 'react-table';
import { t } from 'i18next';

import { Rating } from 'packages/rest';

import TeamCell from '../TeamCell';

type Params = {
  measurement: Rating.Order;
};

const getTeamRatingSchema = ({ measurement }: Params): Column<Rating.TeamRating>[] => {
  return [
    {
      id: 'place',
      Header: () => t('common_place'),
      accessor: 'position',
      cellStyle: { maxWidth: '37px' },
      headerStyle: { maxWidth: '37px' },
    },
    {
      id: 'player',
      Header: () => t('common_player'),
      accessor: ({ photo, name }) => ({ photo, name }),
      Cell: TeamCell,
    },
    {
      id: 'points',
      Header: () => t(measurement === Rating.Order.Points ? 'common_pts' : 'common_text_coins'),
      accessor: measurement === Rating.Order.Points ? 'points' : 'coins',
      cellStyle: { minWidth: '25px', maxWidth: '70px', textAlign: 'right' },
      headerStyle: { minWidth: '25px', maxWidth: '70px', textAlign: 'right' },
    },
  ];
};

export default getTeamRatingSchema;
