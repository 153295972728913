import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { invitationsThunks } from '../../thunks';
import { InvitationsTypes } from '../../types';

const defaultState: InvitationsTypes.Data = {
  invitations: [],
  selectedInvitationsIds: [],
};

const reducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(invitationsThunks.loadInitialInvitations.fulfilled, (state, { payload }) => {
      const { invitations } = payload;

      state.invitations = invitations;
    })
    .addCase(invitationsThunks.loadMoreInvitations.fulfilled, (state, { payload }) => {
      const { invitations } = payload;

      state.invitations = <typeof state.invitations>[...state.invitations, ...invitations];
    })
    .addMatcher(
      isAnyOf(
        invitationsThunks.loadInitialInvitations.pending,
        invitationsThunks.loadInitialInvitations.rejected,
      ),
      () => {
        return defaultState;
      },
    );
});

export default reducer;
