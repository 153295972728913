import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';

import { SearchRequest, SearchResponse } from '../types';

const search = ({ userId, relation }: SearchRequest): Promise<AxiosResponse<SearchResponse[]>> => {
  return authInstance({ method: 'get', url: `/v1/friends/${userId}/${relation}` });
};

export default search;
