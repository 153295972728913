import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';

import { GetAvailableMarketsRequest, GetAvailableMarketsResponse } from '../types';

const getEvents = ({
  eventIds,
}: GetAvailableMarketsRequest): Promise<AxiosResponse<GetAvailableMarketsResponse>> => {
  return authInstance({
    method: 'get',
    url: `/v2/events/available-markets`,
    params: {
      eventIds,
    },
  });
};

export default getEvents;
