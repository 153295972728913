import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import { localStorage, StorageKey } from 'app/storage';
import { AppDispatch } from 'app/store';
import { LocaleCode } from 'app/types';
import { refresh } from 'packages/auth/thunks';

import { DEFAULT_LOCALE_CODE } from '../constants';
import configureLanguage from './configureLanguage';
import configureAppIdentifier from './configureAppIdentifier';

const initApp = createAsyncThunk<void, void, { dispatch: AppDispatch }>(
  'config/init',
  async (_, { dispatch }) => {
    const localeCode =
      localStorage.get<LocaleCode | undefined>(StorageKey.LocaleCode) || DEFAULT_LOCALE_CODE;
    const appIdentifier = localStorage.get<ReturnType<typeof uuid>>(StorageKey.AppIdentifier);

    dispatch(configureLanguage(localeCode));
    dispatch(configureAppIdentifier(appIdentifier));
    await dispatch(refresh());
  },
);

export default initApp;
