import React, { FC, memo, useContext, useEffect, useRef } from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';
import { Context } from '../../context';
import { NavigationActionType } from '../../types';
import Tab from './Tab';

type Props = {
  isSticky?: boolean;
  className?: string;
  topOffset?: number;
};

const TabBar: FC<Props> = ({ className, isSticky = false, topOffset = 0 }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [state, dispatch] = useContext(Context);
  const { tabs } = state;

  useEffect(() => {
    const { offsetHeight: height = 0 } = containerRef.current || {};

    dispatch({
      type: NavigationActionType.SET_TAB_NAVIGATION_CONFIG,
      height,
      topOffset,
    });
  }, [containerRef, topOffset, dispatch]);

  return (
    <div
      ref={containerRef}
      style={isSticky ? { top: topOffset } : undefined}
      className={classNames(styles.tabBar, { [styles.sticky]: isSticky }, className)}
    >
      {tabs.map(({ id, label }) => (
        <Tab containerRef={containerRef} key={id} id={id}>
          {label}
        </Tab>
      ))}
    </div>
  );
};

export default memo(TabBar);
