import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { setupCache } from 'axios-cache-adapter';

import { isObject, toCamelCase } from 'app/utils/Object.utils';

const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

const configure = (requestConfig: AxiosRequestConfig): AxiosInstance => {
  const instance = axios.create({
    headers: requestConfig.headers || {},
    baseURL: requestConfig.baseURL || '',
    timeout: requestConfig.timeout || 0,
    // adapter: cache.adapter,
  });

  instance.interceptors.response.use(
    (response) => {
      if (isObject(response.data)) {
        response.data = toCamelCase(response.data, true);
      }

      return response;
    },
    (error) => {
      const { response } = error;

      if (isObject(response?.data)) {
        error.response.data = toCamelCase(response.data);
      }

      throw error;
    },
  );

  return instance;
};

export default configure;
