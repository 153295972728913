import { RefObject, useEffect, useRef } from 'react';

import { getScrollParent } from 'app/utils/Scroll.utils';

interface Params {
  activePageId: string | null;
  containerRef: RefObject<HTMLDivElement>;
  useWindowScroll: boolean;
}

const useContainerScroll = ({ activePageId, useWindowScroll, containerRef }: Params) => {
  const rootScrollRef = useRef(0);

  useEffect(() => {
    const scrollParent = getScrollParent(containerRef.current) as HTMLElement;
    const [element, scroll] = useWindowScroll
      ? [window, window.scrollY]
      : [scrollParent, scrollParent.scrollTop];

    if (activePageId) {
      rootScrollRef.current = scroll;
      element.scrollTo({ top: 0 });
    } else {
      element.scrollTo({ top: rootScrollRef.current });
    }
  }, [activePageId, containerRef, useWindowScroll]);
};

export default useContainerScroll;
