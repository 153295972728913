import { Nullable, Sport } from 'packages/types';

import { PredictionChoice, UserGroup } from '../../types';

export interface User {
  position: number;
  points: Nullable<number>;
  percent: number;
  id: number;
  firstName: string;
  lastName: string;
  photo: string;
  username: string;
  status: number;
  teamId: number;
  teamName: string;
  balance: string;
  isActive: boolean;
  isPredicted: boolean;
  isTop: boolean;
  groups: UserGroup[];
}

export interface Event {
  id: number;
  club1Id: number;
  club2Id: number;
  club1Name: string;
  club2Name: string;
  img11: string;
  img22: string;
  score: string;
  date: string;
  sportId: number;
  seasonId: number;
  betradarTournamentId: number;
  isProcessed: boolean;
  scoreDetail: string;
  b: string;
  status: number;
  isBetProcessed: boolean;
  leagueName: string;
  img1: string;
  img2: string;
  live: boolean;
  team2Id: Nullable<number>;
  team1Id: Nullable<number>;
  liveStreamUrl: Nullable<string>;
  tournamentId: Nullable<number>;
  calendarId: Nullable<number>;
}

export interface Tournament {
  id: number;
  name: string;
  img: string;
  joined: number;
  creatorId: Nullable<number>;
  eventsCount: number;
  type: TournamentType;
  mode: TournamentMode;
  cost: number;
  jackpot: number;
  status: TournamentStatus;
  sportId: Sport[];
  isRewarded: boolean;
  createdAt: string;
  startDate: string;
  endDate: string;
  prize: Nullable<number>;
  joinPossibility: boolean;
  royalty: number;
  guaranteed: number;
  isPushed: boolean;
  currentPool: number;
  rulesType: TournamentRule;
  stage: number;
  playOff: number;
  isInActivities: boolean;
  round: number;
  phase: number;
  maxRound: number;
  topCount: number;
  leagueName: Nullable<string>;
  genderAllowed: number;
  competitionId: Nullable<number>;
  freeBet: number;
  maxJoined: number;
  transfersPrices: Record<string, unknown>;
  leagueUp: number;
  leagueDown: number;
  countPoints: boolean;
  completedAt: string;
  minPredictionCount: number;
  doAutoRoundChange: boolean;
  transferWindowStartDate: Nullable<string>;
  transferWindowEndDate: string;
  allowLoans: boolean;
  tournamentsRewards: Record<string, unknown>;
  useCards: boolean;
  leagueNumber: Nullable<number>;
  missedPenaltyNumber: number;
  eventChangingPrice: Nullable<number>;
  seasonId: Nullable<number>;
  includedPhases: Record<string, unknown>;
  stageWinsAmount: unknown;
  tournamentsCupsId: Nullable<number>;
  maxQualificationRound: number;
  season: TournamentSeason;
  competition: TournamentCompetition;
  creator: Nullable<TournamentCreator>;
  isJoined: boolean;
  predictedEvents: number;
  leaderboardPosition: number;
  comments: number;
  rulesWin: Nullable<number>;
  hasLiveStream: boolean;
  liveStreamEventIds: unknown[];
  hasRules: boolean;
  topSalary: number;
  showConferencesFilter: boolean;
  bracketsTabs: Record<string, unknown>;
  timerDate: Nullable<string>;
  liveEventIds: unknown[];
  fanclub: TournamentFanclub;
  viewStatus: number;
  isLive: boolean;
  eventId: number;
  totalPredictions: number;
  tournamentStatusFanclub: Nullable<number>;
  calendarId: number;
  sortForTournaments: number;
  level: string;
}

export interface EventTeamsTopResponse {
  all: User[];
  benchPlayersPoints: number;
  event: Event;
  topPlayersCount: number;
  topPlayersPoints: number;
  total: number;
}

export interface EventTeamsTopRequest {
  tournamentId: number;
  teamId: string | number;
  eventId: number;
  limit?: number;
  offset?: number;
}

export enum TournamentMode {
  Free = 1,
  BuyIn,
  Sponsored,
  Express,
  Guaranteed,
  X2,
  Open,
  Fanclubs,
  Flex,
  BigFive,
  National,
}

export enum TournamentType {
  User = 1,
  Team,
}

export enum TournamentStatus {
  NotEnded,
  Suspended,
  Rescheduled,
  Canceled,
  Retired,
  Ended,
}

export enum TournamentRule {
  CustomPlaces,
  WinnerTakesAll,
  WinnerTakesPercent,
}

export enum TournamentVisibility {
  Private = 0,
  Public = 1,
}

export interface TournamentSeason {
  name: string;
  id: number;
}

export interface TournamentCompetition {
  name: string;
  id: number;
}

export interface TournamentFanclub {
  name: string;
  img: string;
  teamId: string;
  id: string;
  isLoaned: boolean;
  isActive: boolean;
  canPredict: boolean;
}

export interface TournamentCreator {
  firstName: string;
  lastName: string;
}

export interface GetTournamentByIdRequest {
  tournamentId: string | number;
}

export type GetTournamentByIdResponse = Tournament;

export interface TournamentExpressEventResult {
  lastGoal: number;
  firstGoal: number;
  kickOfTeam: number;
  firstGoalTime: string;
}

export interface TournamentExpressEventStake {
  coeffWin1: number;
  coeffWin2: number;
  coeffDraw: number;
}

export interface TournamentExpressEvent {
  id: number;
  betradarId: number;
  sportId: Sport;
  leagueId: Sport;
  club1Name: string;
  club2Name: string;
  img1: string;
  img2: string;
  img1Extra: Nullable<string>;
  img2Extra: Nullable<string>;
  score: string;
  scoreDetail: string;
  isProcessed: boolean;
  date: string;
  notify: Nullable<number>;
  f: Nullable<string>;
  b: Nullable<string>;
  h: Nullable<string>;
  t: Nullable<string>;
  isBetProcessed: boolean;
  hasMarkets: boolean;
  teamsId: Nullable<number>;
  statistics: boolean;
  seasonId: number;
  betradarTournamentId: number;
  team1Id: number;
  team2Id: number;
  previousLegId: Nullable<number>;
  results: TournamentExpressEventResult;
  needRecalculation: boolean;
  bofpTeam1Id: Nullable<number>;
  bofpTeam2Id: Nullable<number>;
  tournamentsStagesId: Nullable<number>;
  isManual: boolean;
  isLiveProcessed: boolean;
  live: boolean;
  stake: TournamentExpressEventStake;
  leagueName: string;
}

export interface GetTournamentExpressEventsRequest {
  tournamentId: string | number;
}

export type GetTournamentExpressEventsResponse = TournamentExpressEvent[];

export type ExpressPrediction = {
  choice: PredictionChoice[];
  eventId: number;
  sportId: Sport;
};

export interface MakePredictionRequest {
  tournamentId: string | number;
  predictions: ExpressPrediction[];
}

export interface MakePredictionResponse {
  setNumber: number;
}

export interface CreateTournamentRequest {
  name: string;
  img: string;
  cost?: number;
  royalty?: number;
  l?: number;
  events: number[];
  players?: number[];
  rules: Rules;
  eventsCodes: Partial<Record<Sport, number[]>>;
  type: TournamentType;
  mode: TournamentMode;
  visibility: TournamentVisibility;
  rulesType?: TournamentRule;
}

export interface Rules {
  text?: string;
  places?: number[];
}

export type CreateTournamentResponse = Tournament;

export interface GetInvitationsRequest {
  type: TournamentType;
  tournamentId?: number;
  limit?: number;
  offset?: number;
  search?: string;
}

export type GetInvitationsResponse = PlayerInvitation[] | TeamInvitation[];

export interface PlayerInvitation {
  id: number;
  firstName: string;
  lastName: string;
  photo: string;
  position: number;
}

export interface TeamInvitation {
  id: number;
  name: string;
  photo: string;
  joined: number;
  position: number;
  points: number;
}
