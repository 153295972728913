import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';

import { GetQuestionAnswerResponse, GetQuestionAnswerRequest } from '../types';

const TIMEOUT = 15000;

const getQuestionAnswer = ({
  quizId,
  questionId,
  answerId,
}: GetQuestionAnswerRequest): Promise<AxiosResponse<GetQuestionAnswerResponse>> => {
  return authInstance({
    timeout: TIMEOUT,
    method: 'post',
    url: `/v1/quiz/${quizId}/questions/${questionId}/answer`,
    data: { answer_id: answerId === undefined ? null : answerId },
  });
};

export default getQuestionAnswer;
