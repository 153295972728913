import React, { ReactElement, RefObject, useMemo, HTMLAttributes, memo } from 'react';
import { useTable, Row, Column, TableRowProps } from 'react-table';
import classnames from 'classnames';

import { getConfigurationsByOptions } from './services';
import { LayoutType } from './types';
import styles from './styles.module.scss';

type Props<DataType extends object> = {
  columns: Column<DataType>[];
  data: DataType[];
  isColumnsSticky?: boolean;
  isHeaderSticky?: boolean;
  className?: string;
  containerClassName?: string;
  rowClassName?: string;
  columnClassName?: string;
  headerClassName?: string;
  emptyText?: ReactElement | string;
  layoutType?: LayoutType;
  setRowProps?: (
    row: Row<DataType>,
  ) => Omit<TableRowProps, 'key'> & HTMLAttributes<HTMLTableRowElement>;
  forwardedRef?: RefObject<HTMLDivElement>;
};

const Table = <DataType extends object>({
  columns,
  data,
  forwardedRef,
  className,
  containerClassName,
  rowClassName,
  columnClassName,
  headerClassName,
  emptyText = 'No data available',
  layoutType = LayoutType.Flex,
  isColumnsSticky = false,
  isHeaderSticky = false,
  setRowProps,
  ...props
}: Props<DataType>) => {
  const {
    rowClassName: additionalRowClassName,
    columnClassName: additionalColumnClassName,
    plugins: additionalPlugins,
  } = useMemo(
    () =>
      getConfigurationsByOptions<DataType>({
        layoutType,
        isColumnsSticky,
      }),
    [layoutType, isColumnsSticky],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      data,
      columns,
      ...props,
    },
    ...additionalPlugins,
  );

  return (
    <div className={classnames(styles.tableContainer, containerClassName)} ref={forwardedRef}>
      <table className={classnames(styles.table, className)} {...getTableProps()}>
        <thead
          className={classnames(
            styles.header,
            { [styles.sticky]: isHeaderSticky },
            headerClassName,
          )}
        >
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();

            return (
              <tr key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restHeaderProps } = column.getHeaderProps();

                  return (
                    <th
                      className={classnames(
                        styles.headerColumn,
                        additionalColumnClassName,
                        columnClassName,
                      )}
                      key={key}
                      {...restHeaderProps}
                    >
                      {column.render('Header')}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody className={styles.body} {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const {
              key,
              className,
              highlighted,
              primaryGroup,
              secondaryGroup,
              tertiaryGroup,
              ...restRowProps
            } = row.getRowProps(setRowProps?.(row));

            return (
              <tr
                className={classnames(
                  styles.tableRow,
                  {
                    [styles.highlighted]: highlighted,
                    [styles.groupRow]: primaryGroup || secondaryGroup || tertiaryGroup,
                    [styles.primaryGroup]: primaryGroup,
                    [styles.secondaryGroup]: secondaryGroup,
                    [styles.tertiaryGroup]: tertiaryGroup,
                  },
                  additionalRowClassName,
                  className,
                  rowClassName,
                )}
                key={key}
                {...restRowProps}
              >
                {row.cells.map((cell) => {
                  const { key, ...restCellProps } = cell.getCellProps();

                  return (
                    <td
                      className={classnames(additionalColumnClassName, columnClassName)}
                      key={key}
                      {...restCellProps}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {data.length === 0 && <div className={styles.emptyText}>{emptyText}</div>}
    </div>
  );
};

export default memo(Table);
