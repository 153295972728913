import { AxiosResponse } from 'axios';

import { authInstance } from 'app/rest';

import { ResetQuizRequest, ResetQuizResponse } from '../types';

const resetQuiz = ({ quizId }: ResetQuizRequest): Promise<AxiosResponse<ResetQuizResponse>> => {
  return authInstance({
    method: 'post',
    url: `/v1/quiz/${quizId}/reset`,
  });
};

export default resetQuiz;
