import dayjs from 'dayjs';

import { Sport } from 'packages/types';

import { MatchesFilterTypes } from '../types';

const INITIAL_FROM_DATE = dayjs().startOf('day').format();

export const DEFAULT_FILTER_STATE: MatchesFilterTypes.Filter = {
  fromDate: INITIAL_FROM_DATE,
  toDate: undefined,
  selectedLeaguesOptions: [],
  selectedPredictionsOptions: [],
  differencesCount: 0,
  searchKey: '',
};

export const DEFAULT_STATE: MatchesFilterTypes.State = {
  [Sport.Soccer]: DEFAULT_FILTER_STATE,
  [Sport.Hockey]: DEFAULT_FILTER_STATE,
  [Sport.Tennis]: DEFAULT_FILTER_STATE,
  [Sport.Basketball]: DEFAULT_FILTER_STATE,
};
