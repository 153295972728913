import React, { FC, memo, useCallback } from 'react';
import classNames from 'classnames';

import { Avatar, Icon } from 'packages/shared/components';
import { Nullable } from 'packages/types';
import { UserGroup } from 'packages/rest/types';

import styles from './styles.module.scss';

type Props = {
  isFriend: boolean;
  userId: number;
  friendStatusUpdating?: boolean;
  onAddFriendClick?: (userId: number) => void;
  onRemoveFriendClick?: (userId: number) => void;
  onUserClick?: (userId: number) => void;
  className?: string;
  photo?: Nullable<string>;
  firstName?: Nullable<string>;
  teamName?: Nullable<string>;
  lastName?: Nullable<string>;
  groups?: Nullable<UserGroup[]>;
  friendshipDisabled?: boolean;
};

const Search: FC<Props> = ({
  className,
  photo,
  firstName,
  lastName,
  teamName,
  isFriend,
  groups,
  userId,
  onAddFriendClick,
  onRemoveFriendClick,
  onUserClick,
  friendStatusUpdating,
  friendshipDisabled = false,
}) => {
  const iconId = isFriend ? 'userRemove' : 'userAdd';
  const isCelebrity = groups?.find((group) => group === UserGroup.CELEBRITY);

  const handleActionIconClick = useCallback(() => {
    if (isFriend) {
      onRemoveFriendClick?.(userId);
    } else {
      onAddFriendClick?.(userId);
    }
  }, [userId, isFriend, onAddFriendClick, onRemoveFriendClick]);

  const handleUserClick = useCallback(() => {
    onUserClick?.(userId);
  }, [userId, onUserClick]);

  return (
    <div className={classNames(styles.wrapper, className)}>
      <Avatar onClick={handleUserClick} src={photo || ''} useEmptyUserIcon size="lg" />

      <div className={styles.content}>
        <div onClick={handleUserClick} className={styles.name}>
          {firstName} {lastName}
          {isCelebrity && <Icon className={styles.checkIcon} id="checkedInCircle" module="other" />}
        </div>
        <div className={styles.details}>
          {teamName && <span className={styles.teamName}> {teamName}</span>}
        </div>
      </div>

      {!friendshipDisabled && (
        <Icon
          onClick={friendStatusUpdating ? undefined : handleActionIconClick}
          className={classNames(styles.actionIcon, {
            [styles.highlighted]: !isFriend,
            [styles.inProgress]: friendStatusUpdating,
          })}
          id={iconId}
          module="base"
        />
      )}
    </div>
  );
};

export default memo(Search);
