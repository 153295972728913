import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import toObject from 'dayjs/plugin/toObject';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';

const configureDayjs = () => {
  dayjs.extend(updateLocale);
  dayjs.extend(weekOfYear);
  dayjs.extend(toObject);
  dayjs.extend(localeData);
  dayjs.extend(relativeTime);

  dayjs.updateLocale('en', {
    weekStart: 1,
  });
};

export default configureDayjs;
