import { createSelector } from '@reduxjs/toolkit';

import { Quiz } from 'packages/rest';
import { Nullable } from 'packages/types';

import { StatisticsTypes } from '../../types';
import getState from './getState';

export const getData = createSelector(
  getState,
  (state: StatisticsTypes.State): StatisticsTypes.Data | undefined => state.data,
);

export const getStatistics = createSelector(
  getData,
  (state?: StatisticsTypes.Data): Quiz.QuizStatistics | undefined => state?.stats,
);

export const getQuizInfo = createSelector(
  getData,
  (state?: StatisticsTypes.Data): Quiz.QuizInfo | undefined => state?.quiz,
);

export const getSettings = createSelector(
  getData,
  (state?: StatisticsTypes.Data): Nullable<Quiz.QuizStatisticsSettings> | undefined =>
    state?.quiz?.settings,
);
