import { Nullable } from 'packages/types';

export enum QuestionGroup {
  Sport = 'SPORT',
  Bofp = 'BOFP',
}

export interface QuestionAnswer {
  id: number;
  text: string;
}

export interface Question {
  answers: QuestionAnswer[];
  id: number;
  img: Nullable<string>;
  questionGroup: QuestionGroup;
  text: string;
}

export interface GetQuestionsRequest {
  quizId: string | number;
}

export interface GetQuestionsResponse {
  endDate: string;
  questions: Question[];
  startDate: string;
}

export interface GetQuestionAnswerRequest {
  quizId: string | number;
  questionId: number;
  answerId?: number;
}

export interface GetQuestionAnswerResponse {
  isCorrect: boolean;
  earnedPoints: number;
  correctAnswerId: number;
}

export interface CheckQuizRequest {
  quizId: string | number;
}

export interface CheckQuizResponse {
  success: boolean;
}

export interface ResetQuizRequest {
  quizId: string | number;
}

export interface ResetQuizResponse {
  success: boolean;
}

export interface CompleteQuizRequest {
  quizId: string | number;
}

export interface CompleteQuizResponse {
  maxEarnedPoints: number;
  rightQuestions: number;
  earnedPoints: number;
  questionsCount: number;
}

export interface QuizStatisticsSettings {
  answerTime: number;
  questionCount: number;
  minPointsCount: number;
  maxPointsCount: number;
  maxQuizPointsCount: number;
}

export interface QuizInfo {
  id: number;
  img: string;
  name: string;
  startDate: Nullable<string>;
  endDate: Nullable<string>;
  isActive: Nullable<boolean>;
  resetDate: string;
  resetInProgress: boolean;
  settings: QuizStatisticsSettings;
}

export interface QuizStatisticsDates {
  quizCount: number;
  earnedPoints: number;
}

export interface QuizStatistics {
  all: QuizStatisticsDates;
  week: QuizStatisticsDates;
  month: QuizStatisticsDates;
}

export interface GetStatisticsResponse {
  quiz: QuizInfo;
  stats: QuizStatistics;
}

export interface GetActiveResponse {
  startDate: Nullable<string>;
}
