import { Tournaments } from 'packages/rest';
import { InvitationsTypes } from 'packages/tournament-management/types';

import isPlayersInvitations from './isPlayersInvitations';

const mapInvitations = (
  invitations: Tournaments.GetInvitationsResponse,
): InvitationsTypes.Invitation[] => {
  if (isPlayersInvitations(invitations)) {
    return mapPlayersInvitations(invitations);
  }

  return mapTeamsInvitations(invitations);
};

const mapPlayersInvitations = (
  invitations: Tournaments.PlayerInvitation[],
): InvitationsTypes.Invitation[] => {
  return invitations.map(({ id, photo, firstName, lastName, position }) => ({
    id,
    photo,
    position,
    name: `${firstName} ${lastName}`,
  }));
};

const mapTeamsInvitations = (
  invitations: Tournaments.TeamInvitation[],
): InvitationsTypes.Invitation[] => {
  return invitations.map(({ id, photo, name, position }) => ({
    id,
    photo,
    position,
    name,
  }));
};

export default mapInvitations;
